import React, { Component } from 'react';
import {
  obtenerComprobantes,
  agregarComprobante,
  eliminarComprobante
} from '../../api/Solicitudes';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import imgCredito from '../../images/iconos/credito.svg';
import imgDescarga from '../../images/iconos/descarga.svg';
import imgOkVerde from '../../images/iconos/okverde.svg';
import { BASE_URL } from '../../utils/config';

class OtrosCreditos extends Component {
  state = {
    comprobantes: []
  }

  componentDidMount = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      const resp = await obtenerComprobantes(id);
      const comprobantes = [];
      resp.data.forEach(c => {
        comprobantes.push({
          ...c,
          nombreArchivo: '',
          error: ''
        });
      })
      this.setState({ comprobantes });
    } catch (error) {
      console.log('Error:', error)
    }
  }

  handleImageChange = async (e) => {
    e.preventDefault();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { target: { name, files } } = e;
    const file = files[0];
    if (file) {
      const nameArray = name.split('_');
      const { comprobantes } = this.state;
      const comprobante = comprobantes.find(c => c.id === parseInt(nameArray[1]));
      comprobante.nombreArchivo = file.name;
      comprobante.error = '';
      const formData = new FormData();
      formData.append('documento', file);
      try {
        const resp = await agregarComprobante(id, comprobante.id, formData);
        comprobante.documento = resp.data.documento;
      } catch (error) {
        console.log('Error:', error);
      }
      this.setState({ comprobantes });
    }
  }

  validar = () => {
    let validado = true;
    const { comprobantes } = this.state;

    comprobantes.forEach(c => {
      if (!c.documento) {
        c.error = 'Este documento es requerido.';
        validado = false;
      }
    });

    this.setState({ comprobantes })
    return validado;
  }

  submit = async () => {
    if (!this.validar()) { return; }
    const {
      match: {
        params: { id }
      }
    } = this.props;
    
    this.props.history.push(`/solicitud-credito/${id}/resumen`);
  }

  render() {
    const {
      comprobantes
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Autenticado menuSelected="solicitud-credito">
        <TituloBotonDerecha
          icono={imgCredito}
          titulo="Captura de comprobantes"
          descripcion1=""
          descripcion2=""
        />
        <div className="row mt2">
          <div className="col-sm-6">
            <div className="row border-bottom">
              <div className="col-1">
                <i className="fa fa-exclamation-circle text-warning text-right" aria-hidden="true"></i>
              </div>
              <div className="col-11 fsz-sm">
                <p>
                  <b>Es importante que tomes en cuenta las siguientes consideraciones:</b>
                </p>
                <p>
                  Puedes subir archivos escaneados o imágenes tomadas desde la cámara de tu celular.
                </p>
                <p>
                  Cuando vas a tomar la foto con tu celular, tómala en calidad media o baja.
                </p>
                <p>
                  Las imágenes deben de estar perfectamente legibles.
                </p>
                <p>
                  Los formatos aceptados son .jpg, .png, .pdf y .gif
                </p>
              </div>
            </div>
            <div className="row mt1">
              <div className="col-1" />
              <div className="col-11 fsz-sm">
                <p>
                  Descarga la carta de autorización de consulta al buró de crédito
                </p>
                <a
                  href={`${BASE_URL}/descargas/autorizacion-buro-credito/`}
                  className="btn btn-link flex-row"
                >
                  <img src={imgDescarga} alt="Descargar" />
                  <b>Descarga</b>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            {
              comprobantes.map(c => (
                <div key={`comp${c.id}`} className="row mt1">
                  <div className="col-5 comprobanteLabel">
                    {c.nombre}
                  </div>
                  <div className="col-7">
                    <div key={c.id} className="custom-file">
                      <input
                        type="file"
                        className={`custom-file-input ${c.error ? 'is-invalid' : ''}`}
                        id={`comprobante${c.id}`}
                        name={`comprobante_${c.id}`}
                        onChange={this.handleImageChange}
                      />
                      <label className="custom-file-label" htmlFor={`comprobante${c.id}`}>{c.nombreArchivo}</label>
                    </div>
                    {
                      c.error && (
                        <div className="invalid-feedback" style={{display: 'inline'}}>
                          {c.error}
                        </div>
                      )
                    }
                  </div>
                  {
                    c.documento && (
                      <div className="col-7 offset-5">
                        <a href={c.documento} target="_blank" rel="noopener noreferrer">
                          <img src={imgOkVerde} alt="Ok" width="16" />
                          <small className="archivo"> Archivo</small>
                        </a>
                      </div>
                    )
                  }
                </div>
              ))
            }
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex-between">
            <a
              className="btn btn-secondary mt2"
              href={`/solicitud-credito/${id}/otros-creditos`}
            >
              Regresar
            </a>
            <button
              className="btn btn-success mt2"
              type="button"
              onClick={this.submit}
            >
              Siguiente
            </button>
          </div>
        </div>
        <style jsx="true">{`
          .flex-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
            padding-left: 0px !important;
            color: #25d07b !important;
          }
          .flex-row img {
            width: 20px;
            margin-right: 0.5rem;
          }
          .comprobanteLabel {
            color: #25d07b;
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
          .archivo {
            color: #333;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default OtrosCreditos
