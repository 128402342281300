import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function obtenerProveedores () {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/recargas/proveedores/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerProductosPorProveedor (idProveedor) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/recargas/proveedores/${idProveedor}/productos/`)
  } catch (err) {
    callError(err)
  }
}

async function obtenerDetalleRecarga (idRecarga) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/recargas/detalle-recarga/${idRecarga}/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerMisRecargas () {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/recargas/mis-recargas/');
  } catch (err) {
    callError(err);
  }
}

async function realizarRecarga (idProveedor, idProducto, telefono) {
  const API = await ConstructorAPIAsync(180000);
  const datos = {
    producto: idProducto,
    numero_telefono: telefono
  };
  try {
    return await API.post(`/recargas/hacer-recarga/${idProveedor}/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function enviarEmailRecarga (idRecarga) {
  const API = await ConstructorAPIAsync(180000);
  try {
    return await API.post(`/recargas/detalle-recarga/${idRecarga}/enviar-email/`);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerProveedores,
  obtenerProductosPorProveedor,
  obtenerDetalleRecarga,
  obtenerMisRecargas,
  realizarRecarga,
  enviarEmailRecarga
}