import React, { Component } from 'react';
import {
  obtenerReferencias,
  agregarReferencia,
  editarReferencia
} from '../../api/Solicitudes';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import Input from '../../components/form/Input';
import imgCredito from '../../images/iconos/credito.svg';
import { validateNumber } from '../../utils/regex';

class Referencias extends Component {
  state = {
    referencia1: {
      id: 0,
      nombre: '',
      parentesco: '',
      domicilio: '',
      telefono: ''
    },
    referencia2: {
      id: 0,
      nombre: '',
      parentesco: '',
      domicilio: '',
      telefono: ''
    },
    errores1: {},
    errores2: {}
  }

  componentDidMount = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      const resp = await obtenerReferencias(id);
      const referencias = resp.data;
      let { referencia1, referencia2 } = this.state;
      if (referencias.length > 0) {
        referencia1 = referencias[0];
        if (referencias.length > 1) {
          referencia2 = referencias[1];
        }
      }
      this.setState({ referencia1, referencia2 });
    } catch (error) {
      console.log('Error:', error)
    }
  }

  handleChange1 = (e) => {
    const { target: { name, value }} = e;
    const { referencia1 } = this.state;
    if (name === 'telefono') {
      if (!validateNumber(value) && value.length > 0) { return; }
    }
    referencia1[name] = value;
    this.setState({ referencia1, errores1: {} });
  }
  
  handleChange2 = (e) => {
    const { target: { name, value }} = e;
    const { referencia2 } = this.state;
    if (name === 'telefono') {
      if (!validateNumber(value) && value.length > 0) { return; }
    }
    referencia2[name] = value;
    this.setState({ referencia2, errores2: {} });
  }

  validar = () => {
    let validado = true;
    const errores1 = {}
    const errores2 = {}
    const {
      referencia1,
      referencia2
    } = this.state;

    if (referencia1.nombre.trim().length === 0) {
      validado = false;
      errores1.nombre = ['Este campo es requerido.'];
    }
    if (referencia1.parentesco.trim().length === 0) {
      validado = false;
      errores1.parentesco = ['Este campo es requerido.'];
    }
    if (referencia1.domicilio.trim().length === 0) {
      validado = false;
      errores1.domicilio = ['Este campo es requerido.'];
    }
    if (referencia1.telefono.trim().length === 0) {
      validado = false;
      errores1.telefono = ['Este campo es requerido.'];
    } else if (referencia1.telefono.trim().length !== 10) {
      validado = false;
      errores1.telefono = ['El teléfono debe ser de 10 dígitos.'];
    }
    
    if (referencia2.nombre.trim().length === 0) {
      validado = false;
      errores2.nombre = ['Este campo es requerido.'];
    }
    if (referencia2.parentesco.trim().length === 0) {
      validado = false;
      errores2.parentesco = ['Este campo es requerido.'];
    }
    if (referencia2.domicilio.trim().length === 0) {
      validado = false;
      errores2.domicilio = ['Este campo es requerido.'];
    }
    if (referencia2.telefono.trim().length === 0) {
      validado = false;
      errores2.telefono = ['Este campo es requerido.'];
    } else if (referencia2.telefono.trim().length !== 10) {
      validado = false;
      errores2.telefono = ['El teléfono debe ser de 10 dígitos.'];
    }

    this.setState({ errores1, errores2 })
    return validado;
  }

  submit = async () => {
    if (!this.validar()) { return; }

    const {
      match: {
        params: { id }
      }
    } = this.props;

    const {
      referencia1,
      referencia2
    } = this.state;

    const promesas = [];
    if (referencia1.id === 0) {
      promesas.push(agregarReferencia(id, referencia1));
    } else {
      promesas.push(editarReferencia(id, referencia1.id, referencia1));
    }
    if (referencia2.id === 0) {
      promesas.push(agregarReferencia(id, referencia2));
    } else {
      promesas.push(editarReferencia(id, referencia2.id, referencia2));
    }
    try {
      await Promise.all(promesas);
      this.props.history.push(`/solicitud-credito/${id}/otros-creditos`);
    } catch (error) {
      if (error.status === 400) {
        this.setState({
          errores1: error.data,
          errores2: error.data
        })
      }
    }
  }

  render() {
    const {
      referencia1,
      referencia2,
      errores1,
      errores2
    } = this.state;
    return (
      <Autenticado menuSelected="solicitud-credito">
        <TituloBotonDerecha
          icono={imgCredito}
          titulo="Referencias personales"
          descripcion1="* campos obligatorios"
          descripcion2=""
        />
        <div className="row mt2">
          <div className="col-12">
            <h4>Referencia 01</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Input
              label="Nombre *"
              name="nombre"
              placeholder="Nombre_"
              value={referencia1.nombre}
              error={errores1.nombre}
              onChange={this.handleChange1}
            />
          </div>
          <div className="col-sm-6">
            <Input
              label="Parentesco *"
              name="parentesco"
              placeholder="Parentesco_"
              value={referencia1.parentesco}
              error={errores1.parentesco}
              onChange={this.handleChange1}
            />
          </div>
        </div>
        <div className="row border-bottom">
          <div className="col-sm-6">
            <Input
              label="Domicilio *"
              name="domicilio"
              placeholder="Domicilio_"
              value={referencia1.domicilio}
              error={errores1.domicilio}
              onChange={this.handleChange1}
            />
          </div>
          <div className="col-sm-6">
            <Input
              label="Teléfono *"
              name="telefono"
              placeholder="Teléfono_"
              value={referencia1.telefono}
              maxLength={10}
              error={errores1.telefono}
              onChange={this.handleChange1}
            />
          </div>
        </div>
        <div className="row mt1">
          <div className="col-12">
            <h4>Referencia 02</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Input
              label="Nombre *"
              name="nombre"
              placeholder="Nombre_"
              value={referencia2.nombre}
              error={errores2.nombre}
              onChange={this.handleChange2}
            />
          </div>
          <div className="col-sm-6">
            <Input
              label="Parentesco *"
              name="parentesco"
              placeholder="Parentesco_"
              value={referencia2.parentesco}
              error={errores2.parentesco}
              onChange={this.handleChange2}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Input
              label="Domicilio *"
              name="domicilio"
              placeholder="Domicilio_"
              value={referencia2.domicilio}
              error={errores2.domicilio}
              onChange={this.handleChange2}
            />
          </div>
          <div className="col-sm-6">
            <Input
              label="Teléfono *"
              name="telefono"
              placeholder="Teléfono_"
              value={referencia2.telefono}
              maxLength={10}
              error={errores2.telefono}
              onChange={this.handleChange2}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex-between">
            <a
              className="btn btn-secondary mt2"
              href="/solicitud-credito"
            >
              Regresar
            </a>
            <button
              className="btn btn-success mt2"
              type="button"
              onClick={this.submit}
            >
              Siguiente
            </button>
          </div>
        </div>
      </Autenticado>
    )
  }
}

export default Referencias
