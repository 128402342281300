import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function solicitarDisposicion (monto) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/disposiciones/solicitar/', { monto });
  } catch (err) {
    callError(err);
  }
}

async function cotizarDisposicion (monto) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post('/prestamos/prestamo-express/cotizar/', { monto });
  } catch (err) {
    callError(err);
  }
}

export {
  solicitarDisposicion,
  cotizarDisposicion
}