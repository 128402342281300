import React from 'react';
import iconHomeActive from '../../images/iconos/dashboard_icon_menu_active.svg';
import iconHome from '../../images/iconos/dashboard_icon_menu.svg';

const MenuItem = ({href, name, classMenu, menuSlug, icon, iconActive, menuSelected, menuHover, changeMenuOver, onClick=null}) => (
  <a href={href} onClick={onClick} className={`${classMenu}${menuSelected === menuSlug ? ' active' : ''}`} onMouseOver={() => changeMenuOver(menuSlug)} onMouseOut={() => changeMenuOver('')} >
    <span className="icon-holder"><img src={ menuHover === menuSlug || menuSelected === menuSlug ? iconActive : icon } alt={name} /></span>
    <span className="title-sb">{ name }</span>
  </a>
)

export default MenuItem;
