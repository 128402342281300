import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function obtenerInfoDashboard () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/empleados/dashboard/')
  } catch (err) {
    callError(err)
  }
}

export {
  obtenerInfoDashboard
}