import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function obtenerProveedores () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/servicios/proveedores/')
  } catch (err) {
    callError(err)
  }
}

async function obtenerProductosPorProveedor (idProveedor) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/servicios/proveedores/${idProveedor}/productos/`)
  } catch (err) {
    callError(err)
  }
}

async function obtenerServiciosFrecuentes () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/servicios/frecuentes/')
  } catch (err) {
    callError(err)
  }
}

async function actualizarServiciosFrecuentes (datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post('/servicios/frecuentes/', datos)
  } catch (err) {
    callError(err)
  }
}

async function pagarServicio (idServicio, datos) {
  const API = await ConstructorAPIAsync(180000)
  try {
    return await API.post(`/servicios/pagar-servicio/${idServicio}/`, datos)
  } catch (err) {
    callError(err)
  }
}

async function detalleDePago (id) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/servicios/detalle-pago/${id}/`)
  } catch (err) {
    callError(err)
  }
}

async function enviarEmailPago (idPago) {
  const API = await ConstructorAPIAsync(180000);
  try {
    return await API.post(`/servicios/detalle-pago/${idPago}/enviar-email/`);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerProveedores,
  obtenerProductosPorProveedor,
  obtenerServiciosFrecuentes,
  actualizarServiciosFrecuentes,
  pagarServicio,
  detalleDePago,
  enviarEmailPago
}