import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function Auth (datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post('/auth/login/', datos)
  } catch (err) {
    callError(err)
  }
}

async function cambiarContrasena (contrasenaActual, nuevaContrasena) {
  const API = await ConstructorAPIAsync()
  try {
    const datos = {
      current_password: contrasenaActual,
      new_password: nuevaContrasena
    }
    return await API.post('/usuarios/cambiar-contrasena/', datos)
  } catch (err) {
    callError(err)
  }
}

async function obtenerInfoUsuario () {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/usuarios/info/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerEmpleado (idBase64, uuid) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/activar-cuenta/${idBase64}/${uuid}/`);
  } catch (err) {
    callError(err);
  }
}

async function activarCuenta (idBase64, uuid, password) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/activar-cuenta/${idBase64}/${uuid}/`, { password, terminos_condiciones: true })
  } catch (err) {
    callError(err)
  }
}

async function resetearContrasena (email) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post('/auth/password-reset/', { email })
  } catch (err) {
    callError(err)
  }
}

async function confirmarResetearContrasena (idBase64, uuid, password) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/reset/${idBase64}/${uuid}/`, { password })
  } catch (err) {
    callError(err)
  }
}

export {
  Auth,
  obtenerInfoUsuario,
  obtenerEmpleado,
  activarCuenta,
  cambiarContrasena,
  resetearContrasena,
  confirmarResetearContrasena
}