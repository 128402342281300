import React, { Component } from 'react';
import NoAutenticado from '../components/NoAutenticado';
import Input from '../components/form/Input';
import { obtenerEmpleado, activarCuenta } from '../api/Auth';
import logo from '../images/login/logo_yuhu_blanco.svg';
import iconoUsuario from '../images/login/icono_nombre_usuario.svg';
import iconoPass from '../images/login/icono_contrasena.svg';
import { cargando, mostrarToast, cerrarAlertas } from '../utils/alerts'
import Swal from "sweetalert2";
import spinner from '../images/spinner.gif';


export class ActivarCuenta extends Component {
  state = {
    empleado: {
      apellido_materno: '',
      apellido_paterno: '',
      email: '',
      empresa: '',
      nombre: ''
    },
    password: '',
    confirmarPassword: '',
    terminos: false,
    id: '',
    uuid: '',
    errores: {},
  }

  componentDidMount = async () => {
    cargando();
    const {
      match: {
        params: { id, uuid }
      }
    } = this.props;
    try {
      const resp = await obtenerEmpleado(id, uuid);
      let empleado = resp.data;
      cerrarAlertas();

      this.setState({ id, uuid, empleado: empleado });

      if(empleado.activo){
         // Mostramos alerta de que ya es un usuario activo
        Swal.fire({
          // title: 'Usuario activado',
          html: `<div>Al parecer tu usuario ya ha sido activado anteriormente, por favor inicia sesión. <br><br><a class="btn btn-success" href="/iniciar-sesion">Iniciar sesión</a></div>`,
          showConfirmButton: false,
          position: 'center',
          allowOutsideClick: false,
          allowEscapeKey: false,
          // imageUrl: spinner,
          // imageWidth: 100,
          // imageHeight: 100,
          // imageAlt: 'Espera un momento'
        });
      }

    } catch (error) {
      console.log(error);
      cerrarAlertas();
      this.props.history.push('/iniciar-sesion');
    }
  }

  handleChange = (e) => {
    const {target: {name, value}} = e;
    this.setState({ [name]: value })
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      password, confirmarPassword, terminos
    } = this.state;

    if (!terminos) {
      validado = false;
      errores.terminos = ['Debes aceptar los terminos y condiciones.'];
    }
    if (password.trim().length === 0) {
      validado = false;
      errores.password = ['Este campo es requerido.'];
    }
    if (confirmarPassword.trim().length === 0) {
      validado = false;
      errores.confirmarPassword = ['Este campo es requerido.'];
    } else if (password !== confirmarPassword) {
      validado = false;
      errores.confirmarPassword = ['No coinciden las contraseñas.'];
    }

    this.setState({ errores })
    return validado;
  }

  activarCuenta = async () => {
    if(!this.validar()) { return; }
    cargando();
    try {
      const { id, uuid, password } = this.state;
      await activarCuenta(id, uuid, password);
      cerrarAlertas();
      mostrarToast('Usuario activado exitosamente');
      this.props.history.push('/iniciar-sesion');
    } catch (error) {
      console.log('Error:', error);
      cerrarAlertas();
      if (error.status === 400) {
        this.setState({ errores: error.data });
      } else {
        mostrarToast(error.message, 'error')
      }
    }
  }

  render () {
    const {
      password,
      confirmarPassword,
      terminos,
      empleado,
      errores
    } = this.state;
    return (
      <NoAutenticado>
        <div className="container">
          <div className="row">
            <div className="col-12">
                <img src={logo} alt="yuhu" className="logo-login" />
            </div>
          </div>
          <div className="row mt3">
            <div className="col-sm-5 offset-sm-7 login-box">
                <div className="titulo-box">
                  <span>activa tu usuario</span>
                </div>
                <div className="contenido-box">
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <h5>
                        Bienvenido {empleado.nombre}.
                      </h5>
                      <p>Para activar tu cuenta de usuario solo introduce tu nueva contraseña.</p>
                    </div>
                  </div>
                  <div className="row mt1">
                    <div className="col-1 mb1">
                      <img src={iconoPass} alt="contraseña" />
                    </div>
                    <div className="col-11 text-left">
                      <Input
                        type="password"
                        name="password"
                        placeholder="ingresa tu nueva contraseña_"
                        value={password}
                        error={errores.password}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 mb1">
                      <img src={iconoPass} alt="contraseña" />
                    </div>
                    <div className="col-11 text-left">
                      <Input
                        type="password"
                        name="confirmarPassword"
                        placeholder="confirma tu nueva contraseña_"
                        value={confirmarPassword}
                        error={errores.confirmarPassword}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <label>
                        <input
                          type="checkbox"
                          checked={terminos}
                          onChange={() => this.setState({ terminos: !terminos })}
                        />
                        <span className="pl0-5">
                          Acepto los <a href="#">Terminos y condiciones</a>
                        </span>
                      </label>
                      {
                        errores.terminos && errores.terminos.map((obj, index) => (
                          <div key={`err${index}`} className="invalid-feedback" style={{ display: 'block' }}>
                            <b>{obj}</b>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <button
                        type="button"
                        className="btn btn-success btn-block btn-lg"
                        onClick={ this.activarCuenta }
                      >
                        activar cuenta
                      </button>
                      {
                        errores.non_field_errors && errores.non_field_errors.map((obj, index) => (
                          <div key={`err${index}`} className="invalid-feedback" style={{ display: 'block' }}>
                            <b>{obj}</b>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <h5>
                        La plataforma financiera que te ayuda a conseguir tus sueños.
                      </h5>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          {/* <div className="row mt3">
            <div className="col-12 text-center verde">
              <h1><b>en 15 minutos te confirmamos vía whatsapp</b></h1>
            </div>
          </div> */}

        {/*  Alerta por si empleado esta activo  */}
        {/*  <SweetAlert*/}
        {/*    show={this.state.showConfirm}*/}
        {/*    title="Usuario activo"*/}
        {/*    htmlText={`Hola ${empleado.nombre}, tu usuario ya ha sido activado anteriormente, por favor inicia sesión`}*/}
        {/*    showCancelButton={true}*/}
        {/*    cancelButtonText='Cancelar'*/}
        {/*    confirmButtonText='Confirmar'*/}
        {/*    onConfirm={() => this.setState({})}*/}
        {/*    onCancel={() => this.setState({showConfirm: false})}*/}
        {/*  />*/}

        </div>
        <style jsx="true">{`
          .pl0-5 {
            padding-left: 0.5rem;
          }
        `}</style>
      </NoAutenticado>
    )
  }
}

export default ActivarCuenta
