import React from 'react';

const Select = ({
  options,
  label,
  value,
  name,
  helpText = null,
  error = [],
  onChange
}) => {
  const optionsMap = options.map(option => {
    return (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    );
  });

  return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <select
          value={value}
          name={name}
          id={name}
          onChange={onChange}
          className={`form-control ${error.length > 0 ? 'is-invalid' : ''}`}
        >
          {optionsMap}
        </select>
        {helpText && <small className="form-text text-muted">{helpText}</small>}
        {error.map((obj, index) => (
          <div key={`${name}${index}`} className="invalid-feedback">
            {obj}
          </div>
        ))}
      </div>
  );
};

export default Select;
