import React, { Component } from 'react';
import moment from 'moment';
import {
  obtenerMisPrestamos,
  obtenerDetallePrestamo
} from '../../api/EstadoDeCuenta';
import Autenticado from '../../components/Autenticado';
import TituloSaldo from '../../components/general/TituloSaldo';
import Cotizacion from '../../components/estadodecuenta/Cotizacion';
import Card from '../../components/general/Card';
import InfoRow from '../../components/general/InfoRow';
import Select from '../../components/form/Select';
import imgEdoCuenta from '../../images/iconos/estado_cuenta.svg';
import {
  currencyFormat
} from '../../utils/filters';

const PLAZOS2 = {
  semanal: 'semanal',
  quincenal: 'quincenal',
  catorcenal: 'catorcenal',
  decenal: 'decenal',
  mensual: 'mensual'
};

class Disposicion extends Component {
  state = {
    prestamos: [{label: '--Selecciona--', value: ''}],
    prestamo: '',
    detalle: {}
  };

  componentDidMount = async () => {
    try {
      const resp = await obtenerMisPrestamos();
      const options = [{label: '--Selecciona--', value: ''}];
      resp.data.forEach(p => {
        options.push({
          label: `Fecha: ${moment(p.fecha_creacion).format('DD-MM-YYYY')}, Folio: ${p.folio}, Monto: ${currencyFormat(p.monto)}`,
          value: p.id
        });
      });
      this.setState({ prestamos: options });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  handleChange = async (e) => {
    const { target: {value} } = e;
    if (!value) {return;}
    try {
      const resp = await obtenerDetallePrestamo(value);
      this.setState({ prestamo: value, detalle: resp.data });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  render() {
    const {
      prestamos,
      prestamo,
      detalle
    } = this.state;
    return (
      <Autenticado menuSelected="estado-cuenta">
        <TituloSaldo
          icono={imgEdoCuenta}
          titulo="Estado de cuenta"
          descripcion1=""
          descripcion2=""
        />
        {
          prestamos.length > 1
            ? (
              <div className="row mt2">
                <div className="col-sm-4">
                  <Select
                    label="Selecciona un préstamo"
                    name="prestamo"
                    value={prestamo}
                    options={prestamos}
                    onChange={this.handleChange}
                  />
                  {/* <Select options={prestamos} onChange={this.handleChange} styles={customStyles} /> */}
                </div>
              </div>
            )
            : (
              <div className="row mt2">
                <div className="col-12 flex-center">
                  <h5>No tienes préstamos activos</h5>
                </div>
              </div>
            )
        }
        {
          detalle.id && (
            <div className="row mb1 mt2">
              <div className="col-12">
                <Card padding="1rem" titulo="Detalle del prestamo">
                  <div className="row">
                    <div className="col-sm-6">
                      <InfoRow label="Folio" value={detalle.folio} />
                      <InfoRow label="Tipo" value={detalle.tipo} />
                      <InfoRow label="Plazo" value={detalle.plazo} />
                      <InfoRow label="Fecha de dispersión" value={detalle.fecha_dispersion ? moment(detalle.fecha_dispersion).format('DD-MM-YYYY') : 'N/A'} />
                      <InfoRow label="Fecha de liquidación" value={detalle.fecha_liquidacion ? moment(detalle.fecha_liquidacion).format('DD-MM-YYYY') : 'N/A'} />
                      <InfoRow label="Estatus" value={detalle.estatus} />
                    </div>
                    <div className="col-sm-6">
                    <InfoRow label={`Pago ${PLAZOS2[detalle.frecuencia_cobro]}`} value={currencyFormat(detalle.monto_pago)} />
                      <InfoRow label="Tasa de interes" value={`${detalle.tasa_interes}%`} />
                      <InfoRow label="Interes total" value={currencyFormat(detalle.interes_total)} />
                      <InfoRow label="IVA" value={currencyFormat(detalle.iva_total)} />
                      <InfoRow label="Monto del préstamo" value={currencyFormat(detalle.monto)} />
                      <InfoRow label="Capital" value={currencyFormat(detalle.capital)} />
                      <InfoRow label="Total pagado" value={currencyFormat(detalle.total_pagado)} />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          )
        }
        {
          detalle.id && (
            <div className="row mt1">
              <div className="col-12">
                <h5>Tabla de amortizaciones</h5>
                <Cotizacion
                  cotizacion={detalle}
                  fontSize="1rem"
                  esModal={false}
                  colorTotal="#ff6b6b"
                  colorSaldo="#25d07b"
                />
              </div>
            </div>
          )
        }
      </Autenticado>
    )
  }
}

export default Disposicion
