import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import Card from '../../components/general/Card';
import imgPago from '../../images/iconos/pago.svg';
import imgDinero from '../../images/iconos/dinero1.svg';
import {
  obtenerProveedores,
  obtenerServiciosFrecuentes
} from '../../api/Servicios';

class ListaServicios extends Component {
  state = {
    proveedores: [],
    frecuentes: []
  }

  componentDidMount = async () => {
    try {
      const promesas = [];
      promesas.push(obtenerProveedores());
      promesas.push(obtenerServiciosFrecuentes());
      const [respProveedores, respFrecuentes] = await Promise.all(promesas);
      this.setState({
        proveedores: respProveedores.data,
        frecuentes: respFrecuentes.data
      });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  render() {
    const {
      proveedores,
      frecuentes
    } = this.state;
    return (
      <Autenticado menuSelected="pago-servicios">
        <div className="row mb1 row-mt-15">
          <div className="col-md-12">
            <div className="flex-start">
              <img src={imgPago} alt="Pago" className="icono-titulo-seccion" />
              <div className="div-titulo-seccion">
                <h3 className="titulo-seccion">Pago de servicios</h3>
                <p className="subtitulo-seccion">
                  Realiza tus pagos de la manera más sencilla y segura.<br />
                  Busca aquí el proveedor de servicio que deseas pagar.
                </p>
                <a href="/servicios/frecuentes" className="btn btn-success btn-sm">Editar pagos frecuentes</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb1 mt2">
          <div className="col-md-7">
            <div className="border-top-green" />
            <span className="subtitulo-seccion">Pagos frecuentes</span>
            <div className="row">
              {
                frecuentes.map(f => (
                  <div key={`frec${f.id}`} className="col-sm-6 mb1">
                    <Card mostrarTitulo={false} padding="0.5rem">
                      <div className="flex-center" style={{ height: '100%' }}>
                        <div style={{flex: 2, padding: '0 0.5rem'}}>
                          <img
                            src={f.imagen}
                            alt={f.nombre}
                            className="img-fluid"
                          />
                        </div>
                        <div style={{flex: 3}}>
                          <span className="titulo-servicio">{f.nombre}</span>
                          <div className="border-top" />
                          {/* <small style={{ fontSize: '0.6rem', color: '#AAA' }}>Servicio hogar</small> */}
                          <div className="mt1">
                            <a href={`/servicios/${f.id}/pago`} className="btn btn-success btn-sm">
                              Seleccionar
                            </a>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="col-md-5">
            <div className="border-top-green" />
            <span className="subtitulo-seccion">Otros servicios</span>
            <div className="row">
              {
                proveedores.map(p => (
                  <div key={`prov${p.id}`} className="col-4 mb1">
                    <a href={`/servicios/${p.id}/pago`} className="text-decoration-none">
                      <div className="square-service">
                        <div className="thumbnail">
                          <img
                            src={p.imagen}
                            alt={p.nombre}
                            className="img-fluid"
                          />
                        </div>
                        <b style={{ fontSize: '0.6rem', color: '#333' }}>
                          {p.nombre}
                        </b>
                      </div>
                    </a>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </Autenticado>
    )
  }
}

export default ListaServicios
