import React from 'react';

const InputMonto = ({
  label,
  name,
  value,
  placeholder = '',
  readonly = false,
  helpText = null,
  error = [],
  maxLength = 256,
  bgColor = '#e4e0e6',
  labelColor = '#333',
  valueColor = '#25d07b',
  onChange
}) => (
  <div className="form-group input-monto" style={{ backgroundColor: bgColor }}>
    <label htmlFor={name} style={{ color: labelColor }}>{label}</label>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text simbolo" style={{ color: valueColor }}>$</span>
      </div>
      <input
        type="text"
        className={`form-control no-outline ${error.length > 0 ? 'is-invalid' : ''}`}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        readOnly={readonly}
        maxLength={maxLength}
        style={{ color: valueColor }}
        onChange={onChange}
      />
    </div>
    {helpText && <small className="form-text text-muted">{helpText}</small>}
    {error.map((obj, index) => (
      <div key={`${name}${index}`} className="invalid-feedback">
        {obj}
      </div>
    ))}
    <style jsx="true">{`
      .simbolo {
        padding: 0px;
        padding-right: 0.2rem;
        background-color: transparent;
        border: 0px;
        font-weight: bold;
        font-size: 2rem;
      }
      .invalid-feedback {
        display: block;
      }
      .input-monto input:focus {
        background-color: ${bgColor};
        border-color: transparent;
        box-shadow: none;
      }
    `}</style>
  </div>
)

export default InputMonto
