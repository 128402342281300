import React, { Component } from 'react';
import moment from 'moment';
import Autenticado from '../../components/Autenticado';
import Card from '../../components/general/Card';
import InfoRow from '../../components/general/InfoRow';
import {
  obtenerPerfil
} from '../../api/Empleados';
import {capitalizeOnlyFirst, currencyFormat} from '../../utils/filters';

class PerfilVer extends Component {
  state = {
    infoEmpleado: {
      domicilio: {
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        codigo_postal: '',
        municipio_str: '',
        estado_str: '',
      },
      informacion_bancaria: {
        banco: '',
        cuenta: '',
        clabe: ''
      },
      percepciones: {
        salario_diario: '',
        salario_diario_integrado: '',
        sueldo_mensual: '',
        sueldo_mensual_real: ''
      }
    }
  }

  componentDidMount = async () => {
    try {
      const resp = await obtenerPerfil();
      const infoEmpleado = resp.data;
      if (!infoEmpleado.domicilio) {
        infoEmpleado.domicilio = {
          calle: '',
          numero_exterior: '',
          numero_interior: '',
          colonia: '',
          codigo_postal: '',
          municipio_str: '',
          estado_str: '',
        };
      }
      if (!infoEmpleado.informacion_bancaria) {
        infoEmpleado.informacion_bancaria = {
          banco: '',
          cuenta: '',
          clabe: ''
        };
      }
      if (!infoEmpleado.percepciones) {
        infoEmpleado.percepciones = {
          salario_diario: '',
          salario_diario_integrado: '',
          sueldo_mensual: '',
          sueldo_mensual_real: ''
        };
      }
      this.setState({ infoEmpleado });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  render() {
    const { infoEmpleado } = this.state;
    return (
      <Autenticado menuSelected="perfil">
        <div className="row mb1">
          <div className="col-md-8">
            <Card titulo="Información del empleado">
              <div className="row">
                <div className="col-md-6">
                  <InfoRow label="Nombre" value={`${infoEmpleado.nombre || ''} ${infoEmpleado.apellido_paterno || ''} ${infoEmpleado.apellido_materno || ''}`} />
                  <InfoRow label="Fecha de nacimiento" value={infoEmpleado.fecha_nacimiento ? moment(infoEmpleado.fecha_nacimiento).format('LL') : ''} />
                  <InfoRow label="RFC" value={infoEmpleado.rfc} />
                  <InfoRow label="CURP" value={infoEmpleado.curp} />
                  <InfoRow label="Género" value={capitalizeOnlyFirst(infoEmpleado.genero)} />
                  <InfoRow label="Nacionalidad" value={infoEmpleado.nacionalidad} />
                  <InfoRow label="Estado civil" value={capitalizeOnlyFirst(infoEmpleado.estado_civil)} />
                  <InfoRow label="E-mail" value={infoEmpleado.email} />
                  <InfoRow label="UUID" value={infoEmpleado.uuid} />
                </div>
                <div className="col-md-6">
                  <InfoRow label="Teléfono casa" value={infoEmpleado.telefono_casa} />
                  <InfoRow label="Celular" value={infoEmpleado.telefono_celular} />
                  <InfoRow label="Empresa" value={infoEmpleado.empresa_str} />
                  <InfoRow label="Puesto" value={infoEmpleado.puesto} />
                  <InfoRow label="Fecha de contratación" value={infoEmpleado.fecha_contratacion ? moment(infoEmpleado.fecha_contratacion).format('LL') : ''} />
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4">
            <Card titulo="Domicilio">
              <InfoRow label="Calle" value={infoEmpleado.domicilio.calle} />
              <InfoRow label="Número" value={`${infoEmpleado.domicilio.numero_exterior}${infoEmpleado.domicilio.numero_interior ? ` int. ${infoEmpleado.domicilio.numero_interior}` : ''}`} />
              <InfoRow label="Colonia" value={infoEmpleado.domicilio.colonia} />
              <InfoRow label="Código Postal" value={infoEmpleado.domicilio.codigo_postal} />
              <InfoRow label="Municipio" value={infoEmpleado.domicilio.municipio_str} />
              <InfoRow label="Estado" value={infoEmpleado.domicilio.estado_str} />
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Card titulo="Información bancaria">
              <InfoRow label="Banco" value={infoEmpleado.informacion_bancaria.banco_str} />
              <InfoRow label="Cuenta" value={infoEmpleado.informacion_bancaria.cuenta} />
              <InfoRow label="Clabe" value={infoEmpleado.informacion_bancaria.clabe} />
            </Card>
          </div>
          <div className="col-md-4">
            <Card titulo="Percepciones">
              <InfoRow label="Salario diario" value={currencyFormat(infoEmpleado.percepciones.salario_diario)} />
              <InfoRow label="Salario diario integrado" value={currencyFormat(infoEmpleado.percepciones.salario_diario_integrado)} />
              <InfoRow label="Sueldo mensual" value={currencyFormat(infoEmpleado.percepciones.sueldo_mensual)} />
              <InfoRow label="Sueldo mensual real" value={currencyFormat(infoEmpleado.percepciones.sueldo_mensual_real)} />
            </Card>
          </div>
        </div>
      </Autenticado>
    )
  }
}

export default PerfilVer
