import React, { Component } from 'react';
import {
  obtenerDocumentos,
  agregarDocumento,
  eliminarDocumento,
  terminarDocumentos
} from '../../api/Solicitudes';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import imgCredito from '../../images/iconos/credito.svg';
import imgOkVerde from '../../images/iconos/okverde.svg';
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts';

class FirmarDocumentos extends Component {
  state = {
    documentos: []
  }

  componentDidMount = async () => {
    cargando();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      const resp = await obtenerDocumentos(id);
      const documentos = resp.data;
      documentos.forEach(d => {
        d.documentos_firmados.forEach(df => {
          const nameArray = df.documento.split('/');
          df.nombreArchivo = nameArray[nameArray.length - 1];
        });
      })
      console.log('Resp:', documentos);
      this.setState({ documentos });
    } catch (error) {
      console.log('Error:', error)
      if (error.status === 404) {
        console.log('Error404:')
        this.props.history.push('/pagina-no-encontrada');
      }
    }
    cerrarAlertas();
  }

  handleImageChange = async (e) => {
    e.preventDefault();
    cargando()
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { target: { name, files } } = e;
    console.log('Files:', files);
    // const file = files[0];
    if (files[0]) {
      const { documentos } = this.state;
      for (let i = 0; i < files.length; i++) {
        const nameArray = name.split('_');
        const documento = documentos.find(c => c.id === parseInt(nameArray[1]));
        const nombreArchivo = files[i].name;
        const formData = new FormData();
        formData.append('documento', files[i]);
        try {
          const resp = await agregarDocumento(id, documento.id, formData);
          documento.documentos_firmados.push({
            ...resp.data,
            nombreArchivo
          });
        } catch (error) {
          console.log('Error:', error);
        }
      }
      console.log('Documentos:', documentos);
      this.setState({ documentos });
    }
    cerrarAlertas();
    mostrarToast("Documentos enviados correctamente.");
  }

  validar = () => {
    let validado = true;
    const { documentos } = this.state;

    documentos.forEach(d => {
      if (d.documentos_firmados.length === 0) {
        validado = false;
      }
    });

    mostrarToast("Algunos documentos aun no estan firmados.", "error");
    return validado;
  }

  submit = async () => {
    cargando()
    if (!this.validar()) { return; }
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      await terminarDocumentos(id);
      cerrarAlertas();
      mostrarToast("Firma de documentos terminada exitosamente.")
      this.props.history.push(`/`);
    } catch (error) {
      console.log('Error:', error);
      cerrarAlertas();
      mostrarToast("Ocurrio un error al terminar la firma de documentos.", "error");
    }
    
  }

  eliminarDocumentoFirmado = async (idDocumento, idDocumentoFirmado) => {
    cargando()
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      await eliminarDocumento(id, idDocumento, idDocumentoFirmado);
      const {documentos} = this.state;
      const documento = documentos.find(c => c.id === idDocumento);
      documento.documentos_firmados.splice(documento.documentos_firmados.findIndex(v => v.id === idDocumentoFirmado), 1);
      this.setState({ documentos });
      cerrarAlertas();
      mostrarToast("Documento eliminado correctamente.");
    } catch (error) {
      console.log('Error:', error);
      cerrarAlertas()
      mostrarToast("Ocurrio un error al eliminar este documento.", "error");
    }
  }

  render() {
    const {
      documentos
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Autenticado menuSelected="solicitud-credito">
        <TituloBotonDerecha
          icono={imgCredito}
          titulo="Firmar contratos"
          descripcion1="En esta pagina puedes descargar los contratos solicitados para posteriormente enviarlos firmados."
          descripcion2=""
        />
        <div className="row mt2">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Nombre del documento</th>
                    <th>Descarga documento</th>
                    <th>Subir documentos firmados</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    documentos.map(d => (
                      <tr key={d.id}>
                        <td>{d.nombre}</td>
                        <td>
                          <a target="_blank" href={d.documento} rel="noopener noreferrer" className="btn btn-primary btn-sm btn-block">
                            <i className="fa fa-download" aria-hidden="true"></i>{' '}
                            Descargar
                          </a>
                        </td>
                        <td>
                          <div className="custom-file">
                            <input
                              type="file"
                              className={`custom-file-input ${d.error ? 'is-invalid' : ''}`}
                              id={`comprobante${d.id}`}
                              name={`comprobante_${d.id}`}
                              multiple
                              onChange={this.handleImageChange}
                            />
                            <label className="custom-file-label" htmlFor={`comprobante${d.id}`}>{d.nombreArchivo}</label>
                          </div>
                          {
                            d.documentos_firmados.map(df => (
                              <div className="row" key={`df${df.id}`}>
                                <div className="col-12 flex-between">
                                  <a href={df.documento} target="_blank" rel="noopener noreferrer">
                                    <img src={imgOkVerde} alt="Ok" width="16" />
                                    <small className="archivo"> {df.nombreArchivo}</small>
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => this.eliminarDocumentoFirmado(d.id, df.id)}
                                  >
                                    <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </div>
                            ))
                          }
                        </td>
                      </tr>
                      // <div key={`comp${c.id}`} className="row mt1">
                      //   <div className="col-5 comprobanteLabel">
                      //     {c.nombre}
                      //   </div>
                      //   <div className="col-7">
                      //     <div key={c.id} className="custom-file">
                      //       <input
                      //         type="file"
                      //         className={`custom-file-input ${c.error ? 'is-invalid' : ''}`}
                      //         id={`comprobante${c.id}`}
                      //         name={`comprobante_${c.id}`}
                      //         onChange={this.handleImageChange}
                      //       />
                      //       <label className="custom-file-label" htmlFor={`comprobante${c.id}`}>{c.nombreArchivo}</label>
                      //     </div>
                      //     {
                      //       c.error && (
                      //         <div className="invalid-feedback" style={{display: 'inline'}}>
                      //           {c.error}
                      //         </div>
                      //       )
                      //     }
                      //   </div>
                      //   {
                      //     c.documento && (
                      //       <div className="col-7 offset-5">
                      //         <a href={c.documento} target="_blank" rel="noopener noreferrer">
                      //           <img src={imgOkVerde} alt="Ok" width="16" />
                      //           <small className="archivo"> Archivo</small>
                      //         </a>
                      //       </div>
                      //     )
                      //   }
                      // </div>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex-between">
            <button
              className="btn btn-success mt2"
              type="button"
              onClick={this.submit}
            >
              Finalizar
            </button>
          </div>
        </div>
        <style jsx="true">{`
          .flex-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
            padding-left: 0px !important;
            color: #25d07b !important;
          }
          .flex-row img {
            width: 20px;
            margin-right: 0.5rem;
          }
          .comprobanteLabel {
            color: #25d07b;
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
          .archivo {
            color: #333;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default FirmarDocumentos
