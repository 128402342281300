import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function obtenerMisPrestamos () {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/mis-prestamos/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerDetallePrestamo (idPrestamo) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/mis-prestamos/${idPrestamo}/`)
  } catch (err) {
    callError(err)
  }
}



export {
  obtenerMisPrestamos,
  obtenerDetallePrestamo
}