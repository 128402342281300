import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function obtenerEstados () {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/estados/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerMunicipios (idEstado) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/estados/${idEstado}/municipios/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerBancos () {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/bancos/');
  } catch (err) {
    callError(err);
  }
}

async function cambiarImagenPerfil (datos) {
  const API = await ConstructorAPIAsync(20000);
  try {
    return await API.put('usuarios/cambiar-imagen-perfil/', datos);
  } catch (err) {
    callError(err);
  }
}

async function verificarServicio (servicio) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/verificar-servicio/${servicio}/`);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerEstados,
  obtenerMunicipios,
  obtenerBancos,
  cambiarImagenPerfil,
  verificarServicio
}