import React, { Component } from 'react';
import Autenticado from '../components/Autenticado';
import Input from '../components/form/Input';
import {
  cambiarContrasena
} from '../api/Auth';
import { cargando, mostrarToast, cerrarAlertas } from '../utils/alerts';

class CambiarContrasena extends Component {
  state = {
    current_password: '',
    new_password: '',
    confirmarNuevaContrasena: '',
    errores: {}
  }

  handleChange = (e) => {
    const {target: {name, value}} = e;
    this.setState({ [name]: value })
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      current_password,
      new_password,
      confirmarNuevaContrasena
    } = this.state;

    if (current_password.trim().length === 0) {
      validado = false;
      errores.current_password = ['Este campo es requerido.'];
    }
    if (new_password.trim().length === 0) {
      validado = false;
      errores.new_password = ['Este campo es requerido.'];
    }
    if (confirmarNuevaContrasena.trim().length === 0) {
      validado = false;
      errores.confirmarNuevaContrasena = ['Este campo es requerido.'];
    } else if (new_password !== confirmarNuevaContrasena) {
      validado = false;
      errores.confirmarNuevaContrasena = ['No coincide la nueva contraseña con la conformación.'];
    }

    this.setState({ errores });
    return validado;
  }

  guardar = async () => {
    if (!this.validar()) { return; }
    cargando();
    try {
      const { current_password, new_password } = this.state;
      await cambiarContrasena(current_password, new_password);
      cerrarAlertas();
      this.setState({
        current_password: '',
        new_password: '',
        confirmarNuevaContrasena: ''
      });
      mostrarToast('Contraseña actualizada exitosamente.')
    } catch (error) {
      console.log('Error:', error);
      cerrarAlertas();
      if (error.status === 400) {
        this.setState({ errores: error.data });
      } else {
        mostrarToast(error.message, 'error')
      }
    }
  }

  render() {
    const {
      current_password,
      new_password,
      confirmarNuevaContrasena,
      errores
    } = this.state;
    return (
      <Autenticado menuSelected="cambiar-contrasena">
        <div className="row mb1 row-mt-15">
          <div className="col-md-12">
            <div className="titulo">
              <h3 className="titulo-seccion">Cambiar contraseña</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-md-6 form-group">
            <Input
              label="Contraseña actual"
              name="current_password"
              type="password"
              placeholder="Ingrese su contraseña actual"
              value={current_password}
              error={errores.current_password}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-md-6 form-group">
            <Input
              label="Nueva contraseña"
              name="new_password"
              type="password"
              placeholder="Ingrese su nueva contraseña"
              value={new_password}
              error={errores.new_password}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-md-6 form-group">
            <Input
              label="Confirmar nueva contraseña"
              name="confirmarNuevaContrasena"
              type="password"
              placeholder="Ingrese su nueva contraseña nuevamente"
              value={confirmarNuevaContrasena}
              error={errores.confirmarNuevaContrasena}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-md-6">
            <button type="button" className="btn btn-success" onClick={this.guardar}>Cambiar contraseña</button>
            <a href="/" className="btn btn-secondary ml1">Cancelar</a>
          </div>
        </div>
      </Autenticado>
    )
  }
}

export default CambiarContrasena
