import React, { Component } from 'react';
import NoAutenticado from '../components/NoAutenticado';
import Input from '../components/form/Input';
import { confirmarResetearContrasena } from '../api/Auth';
import logo from '../images/login/logo_yuhu_blanco.svg';
import iconoPass from '../images/login/icono_contrasena.svg';
import { cargando, mostrarToast, cerrarAlertas } from '../utils/alerts'

export class ResetearContrasena extends Component {
  state = {
    password: '',
    confirmarPassword: '',
    id: '',
    uuid: '',
    errores: {},
  }

  componentDidMount = async () => {
    const {
      match: {
        params: { id, uuid }
      }
    } = this.props;
    this.setState({ id, uuid });
  }

  handleChange = (e) => {
    const {target: {name, value}} = e;
    this.setState({ [name]: value })
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      password, confirmarPassword
    } = this.state;

    if (password.trim().length === 0) {
      validado = false;
      errores.password = ['Este campo es requerido.'];
    }
    if (confirmarPassword.trim().length === 0) {
      validado = false;
      errores.confirmarPassword = ['Este campo es requerido.'];
    } else if (password !== confirmarPassword) {
      validado = false;
      errores.confirmarPassword = ['No coinciden las contraseñas.'];
    }

    this.setState({ errores })
    return validado;
  }

  resetearContrasena = async () => {
    if(!this.validar()) { return; }
    cargando();
    try {
      const { id, uuid, password } = this.state;
      await confirmarResetearContrasena(id, uuid, password);
      cerrarAlertas();
      mostrarToast('Contraseña reiniciada exitosamente.');
      this.props.history.push('/iniciar-sesion');
    } catch (error) {
      console.log('Error:', error);
      cerrarAlertas();
      if (error.status === 400) {
        this.setState({ errores: error.data });
      } else {
        mostrarToast(error.message, 'error')
      }
    }
  }

  render () {
    const {
      password,
      confirmarPassword,
      errores
    } = this.state;
    return (
      <NoAutenticado>
        <div className="container">
          <div className="row">
            <div className="col-12">
                <img src={logo} alt="yuhu" className="logo-login" />
            </div>
          </div>
          <div className="row mt3">
            <div className="col-sm-5 offset-sm-7 login-box">
                <div className="titulo-box">
                  <span>reiniciar contraseña</span>
                </div>
                <div className="contenido-box">
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <h5>
                        Bienvenido.
                      </h5>
                      <p>Introduce tu nueva contraseña.</p>
                    </div>
                  </div>
                  <div className="row mt1">
                    <div className="col-1 mb1">
                      <img src={iconoPass} alt="contraseña" />
                    </div>
                    <div className="col-11 text-left">
                      <Input
                        type="password"
                        name="password"
                        placeholder="ingresa tu nueva contraseña_"
                        value={password}
                        error={errores.password}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 mb1">
                      <img src={iconoPass} alt="contraseña" />
                    </div>
                    <div className="col-11 text-left">
                      <Input
                        type="password"
                        name="confirmarPassword"
                        placeholder="confirma tu nueva contraseña_"
                        value={confirmarPassword}
                        error={errores.confirmarPassword}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <button
                        type="button"
                        className="btn btn-success btn-block btn-lg"
                        onClick={ this.resetearContrasena }
                      >
                        reiniciar contraseña
                      </button>
                      {
                        errores.non_field_errors && errores.non_field_errors.map((obj, index) => (
                          <div key={`err${index}`} className="invalid-feedback" style={{ display: 'block' }}>
                            <b>{obj}</b>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <h5>
                        La plataforma financiera que te ayuda a conseguir tus sueños.
                      </h5>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          {/* <div className="row mt3">
            <div className="col-12 text-center verde">
              <h1><b>en 15 minutos te confirmamos vía whatsapp</b></h1>
            </div>
          </div> */}
        </div>
        <style jsx="true">{`
          .pl0-5 {
            padding-left: 0.5rem;
          }
        `}</style>
      </NoAutenticado>
    )
  }
}

export default ResetearContrasena
