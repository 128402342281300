import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import imgPago from '../../images/iconos/pago_servicio_color.svg';
import {
  obtenerProveedores,
  obtenerServiciosFrecuentes,
  actualizarServiciosFrecuentes
} from '../../api/Servicios';

class ListaServicios extends Component {
  state = {
    proveedores: []
  }

  componentDidMount = async () => {
    try {
      const promesas = [];
      promesas.push(obtenerProveedores());
      promesas.push(obtenerServiciosFrecuentes());
      const [respProveedores, respFrecuentes] = await Promise.all(promesas);
      const proveedores = [];
      respProveedores.data.forEach(p => {
        const frecuente = respFrecuentes.data.find(f => f.id === p.id);
        const proveedor = {...p};
        if (frecuente) {
          proveedor.frecuente = true;
        } else {
          proveedor.frecuente = false;
        }
        proveedores.push(proveedor);
      })
      this.setState({
        proveedores
      });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  handleChange = (id) => {
    const { proveedores } = this.state;
    const proveedor = proveedores.find(p => p.id === parseInt(id));
    proveedor.frecuente = !proveedor.frecuente;
    this.setState({ proveedores });
  }

  submit = async () => {
    const {proveedores} = this.state;
    const frecuentes = proveedores.filter(p => p.frecuente === true);
    const servicios = [];
    frecuentes.forEach(f => {
      servicios.push(f.id);
    });
    try {
      await actualizarServiciosFrecuentes({servicios});
      this.props.history.push('/servicios');
    } catch (error) {
      console.log('Error:', error);
    }
  }

  render() {
    const {
      proveedores
    } = this.state;
    return (
      <Autenticado menuSelected="pago-servicios">
        <TituloBotonDerecha
          titulo="Pago de servicio"
          descripcion1="Selecciona los servicios que utilizas con más frecuencia"
          descripcion2=""
          icono={imgPago}
        />
        <div className="row mt1">
          {
            proveedores.map(p => (
              <div key={`prov${p.id}`} className="col-sm-4 col-6 mt04">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`chkProveedor${p.id}`}
                    checked={p.frecuente}
                    onChange={() => this.handleChange(p.id)}
                  />
                  <label className="custom-control-label" htmlFor={`chkProveedor${p.id}`}>
                    {p.nombre}
                  </label>
                </div>
              </div>
            ))
          }
        </div>
        <div className="row mt1">
          <div className="col-12">
            <button type="button" className="btn btn-success" onClick={this.submit}>
              Guardar
            </button>
          </div>
        </div>
        <style jsx="true">{`
          .custom-control-label {
            color: #333;
            font-size: 0.85rem;;
          }
          .custom-control-input:checked~.custom-control-label::before {
            color: #fff;
            border-color: #25d07b;
            background-color: #25d07b;
          }
          .mt04 {
            margin-top: 0.4rem;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default ListaServicios