import React from 'react';
import {currencyFormat} from '../../utils/filters';

const Meta = ({
  total,
  pagado,
  colorPendiente = '#fff',
  colorPagado = '#e4e0e6',
  width = '100%',
  height = '1rem',
  className = ''
}) => {
  const porcentajePagado = parseInt((parseFloat(pagado) * 100) / parseFloat(total));
  const porcentajePendiente = 100 - porcentajePagado;
  return (
    <div className={`div0 ${className}`}>
      <div className="meta-tooltip">
        <span className="tooltiptext">{currencyFormat(pagado)}</span>
      </div>
      <div className="div1 flex-center">
        <div className="div2" />
        <div className="div3" />
      </div>
      <style jsx="true">{`
        .div0 {
          width: ${width};
          padding: 0px 5px;
        }
        .div1 {
          width: 100%;
        }
        .div2 {
          width: ${porcentajePagado}%;
          height: ${height};
          background-color: ${colorPagado};
        }
        .div3 {
          width: ${porcentajePendiente}%;
          height: ${height};
          background-color: ${colorPendiente};
        }
        .meta-tooltip {
          position: relative;
        }
        .meta-tooltip .tooltiptext {
          visibility: visible;
          background-color: #fff;
          font-size: 0.7rem;
          color: #25d07b;
          text-align: center;
          border-radius: 1px;
          padding: 0px 2px;
          position: absolute;
          z-index: 1;
          bottom: ${height};
          left: ${porcentajePagado}%;
          opacity: 1;
          transition: opacity 0.3s;
        }
        .meta-tooltip .tooltiptext::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 3px;
          margin-left: -3px;
          border-width: 3px;
          border-style: solid;
          border-color: #fff transparent transparent #fff;
        }
      `}</style>
    </div>
  )
}

export default Meta
