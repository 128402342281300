
export function getLinkSolicitud(solicitud){
  const {id, paso_autoattend} = solicitud;

  switch (paso_autoattend) {

    case 'informacion_crediticia':
      return `/solicitud-credito/${id}/otros-creditos`;

    case 'comprobantes':
      return `/solicitud-credito/${id}/comprobantes`;

    case 'aceptacion':
      return `/solicitud-credito/${id}/resumen`;

    default:
      return '/solicitud-credito';

  }
};