import React, { Component } from 'react';
import MenuItem from '../general/MenuItem';
import logo from '../../images/logo.png';
import noProfilePicture from '../../images/profile.png';
// import profilePicture from '../../images/image-profile2.jpeg';
import iconHome from '../../images/iconos/dashboard_icon_menu.svg';
import iconHomeActive from '../../images/iconos/dashboard_icon_menu_active.svg';
import iconSolicitud from '../../images/iconos/solicitud_credito_icon_menu.svg';
import iconSolicitudActive from '../../images/iconos/solicitud_credito_icon_menu_active.svg';
import iconEdoCuenta from '../../images/iconos/estado_cuenta_icon_menu.svg';
import iconEdoCuentaActive from '../../images/iconos/estado_cuenta_icon_menu_active.svg';
import iconAhorro from '../../images/iconos/ahorro_icon_menu.svg';
import iconAhorroActive from '../../images/iconos/ahorro_icon_menu_active.svg';
import iconServicios from '../../images/iconos/servicios_icon_menu.svg';
import iconServiciosActive from '../../images/iconos/servicios_icon_menu_active.svg';
import iconPrepago from '../../images/iconos/prepago.svg';
import iconPrepagoActive from '../../images/iconos/prepago_active.svg';
import iconPerfil from '../../images/iconos/perfil.svg';
import iconPerfilActive from '../../images/iconos/perfil_active.svg';
import iconContrasena from '../../images/iconos/contrasena.svg';
import iconContrasenaActive from '../../images/iconos/contrasena_active.svg';
import iconSesion from '../../images/iconos/close_sesion.svg';
import iconSesionActive from '../../images/iconos/close_sesion_active.svg';
import iconDisposicion from '../../images/iconos/disposicion_icon_menu.svg';
import iconDisposicionActive from '../../images/iconos/disposicion_icon_menu_active.svg';
import { obtenerUrlCajaDeAhorro, obtenerUrlCajaDeAhorroYuhu } from '../../api/Empleados';
import {cambiarImagenPerfil} from '../../api/Utils';
import {obtenerInfoUsuario} from '../../api/Auth';
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts';
import { getUserInfo, setUserInfo } from '../../utils/auth';


class Sidebar extends Component {
  state = {
    menuPerfilDisplay: false,
    profileImagePreview: null
  }

  componentDidMount = async () => {

    const userInfo = await getUserInfo();

    const empresa = userInfo.empresa;

     this.setState({ empresa });
  }

  irACajaDeAhorroYuhu = async (e) => {
    e.preventDefault();
    cargando();
    try {
      const resp = await obtenerUrlCajaDeAhorroYuhu();
      console.log(resp);
      cerrarAlertas();
      const win = window.open(`${resp.data.url}?token=${resp.data.access_token}`, '_blank');
      if(win){
        win.focus();
      }
    } catch (error) {
      cerrarAlertas();
      console.log(error);
      if( error.status === 403 ){
        mostrarToast(error.message, 'warning');
      }

      if(error.status === 500){
        mostrarToast(error.message, 'error');
      }

    }
  }

  handleImageChange = async (e) => {
    e.preventDefault();
    const { target: { files } } = e
    const file = files[0]
    if (file){
      cargando();
      const formData = new FormData();
      formData.append('imagen_perfil', file);
      try {
        await cambiarImagenPerfil(formData);
        const resp = await obtenerInfoUsuario();
        await setUserInfo(resp.data);
        cerrarAlertas();
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            profileImagePreview: reader.result
          })
        };
        reader.readAsDataURL(file);
        mostrarToast('Imagen de perfil actualizada.');
      } catch (error) {
        cerrarAlertas();
        mostrarToast('No fue posible actualizar la imagen de perfil.', 'error');
      }
    }
  }

  changeMenuHover = (menu) => {
    this.setState({menuHover: menu});
  }

  render() {
    const { collapsed, logout, userInfo, menuSelected = 'dashboard' } = this.props;
    const { menuPerfilDisplay, profileImagePreview, menuHover, empresa } = this.state;

    let subdominio = userInfo.dils_subdominio ? `https://dils.yuhu.mx/${userInfo.dils_subdominio}?utm_source=web&utm_canal=portal_autoattend` : '';

    return (
      <div id="sidebar-menu" className={collapsed ? 'is-collapsed' : ''}>
        <div className="sidebar">
          <div className="sidebar-inner">
            <div className="sidebar-logo">
              <div className="userInfo">
                <img src={logo} width="36" height="36" alt="Profile" />
                <div className="profileImageDiv">
                  <img
                    src={profileImagePreview || userInfo.imagen_perfil || noProfilePicture}
                    width="64"
                    height="64"
                    alt="Profile"
                    className="profile-picture"
                  />
                  <div className="overlay">
                    <label htmlFor="fileProfileImage">Cambiar imagen</label>
                    <input
                      type="file"
                      id="fileProfileImage"
                      name="profileImage"
                      style={{display: 'none'}}
                      onChange={(e) => this.handleImageChange(e)}
                    />
                  </div>
                </div>
                <strong className="userName">{`${userInfo.nombre} ${userInfo.apellido_paterno}`}</strong>
                <span className="companyName">{userInfo.empresa || ''}</span>
              </div>
            </div>
            <ul className="sidebar-menu scrollable pos-r">
              <li className="mT-30">
                <MenuItem
                  href="/"
                  name="Dashboard"
                  menuSlug="dashboard"
                  classMenu="sidebar-link"
                  icon={iconHome}
                  iconActive={iconHomeActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                />
              </li>
              <li>
                <MenuItem
                  // href="/lista-creditos"
                  href={ subdominio }
                  name="Solicitar Crédito"
                  menuSlug="solicitud-credito"
                  classMenu="sidebar-link"
                  icon={iconSolicitud}
                  iconActive={iconSolicitudActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                />
              </li>
              <li>
                <MenuItem
                  href="/estado-de-cuenta"
                  name="Estado de cuenta"
                  menuSlug="estado-cuenta"
                  classMenu="sidebar-link"
                  icon={iconEdoCuenta}
                  iconActive={iconEdoCuentaActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                />
              </li>
              <li>
                <MenuItem
                  href="/"
                  name="Caja de ahorro/Inversión"
                  menuSlug="caja-ahorro"
                  classMenu="sidebar-link"
                  icon={iconAhorro}
                  iconActive={iconAhorroActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                  onClick={this.irACajaDeAhorroYuhu}
                />
              </li>
              <li className="d-none">
                <MenuItem
                  href="/servicios"
                  name="Pago de servicios"
                  menuSlug="pago-servicios"
                  classMenu="sidebar-link"
                  icon={iconServicios}
                  iconActive={iconServiciosActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                />
              </li>
              <li className="d-none">
                <MenuItem
                  href="/recargas"
                  name="Recargas"
                  menuSlug="recargas"
                  classMenu="sidebar-link"
                  icon={iconPrepago}
                  iconActive={iconPrepagoActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                />
              </li>
              <li>
                <MenuItem
                  href="/perfil/ver"
                  name="Perfil"
                  menuSlug="perfil"
                  classMenu="sidebar-link"
                  icon={iconPerfil}
                  iconActive={iconPerfilActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                />
              </li>
              <li>
                <MenuItem
                  href="/cambiar-contrasena"
                  name="Cambiar contraseña"
                  menuSlug="cambiar-contraseña"
                  classMenu="sidebar-link"
                  icon={iconContrasena}
                  iconActive={iconContrasenaActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                />
                {/* <a href="admin" className={`sidebar-link${menuSelected === 'cambiar-contrasena' ? ' active' : ''}`}>
                  <span className="icon-holder"><img src={iconContrasena} alt="Contraseña" /></span>
                  <span className="title-sb">Cambiar contraseña</span>
                </a> */}
              </li>
              <li>
                <MenuItem
                  href="#"
                  name="Cerrar sesión"
                  menuSlug="cerrar-sesion"
                  classMenu="sidebar-link"
                  icon={iconSesion}
                  iconActive={iconSesionActive}
                  menuSelected={menuSelected}
                  menuHover={menuHover}
                  changeMenuOver={this.changeMenuHover}
                  onClick={logout}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Sidebar
