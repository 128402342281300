import React from 'react';

const InfoRow = ({label, value, fsz = '0.8rem'}) => (
  <div className="info-row">
    <span className="label" style={{fontSize: fsz}}>{label}</span>
    <span className="value" style={{fontSize: fsz}}>{value}</span>
  </div>
)

export default InfoRow
