import React from 'react';
import { currencyFormat } from '../../utils/filters';

const Cotizacion = ({
  cotizacion,
  fontSize = '0.7rem',
  esModal = true,
  colorTotal = '#212529',
  colorSaldo = '#212529',
}) => (
  <div className="table-responsive table-wrapper-scroll-y fz">
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th className="text-right">Capital</th>
          <th className="text-right">Interés</th>
          <th className="text-right">IVA</th>
          <th className="text-right">Total a pagar</th>
          <th className="text-right">Saldo final</th>
        </tr>
      </thead>
      <tbody>
        {cotizacion.pagos.map(p => (
          <tr key={`pago${p.num_pago}`}>
            <td>{p.num_pago}</td>
            <td className="text-right">
              {currencyFormat(p.capital)}
            </td>
            <td className="text-right">
              {currencyFormat(p.interes_sin_iva)}
            </td>
            <td className="text-right">
              {currencyFormat(p.iva)}
            </td>
            <td className="text-right" style={{ color: colorTotal }}>
              {currencyFormat(p.total_pagar)}
            </td>
            <td className="text-right" style={{ color: colorSaldo }}>
              {currencyFormat(p.saldo_final)}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th />
          <th className="text-right">
            {currencyFormat(cotizacion.totales.capital)}
          </th>
          <th className="text-right">
            {currencyFormat(cotizacion.totales.interes)}
          </th>
          <th className="text-right">
            {currencyFormat(cotizacion.totales.iva)}
          </th>
          <th className="text-right">
            {currencyFormat(cotizacion.totales.total)}
          </th>
          <th />
        </tr>
      </tfoot>
    </table>
    <style jsx="true">{`
      .fz {
        font-size: ${fontSize};
      }
      .table-wrapper-scroll-y {
        display: block;
        position: relative;
        ${esModal ? `height: ${window.innerHeight - ((window.innerHeight / 12) * 4)}px` : ''};
        overflow: auto;
      }
    `}</style>
  </div>
)

export default Cotizacion
