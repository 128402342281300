import React, { Component } from 'react'
import Meta from "../dashboard/Meta";
import {obtenerInfoDashboard} from '../../api/Dashboard';
import {currencyFormat} from '../../utils/filters';
import imgMeta from '../../images/iconos/meta.svg';
import iconHome from '../../images/iconos/dashbor_white.svg'

export default class extends Component{
  state = {
    dropdownUser: false,
    collapsed: this.props.collapsed,
    size: this.props.size,
    dashboardInfo: {
      monto_total: '0',
      monto_pagar: '0',
      monto_total_pagado: '0'
    }
  }

  componentDidMount = async () => {
    try {
      const resp = await obtenerInfoDashboard();
      this.setState({ dashboardInfo: resp.data });
    } catch (error) {
      console.log('Error:', error);
    }
  }
  

  async componentWillReceiveProps(nextProps) {
    this.setState({
      collapsed: nextProps.collapsed,
      size: nextProps.size
    });
  }

  openNav = () => {
    document.getElementById("sidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  showDropdownUser = (e) => {
    e.preventDefault()
    const {dropdownUser} = this.state;
    this.setState({ dropdownUser: !dropdownUser })
  }

  logout = (e) => {
    // e.preventDefault();
    localStorage.clear();
  }

  render(){
    const {collapse, logout, userInfo} = this.props;
    const {collapsed, dropdownUser, dashboardInfo, size} = this.state;
    const verMeta = parseFloat(dashboardInfo.monto_total) > 0;
    return (
      <div className={collapsed ? 'is-collapsed' : ''}>
        <div className="header navbar">
          <div className="header-container">
            {/* <ul className="nav-left">
              <li>
                <a id="sidebar-toggle" className="sidebar-toggle" href="" onClick={collapse}>
                  <i className="fa fa-bars"></i>
                </a>
              </li>
            </ul> */}
            <ul className="nav-right">
              {/* <li className="dropdown">
                <a href="" className="no-after peers fxw-nw ai-c lh-1" onClick={this.showDropdownUser}>
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                </a>
                <ul className={`dropdown-menu fsz-sm ${dropdownUser && "show"}`}>
                  <li>
                    <a href="/" className="d-b td-n pY-5 bgcH-grey-100 c-grey-700" onClick={this.logout}>
                      <i className="fas fa-power-off mR-10"></i> <span>Lista de notificaciones</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="" className="no-after peers fxw-nw ai-c lh-1">
                  <i className="fa fa-cog" aria-hidden="true"></i>
                </a>
              </li> */}
              <li>
                <a href="" className="no-after peers fxw-nw ai-c lh-1" onClick={logout}>
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
          <hr className="no-margin white-hr" />
          <div className="mh2 mt1-5">
            <div className="row flex-center">
              <div className="col-md-6 col-sm-9 col-10 contenido">
                <div className="navbar-content-item">
                  <div className="w2-5">
                    <img src={iconHome} alt="Dashboard" />
                  </div>
                  {/* <i className="fa fa-home navbar-title w2-5" aria-hidden="true"></i> */}
                  <strong className="navbar-title">Dashboard</strong>
                </div>
                <div className="navbar-content-item">
                  <div className="w2-5" />
                  <span className="navbar-title">Hola <strong>{`${userInfo.nombre} ${userInfo.apellido_paterno}`}</strong>, bienvenido</span>
                </div>
              </div>
              <div className="col-md-6 col-sm-3 col-2 flex-end-center">
                { size >= 3 && verMeta && (
                  <div className="text-white flex-column lh1 text-center">
                    <span >
                      <b className="fsz-1-1">
                        {currencyFormat(dashboardInfo.monto_pagar)}
                      </b>
                      <br /><small>Monto por pagar</small>
                    </span>
                    <span className="mt1">
                      <b className="fsz-1-1">
                        {currencyFormat(dashboardInfo.monto_total)}
                      </b>
                      <br /><small>Monto total</small>
                    </span>
                  </div>
                )}
                { size >= 3 && verMeta && (
                  <Meta
                    total={dashboardInfo.monto_total}
                    pagado={dashboardInfo.monto_total_pagado}
                    width="40%"
                    height="0.7rem"
                  />
                )}
                { size >= 3 && verMeta && (
                  <img src={imgMeta} alt="Meta" />
                )}
                <button
                  type="button"
                  className="btn btn-link text-white d-block d-lg-none"
                  onClick={collapse}
                >
                  <i className="fa fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
