import React, { Component } from 'react';
import moment from 'moment';
import 'react-rangeslider/lib/index.css';
import {
  obtenerInfoCrediticia,
  actualizarInfoCrediticia
} from '../../api/Solicitudes';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import imgCredito from '../../images/iconos/credito.svg';

class InformacionCrediticia extends Component {
  state = {
    creditoHipotecario: null,
    creditoAutomotriz: null,
    tarjetaCredito: null,
    errores: {}
  }

  componentDidMount = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      const resp = await obtenerInfoCrediticia(id);
      this.setState({
        creditoHipotecario: resp.data.credito_hipotecario,
        creditoAutomotriz: resp.data.credito_automotriz,
        tarjetaCredito: resp.data.tarjeta_credito,
      });
    } catch (error) {
      console.log('Error:', error)
    }
  }

  validar = () => {
    let validado = true;
    const errores = {};
    const {
      creditoHipotecario,
      creditoAutomotriz,
      tarjetaCredito
    } = this.state;

    if (!creditoHipotecario) {
      validado = false;
      errores.credito_hipotecario = ['Esta respuesta es requerida.'];
    }
    if (!creditoAutomotriz) {
      validado = false;
      errores.credito_automotriz = ['Esta respuesta es requerida.'];
    }
    if (!tarjetaCredito) {
      validado = false;
      errores.tarjeta_credito = ['Esta respuesta es requerida.'];
    }

    this.setState({ errores });
    return validado;
  }

  submit = async () => {
    if (!this.validar()) { return; }
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const {
      creditoHipotecario,
      creditoAutomotriz,
      tarjetaCredito
    } = this.state;
    const datos = {
      credito_hipotecario: creditoHipotecario,
      credito_automotriz: creditoAutomotriz,
      tarjeta_credito: tarjetaCredito
    };
    try {
      await actualizarInfoCrediticia(id, datos);
      this.props.history.push(`/solicitud-credito/${id}/comprobantes`);
    } catch (error) {
      console.log('error:', error);
    }
  }

  render() {
    const {
      creditoHipotecario,
      creditoAutomotriz,
      tarjetaCredito,
      errores
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const rowPregunta = (
      pregunta,
      nombre,
      checkSi,
      checkNo,
      onChangeSi,
      onChangeNo,
      error = [],
      respuestaSi = 'Si',
      respuestaNo = 'No tengo, y nunca he tenido',
      nota = null
    ) => (
      <div className="row mt2">
        <div className="col-sm-6">
          <span>{pregunta}</span>
          {nota && <small className="font-italic"><br />{nota}</small>}
          {error.map((obj, index) => (
            <div key={`${nombre}-${index}`} className="invalid-feedback">
              {obj}
            </div>
          ))}
        </div>
        <div className="col-sm-6 flex-start">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id={`${nombre}1`}
              className="custom-control-input"
              value="si"
              checked={checkSi}
              onChange={onChangeSi}
            />
            <label className="custom-control-label" htmlFor={`${nombre}1`}>{respuestaSi}</label>
          </div>
          <div className="custom-control custom-radio ml2">
            <input
              type="radio"
              id={`${nombre}2`}
              className="custom-control-input"
              value="no"
              checked={checkNo}
              onChange={onChangeNo}
            />
            <label className="custom-control-label" htmlFor={`${nombre}2`}>{respuestaNo}</label>
          </div>
        </div>
      </div>
    )
    return (
      <Autenticado menuSelected="solicitud-credito">
        <TituloBotonDerecha
          icono={imgCredito}
          titulo="Datos de otros créditos"
          descripcion1="Consulta de buro"
          descripcion2=""
        />
        {rowPregunta(
          '¿TIENES O HAS TENIDO CRÉDITO HIPOTECARIO?',
          "creditoHipotecario",
          creditoHipotecario === 'si',
          creditoHipotecario === 'no',
          () => this.setState({ creditoHipotecario: 'si' }),
          () => this.setState({ creditoHipotecario: 'no' }),
          errores.credito_hipotecario
        )}
        {rowPregunta(
          '¿TIENES O HAS TENIDO CRÉDITO AUTOMOTRIZ?',
          "creditoAutomotriz",
          creditoAutomotriz === 'si',
          creditoAutomotriz === 'no',
          () => this.setState({ creditoAutomotriz: 'si' }),
          () => this.setState({ creditoAutomotriz: 'no' }),
          errores.credito_automotriz
        )}
        {rowPregunta(
          '¿TIENES TARJETA DE CRÉDITO BANCARIA?',
          "tarjetaCredito",
          tarjetaCredito === 'si',
          tarjetaCredito === 'no',
          () => this.setState({ tarjetaCredito: 'si' }),
          () => this.setState({ tarjetaCredito: 'no' }),
          errores.tarjeta_credito,
          'Si',
          'No',
          'No aplica departamental liverpool y similares'
        )}
        <div className="row mt2">
          <div className="col-12">
            <small>
              Autorizo a proquatro SAPI DE CV SOFOM ENR* para que consulte mi historial crediticio de acuerdo con los artículos 13 y 28 de la Ley para Regular las Sociedades de Información Crediticia. Igualmente, declaro que conozco la naturaleza y el alcance de la información que se solicitará.
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-12 flex-between">
            <a
              className="btn btn-secondary mt2"
              href={`/solicitud-credito/`}
            >
              Regresar
            </a>
            <button
              className="btn btn-success mt2"
              type="button"
              onClick={this.submit}
            >
              Siguiente
            </button>
          </div>
        </div>
        <style jsx="true">{`
          .custom-control-input:checked~.custom-control-label::before {
            color: #fff;
            border-color: #25d07b;
            background-color: #25d07b;
          }
          .invalid-feedback {
            display: block;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default InformacionCrediticia