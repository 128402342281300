import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {
  obtenerDomicilio,
  actualizarDomicilio
} from '../../api/Empleados';
import {
  obtenerEstados,
  obtenerMunicipios
} from '../../api/Utils';
import {validateNumber} from '../../utils/regex';

class Domicilio extends Component {
  state = {
    domicilio: {
      calle: '',
      numero_exterior: '',
      numero_interior: '',
      colonia: '',
      codigo_postal: '',
      municipio: '',
      estado: '',
    },
    estados: [{label: '--Selecciona--', value: ''}],
    municipios: [{label: '--Selecciona--', value: ''}],
    errores: {}
  }

  componentDidMount = async () => {
    try {
      const respDomicilio = await obtenerDomicilio();
      const {municipios} = this.state;
      if (respDomicilio.data.estado) {
        const respMunicipios = await obtenerMunicipios(respDomicilio.data.estado);
        respMunicipios.data.forEach(e => {
          municipios.push({label: e.nombre, value: e.id});
        });
      }
      const domicilio = respDomicilio.data;
      if (domicilio.estado){
        domicilio.estado = `${domicilio.estado}`;
      }
      if (domicilio.municipio){
        domicilio.municipio = `${domicilio.municipio}`;
      }
      this.setState({
        domicilio,
        municipios
      });
    } catch (error) {
      console.log('Error: ', error);
    }
    try {
      const respEstados = await obtenerEstados();
      const {estados} = this.state;
      respEstados.data.forEach(e => {
        estados.push({label: e.nombre, value: e.id});
      });
      this.setState({
        estados
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  handleChange = async (e) => {
    const { domicilio } = this.state
    const {target: {name, value}} = e;
    domicilio[name] = value
    if (name === 'estado') {
      const respMunicipios = await obtenerMunicipios(value);
      const municipios = [{label: '--Selecciona--', value: ''}];
      respMunicipios.data.forEach(m => {
        municipios.push({label: m.nombre, value: m.id});
      });
      domicilio.municipio = '';
      this.setState({ domicilio, municipios })
    } else if (name === 'codigo_postal') {
      if (validateNumber(value) || value.length === 0) {
        this.setState({ domicilio });
      }
    } else {
      this.setState({ domicilio })
    }
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      domicilio
    } = this.state;

    if (domicilio.calle.trim().length === 0) {
      validado = false;
      errores.calle = ['Este campo es requerido.'];
    }
    if (domicilio.numero_exterior.trim().length === 0) {
      validado = false;
      errores.numero_exterior = ['Este campo es requerido.'];
    }
    if (domicilio.colonia.trim().length === 0) {
      validado = false;
      errores.colonia = ['Este campo es requerido.'];
    }
    if (domicilio.codigo_postal.trim().length === 0) {
      validado = false;
      errores.codigo_postal = ['Este campo es requerido.'];
    }
    if (domicilio.municipio.trim().length === 0) {
      validado = false;
      errores.municipio = ['Este campo es requerido.'];
    }
    if (domicilio.estado.trim().length === 0) {
      validado = false;
      errores.estado = ['Este campo es requerido.'];
    }

    this.setState({ errores });
    return validado;
  }

  guardar = async () => {
    if (!this.validar()) { return; }
    try {
      const { domicilio } = this.state;
      await actualizarDomicilio(domicilio);
      this.props.history.push('/perfil/ver')
    } catch (error) {
      console.log('Error:', error);
      if (error.status === 400) {
        this.setState({ errores: error.data });
      }
    }
  }

  render() {
    const { domicilio, errores, estados, municipios } = this.state;

    const tipoViviendaChoices = [
      {label: '--Selecciona--', value:''},
      {label: 'Casa propia', value:'propia'},
      {label: 'Vivo con mis padres', value:'padres'},
      {label: 'Rentada', value:'rentada'},
      {label: 'Prestada', value:'prestada'},
      {label: 'Vivo con familiares', value:'familiares'},
      {label: 'Otra', value:'otra'}
    ];

    return (
      <Autenticado menuSelected="perfil">
        <div className="row mb1 row-mt-15">
          <div className="col-md-12">
            <div className="titulo">
              <h3 className="titulo-seccion">Domicilio del empleado</h3>
            </div>
          </div>
        </div>
        <div className="row mb1">
          <div className="col-sm-3 form-group">
            <Input
              label="Calle"
              name="calle"
              placeholder="Calle_"
              value={domicilio.calle}
              maxLength={85}
              error={errores.calle}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-3 form-group">
            <Input
              label="Número exterior"
              name="numero_exterior"
              placeholder="Numero exterior_"
              value={domicilio.numero_exterior}
              maxLength={10}
              error={errores.numero_exterior}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-3 form-group">
            <Input
              label="Número interior"
              name="numero_interior"
              placeholder="Numero interior_"
              value={domicilio.numero_interior}
              maxLength={5}
              error={errores.numero_interior}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-3 form-group">
            <Input
              label="Colonia"
              name="colonia"
              placeholder="Colonia_"
              value={domicilio.colonia}
              maxLength={25}
              error={errores.colonia}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mb1">
          <div className="col-sm-3 form-group">
            <Input
              label="Código postal"
              name="codigo_postal"
              placeholder="Codigo postal_"
              value={domicilio.codigo_postal}
              maxLength={5}
              error={errores.codigo_postal}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-3 form-group">
            <Select
              label="Estado"
              name="estado"
              value={domicilio.estado}
              options={estados}
              error={errores.estado}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-3 form-group">
            <Select
              label="Municipio"
              name="municipio"
              value={domicilio.municipio}
              options={municipios}
              error={errores.municipio}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-3 form-group">
            <Select
              label="Tipo de vivienda"
              name="tipo_vivienda"
              value={domicilio.tipo_vivienda}
              options={tipoViviendaChoices}
              error={errores.tipo_vivienda}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className="row mb1">
          <div className="col-sm-3 form-group">
            <Input
              label="Antigüedad"
              name="antiguedad"
              placeholder="Ej. 1 año"
              value={domicilio.antiguedad || ''}
              error={errores.antiguedad}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-success" onClick={this.guardar}>Guardar</button>
            <a href="/perfil/ver" className="btn btn-secondary ml1">Cancelar</a>
          </div>
        </div>
      </Autenticado>
    )
  }
}

export default Domicilio
