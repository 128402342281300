import React, { Component } from 'react';
// Librerias
import { Link } from 'react-router-dom';
import moment from 'moment';
import { GA_CODE, MOMENT_LOCALE } from '../utils/config';
// Componentes
import Sidebar from './layout/Sidebar'
import Header from './layout/Header'
// CSS
import '../fonts/font-awesome/css/font-awesome.css';
import '../css/bootstrap.min.css'
import '../css/style.css'
// Funciones
import { removeToken } from '../utils/auth';
import {getUserInfo} from '../utils/auth';
// Imagenes
import profilePicture from '../images/profile.png'
import ReactGA from 'react-ga';

class Autenticado extends Component {
  state = {
    collapsed: false,
    width: 0,
    height: 0,
    menuSelected: 'dashboard',
    size: 4,
    userInfo: {
      email: '',
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      subdominio: null,
    }
  }

  componentDidMount = async () => {
    moment.updateLocale('es', MOMENT_LOCALE)
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const userInfo = await getUserInfo();
    ReactGA.initialize(GA_CODE, {
      gaOptions: {
        userId: userInfo.uuid
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ userInfo });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    let collapsed = true;
    let size = 4;
    if (width < 992) {
      collapsed = false;
    }
    if (width >= 992) { size = 4 }
    else if (width >= 768) { size = 3 }
    else if (width >= 576) { size = 2 }
    else { size = 1 }
    this.setState({ width, height, collapsed, size });
  }

  collapse = (e) => {
    e.preventDefault()
    this.setState({ collapsed: !this.state.collapsed })
  }

  logout = () => {
    removeToken();
  };

  render() {
    const { children, menuSelected } = this.props;
    const { collapsed, userInfo, size } = this.state;
    return (
      <div>
        <Sidebar
          collapse={() => console.log('Colapsar')}
          collapsed={collapsed}
          menuSelected={menuSelected}
          logout={this.logout}
          userInfo={userInfo}
        />
        <div id="main" className="page-container">
          <Header
            collapse={this.collapse}
            collapsed={collapsed}
            logout={this.logout}
            userInfo={userInfo}
            size={size}
          />
          <main className="main-content bgc-grey-100">
            { children }
          </main>
        </div>
      </div>
    );
  }
}

export default Autenticado;
