const USER_AUTH_KEY = 'auth-token'
const USER_INFO_KEY = 'user-info'

const setToken = (token) => {
  localStorage.setItem(USER_AUTH_KEY, token);
}

const getToken = () => {
  return localStorage.getItem(USER_AUTH_KEY)
}

const removeToken = () => {
  localStorage.removeItem(USER_AUTH_KEY);
}

const setUserInfo = (userInfo) => {
  localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
}

const getUserInfo = () => {
  return JSON.parse(localStorage.getItem(USER_INFO_KEY));
}

const removeUserInfo = () => {
  localStorage.removeItem(USER_INFO_KEY);
}

const isLogin = () => {
  return !!getToken();
}

export {
  setToken,
  getToken,
  removeToken,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
  isLogin
}
