import React, { Component } from 'react';
import moment from 'moment';
import Autenticado from '../../components/Autenticado';
import imgPago from '../../images/iconos/pago_servicio_color.svg';
import imgExito from '../../images/iconos/exito.svg';
import imgError from '../../images/error-flat.png';
import imgEnviar from '../../images/iconos/enviar.svg';
import {
  detalleDePago,
  enviarEmailPago
} from '../../api/Servicios';
import {currencyFormat} from '../../utils/filters';
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts';

class Recibo extends Component {
  state = {
    pago: {
      estatus: 'exito',
      proveedor: {},
      producto: {},
      transaccion: {
        carrier_control_no: '',
        customer_service_no: '',
        date_time: new Date(),
        response_message: ''
      }
    }
  };

  componentDidMount = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      const resp = await detalleDePago(id);
      const pago = resp.data
      if (!pago.transaccion) {
        pago.transaccion = {
          carrier_control_no: '',
          customer_service_no: '',
          date_time: new Date(),
          response_message: ''
        }
      }
      console.log('Pago:', pago)
      this.setState({ pago });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  enviar = async () => {
    const { pago } = this.state;
    try {
      cargando();
      await enviarEmailPago(pago.id)
      cerrarAlertas();
      mostrarToast('Comprobante enviado exitosamente.');
    } catch (error) {
      cerrarAlertas();
      mostrarToast(error.message, 'error');
    }
  }

  render() {
    const {pago} = this.state;
    return (
      <Autenticado menuSelected="pago-servicios">
        <div className="row mt1">
          <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 flex-column border-bottom">
            <img src={imgPago} alt="Pago" width="50" />
            <h3 className="titulo-recibo">Pagos de servicio</h3>
          </div>
        </div>
        <div className="row mt1">
          <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 flex-around border-bottom pb1">
            <img src={pago.proveedor.imagen} alt="{pago.proveedor.nombre}" className="logo-proveedor" />
            <h5 className="proveedor">{pago.proveedor.nombre}</h5>
          </div>
        </div>
        <div className="row mt1">
          <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 flex-column border-bottom">
            {
              pago.estatus === 'exito'
                ? <img src={imgExito} alt="Pago" width="50" />
                : <img src={imgError} alt="Pago" width="50" />
            }
            {
              pago.estatus === 'exito'
                ? <h5 className="pagomsj"><strong>Tu pago fue realizado con éxito</strong></h5>
                : <h5 className="pagomsj" style={{ color: 'red' }}><strong>Ocurrio un error al realizar el pago</strong></h5>
            }
            <small className="pagomsj">Fecha y Hora: <strong>{moment(pago.transaccion.date_time).format('DD/MM/YYYY HH:mm:ss')}</strong></small>
            <small className="pagomsj">Folio de la transacción: <strong>{pago.folio}</strong></small>
            <small className="pagomsj">Autorización: <strong>{pago.transaccion.carrier_control_no ? pago.transaccion.carrier_control_no : ''}</strong></small>
            <small className="pagomsj">Servicio cliente num.: <strong>{pago.transaccion.customer_service_no ? pago.transaccion.customer_service_no : ''}</strong></small>
            <small className="pagomsj"><strong>Monto: <span className="montos">{currencyFormat(pago.monto)}</span></strong></small>
            <small className="pagomsj"><strong>Comisión: <span className="montos">{currencyFormat(pago.comision)}</span></strong></small>
            <small className="pagomsj"><strong>Total a pagar</strong></small>
            <h3 className="titulo-recibo">{currencyFormat(pago.total)}</h3>
            <small className="pagomsj">Información importante:</small>
            <small className="pagomsj"><strong>{pago.transaccion.response_message}</strong></small>
          </div>
        </div>
        <div className="row mt1">
          <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2 flex-column">
            <button
              type="button"
              className="btn btn-link flex-row"
              onClick={this.enviar}
            >
              <img src={imgEnviar} alt="Ver" />
              <small>Enviar comprobante a tu email</small>
            </button>
          </div>
        </div>
        <style jsx="true">{`
          .titulo-recibo {
            text-align: center;
            font-weight: bold;
            color: #25d07b;
            margin-top: 0.5rem;
          }
          .montos {
            color: #25d07b;
          }
          .proveedor {
            text-align: left;
            font-weight: bold;
            color: #333;
            max-width: 60%;
          }
          .logo-proveedor {
            max-width: 30%;
          }
          .pagomsj {
            text-align: center;
            color: #333;
            margin-top: 0.5rem;
          }
          .flex-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
            padding-left: 0px !important;
            color: #333 !important;
          }
          .flex-row img {
            width: 20px;
            margin-right: 0.5rem;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default Recibo
