import React from 'react';

const DonutChart = ({
  titulo = '',
  descripcion = '',
  tituloPagado = '',
  descripcionPagado = '',
  pagado = 0,
  total = 100,
}) => {
  const porcentajePagado = parseInt((parseFloat(pagado) * 100) / parseFloat(total));
  const porcentajePendiente = 100 - porcentajePagado;
  return (
    <figure>
      <div className="figure-content">
        <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut" aria-labelledby="beers-title beers-desc" role="img">
          <title id="beers-title">{titulo}</title>
          <desc id="beers-desc">{descripcion}</desc>
          <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff" role="presentation"></circle>
          <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4" strokeWidth="3" role="presentation"></circle>

          <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#25d07b" strokeWidth="3" strokeDasharray={`${porcentajePagado} ${porcentajePendiente}`} strokeDashoffset="25" aria-labelledby="donut-segment-1-title donut-segment-1-desc">
            <title id="donut-segment-1-title">{tituloPagado}</title>
            <desc id="donut-segment-1-desc">{descripcionPagado}</desc>
          </circle>
          {/* <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#b1c94e" strokeWidth="3" strokeDasharray="20 80" strokeDashoffset="85">
            <title id="donut-segment-2-title">Imperial India Pale Ales</title>
            <desc id="donut-segment-2-desc">Green chart segment spanning 20% of the whole, which is 2 Imperial India Pale Ales out of 10 total.</desc>
          </circle>
          <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#377bbc" strokeWidth="3" strokeDasharray="30 70" strokeDashoffset="65">
            <title id="donut-segment-3-title">Russian Imperial Stouts</title>
            <desc id="donut-segment-3-desc">Blue chart segment spanning 3% of the whole, which is 3 Russian Imperial Stouts out of 10 total.</desc>
          </circle> */}
          <g className="chart-text">
            <text x="50%" y="50%" className="chart-number">
              pagos
            </text>
            <text x="50%" y="50%" className="chart-label">
              {`${pagado}/${total}`}
            </text>
          </g>
        </svg>
      </div>
      <style jsx="true">{`
        .chart-text {
          fill: #25d07b;
          -moz-transform: translateY(0.25em);
          -ms-transform: translateY(0.25em);
          -webkit-transform: translateY(0.25em);
          transform: translateY(0em);
        }
        .chart-number {
          font-size: 0.3em;
          line-height: 1;
          text-anchor: middle;
          -moz-transform: translateY(-0.25em);
          -ms-transform: translateY(-0.25em);
          -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
        }
        .chart-label {
          font-size: 0.3em;
          text-transform: uppercase;
          text-anchor: middle;
          -moz-transform: translateY(0.7em);
          -ms-transform: translateY(0.7em);
          -webkit-transform: translateY(0.7em);
          transform: translateY(0.7em);
          font-weight: bold;
        }
        figure {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
        }
        @media (min-width: 768px) {
          figure {
            flex-direction: row;
          }
        }
        .figure-content,
        .figure-key {
          flex: 1;
          padding-left: 15px;
          padding-right: 15px;
          align-self: center;
        }
        .figure-content svg {
          height: auto;
        }
        .figure-key {
          min-width: calc(8 / 12);
        }
        .figure-key [className*="shape-"] {
          margin-right: 6px;
        }
        .figure-key-list {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        .figure-key-list li {
          margin: 0 0 8px;
          padding: 0;
        }
        .shape-circle {
          display: inline-block;
          vertical-align: middle;
          width: 32px;
          height: 32px;
          -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
                  border-radius: 50%;
        }
        .shape-fuschia {
          background-color: #ce4b99;
        }
        .shape-lemon-lime {
          background-color: #b1c94e;
        }
        .shape-blue {
          background-color: #377bbc;
        }
        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          overflow: hidden;
          clip: rect(0,0,0,0);
          border: 0;
        }
      `}</style>
    </figure>
  )
}

export default DonutChart
