import React from 'react';

const Input = ({
  label,
  name,
  value,
  type = 'text',
  placeholder = '',
  readonly = false,
  helpText = null,
  error = [],
  maxLength = 256,
  onChange,
  onBlur = null
}) => (
  <div className="form-group">
    {!!label && <label htmlFor={name}>{label}</label>}
    <input
      type={type}
      className={`form-control ${error.length > 0 ? 'is-invalid' : ''}`}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      readOnly={readonly}
      maxLength={maxLength}
      onChange={onChange}
      onBlur={onBlur}
    />
    {helpText && <small className="form-text text-muted">{helpText}</small>}
    {error.map((obj, index) => (
      <div key={`${name}${index}`} className="invalid-feedback">
        {obj}
      </div>
    ))}
  </div>
)

export default Input
