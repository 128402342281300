import React, { Component } from 'react';
import moment from 'moment';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import {obtenerMisSolicitudes} from '../../api/Solicitudes';
import {currencyFormat} from '../../utils/filters';
import imgCredito from '../../images/iconos/credito.svg';
import imgEye from '../../images/iconos/eye.svg';
import { getLinkSolicitud } from '../../utils/functions';

class Lista extends Component {
  state = {
    solicitudes: [],
    showCotizarButton: true,
    solicitudIncompleta: null,
  };

  componentDidMount = async () => {
    try {
      const resp = await obtenerMisSolicitudes();
      const solicitudes = resp.data;
      let showCotizarButton = true;
      let solicitudIncompleta = null;
      solicitudes.forEach(s => {
        if (s.estatus === 'incompleta'){
          showCotizarButton = false;
          solicitudIncompleta = s;
        }
      });
      this.setState({ solicitudes, showCotizarButton, solicitudIncompleta });
    } catch (error) {
      console.log('Error:', error);
    }
  }
  

  render() {
    const { solicitudes, showCotizarButton, solicitudIncompleta } = this.state;

    let linkBoton = '/solicitud-credito';

    if (solicitudIncompleta){
      linkBoton = getLinkSolicitud(solicitudIncompleta);
    }

    return (
      <Autenticado menuSelected="solicitud-credito">

        <TituloBotonDerecha
          icono={imgCredito}
          titulo="Solicitar Crédito"
          descripcion1="Cotiza tu crédito de la manera más fácil y sencilla"
          descripcion2="y disfruta todo lo que tienes planeado hacer con él."
          // textoBoton={showCotizarButton ? 'Cotizar' : 'Continuar solicitud'}
          // linkBoton={linkBoton}
        />
        <div className="row mt2">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {
                    solicitudes.map(s => (
                      <tr key={`sol${s.id}`}>
                        <td>
                          <span className="td-label">
                            Producto<br />
                            <span className="td-value">{s.producto}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Total crédito<br />
                            <span className="td-value">{currencyFormat(s.monto)}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Costo<br />
                            <span className="td-value">{`${s.costo}%`}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Plazo<br />
                            <span className="td-value">{s.periodo}</span>
                          </span>
                        </td>
                        {/* <td>
                          <span className="td-label">
                            # Pagos<br />
                            <span className="td-value">13</span>
                          </span>
                        </td> */}
                        <td>
                          <span className="td-label">
                            Fecha creación<br />
                            <span className="td-value">{moment(s.fecha_creacion).format('DD-MM-YYYY')}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Número de crédito<br />
                            { s.folio ?
                              <span className="td-value">{s.folio}</span>
                              :
                              <span className="td-value">{s.id}</span>
                            }
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Estatus<br />
                            <span className="td-value">{s.estatus}</span>
                          </span>
                        </td>
                        <td className="td">
                          <a href="#" className="icon">
                            {/*<i className="fa fa-check-circle-o icon-check" aria-hidden="true"></i>*/}
                          </a>
                          {/*{s.estatus === 'incompleta' && s === solicitudIncompleta &&*/}
                          {/*  <a href={linkBoton} className="btn btn-success btn-sm">Continuar</a>*/}
                          {/*}*/}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            {
              solicitudes.length === 0 && (
                <div className="text-center font-italic">
                  <h5>Aún no tienes solicitudes de crédito.</h5>
                </div>
              )
            }
          </div>
        </div>
        <style jsx="true">{`
          .flex1 {
            flex: 1;
          }
          .td {
            vertical-align: middle !important;
          }
          .td-label {
            color: #333;
            font-size: 0.8rem;
          }
          .td-value {
            color: #25d07b;
            font-size: 0.8rem;
          }
          .icon {
            padding: 0.5rem;
          }
          .icon-check {
            color: #25d07b;
          }
          .icon-eye {
            width: 1rem;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default Lista
