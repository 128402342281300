import React, { Component } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {
  obtenerProductosDisponibles,
  calcularSolicitud,
  crearSolicitud,
  obtenerSolicitudIncompleta
} from '../../api/Solicitudes';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import Card from '../../components/general/Card';
import Input from '../../components/form/Input';
import InputMonto from '../../components/form/InputMonto';
import Select from '../../components/form/Select';
import Cotizacion from '../../components/solicitudes/Cotizacion';
import imgCredito from '../../images/iconos/credito.svg';
import imgEye from '../../images/iconos/eye.svg';
import {
  currencyFormat,
  capitalizeOnlyFirst
} from '../../utils/filters';
import { validateDecimal } from '../../utils/regex';
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts';

const MySwal = withReactContent(Swal)

class Solicitud extends Component {
  state = {
    productosDisponibles: [],
    productosDisponiblesSelect: [],
    productoObj: {},
    producto: '',
    productoAyuda: '',
    monto: '',
    periodicidad: '',
    plazos: [0, 1, 2, 3, 4],
    plazosLabels: {0: 3, 1: 6, 2: 9, 3: 12, 4: 15},
    plazoMin: 0,
    plazoMax: 4,
    plazo: 0,
    plazoValor: 0,
    calculado: false,
    cotizacion: {},
    errores: {}
  };

  componentDidMount = async () => {
    cargando();
    try {
      const resp = await obtenerProductosDisponibles();
      const productosDisponibles = resp.data;
      const productosDisponiblesSelect = [{
        label: '--Selecciona--',
        value: ''
      }];
      let producto = '';
      productosDisponibles.forEach(p => {
        productosDisponiblesSelect.push({
          label: p.nombre,
          value: p.id
        });
      });
      if (productosDisponibles.length > 0) {
        producto = productosDisponibles[0].id;
      }
      this.setState({
        productosDisponibles,
        productosDisponiblesSelect,
        producto
      });
      this.handleChange({
        target: {
          name: 'producto',
          value: producto
        }
      }, resp.data.num_amortizaciones);
    } catch (error) {
      console.log('Error:', error)
    }

    try {
      const resp = await obtenerSolicitudIncompleta();
      this.handleChange({
        target: {
          name: 'producto',
          value: resp.data.producto
        }
      }, resp.data.num_amortizaciones);
      this.setState({
        monto: resp.data.monto,
        cotizacion: resp.data.cotizacion,
        calculado: true
      })
    } catch (error) {
      console.log('Error:', error);
    }
    cerrarAlertas();
  }

  handleChange = (e, numAmortizaciones = 0) => {
    const { target: { name, value }} = e;
    if (name === 'producto') {
      if (value === '') { return }
      const { productosDisponibles } = this.state;
      const productoObj = productosDisponibles.find(pd => pd.id === parseInt(value));
      const productoAyuda = `Monto desde ${currencyFormat(productoObj.monto_minimo)} hasta ${currencyFormat(productoObj.monto_maximo)}`
      const plazos = [];
      const plazosLabels = {};
      let plazoIndex = 0;
      productoObj.plazos.forEach((plazo, index) => {
        plazos.push(index);
        plazosLabels[index] = plazo;
        if (numAmortizaciones > 0 && numAmortizaciones == plazo) {
          plazoIndex = index;
        }
      });
      const plazoValor = plazosLabels[plazoIndex];
      this.setState({
        [name]: value,
        productoObj,
        productoAyuda,
        periodicidad: capitalizeOnlyFirst(productoObj.frecuencia_cobro),
        plazos,
        plazosLabels,
        plazoMax: productoObj.plazos.length - 1,
        plazo: plazoIndex,
        plazoValor,
        calculado: false
      });
    } else if(name === 'monto') {
      if (!validateDecimal(value) && value.length > 0) { return }
      this.setState({ [name]: value, calculado: false });
    } else {
      this.setState({ [name]: value, calculado: false });
    }
  }

  cambiarPlazo = (plazo) => {
    const { plazosLabels } = this.state;
    const plazoValor = plazosLabels[plazo];
    this.setState({ plazo, plazoValor, calculado: false });
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      producto,
      monto,
      productoObj
    } = this.state;

    if (producto.length === 0) {
      validado = false;
      errores.producto = ['Selecciona un producto.'];
    }

    if (monto.length === 0) {
      validado = false;
      errores.monto = ['Ingresa un monto.'];
    } else if (parseFloat(monto) < parseFloat(productoObj.monto_minimo)) {
      validado = false;
      errores.monto = [`El monto minimo es de ${currencyFormat(productoObj.monto_minimo)}`];
    } else if (parseFloat(monto) > parseFloat(productoObj.monto_maximo)) {
      validado = false;
      errores.monto = [`El monto maximo es de ${currencyFormat(productoObj.monto_maximo)}`];
    }

    this.setState({ errores })
    return validado;
  }

  calcular = async () => {
    if(!this.validar()) { return; }
    cargando();
    const {
      productoObj,
      monto,
      plazoValor
    } = this.state;
    try {
      const datos = {
        monto,
        num_amortizaciones: plazoValor,
        frecuencia: productoObj.frecuencia_cobro,
        fecha_inicio: moment().format('YYYY-MM-DD')
      };
      const resp = await calcularSolicitud(productoObj.id, datos);
      this.setState({
        cotizacion: resp.data,
        calculado: true
      });
      console.log(resp);
    } catch (error) {
      console.log('Error:', error);
    }
    cerrarAlertas();
  }

  mostrarCotizacion = () => {
    const { cotizacion } = this.state;
    MySwal.fire({
      title: 'Tabla de amortizaciones',
      html: <Cotizacion cotizacion={cotizacion} />,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-success',
      }
    });
  }

  crear = async () => {
    cargando();
    const {
      productoObj,
      monto,
      plazoValor
    } = this.state;
    const datos = {
      producto: productoObj.id,
      monto,
      num_amortizaciones: plazoValor
    };
    try {
      const resp = await crearSolicitud(datos);
      cerrarAlertas();
      // this.props.history.push(`/solicitud-credito/${resp.data.id}/referencias`);
      this.props.history.push(`/solicitud-credito/${resp.data.id}/otros-creditos`);
    } catch (error) {
      console.log('Error:', error);
      cerrarAlertas();
      if (error.status === 400) {
        this.setState({ errores: error.data });
      } else {
        mostrarToast('Ocurrio un error al crear la solicitud', 'error');
      }
    }
  }

  render() {
    const {
      monto,
      periodicidad,
      plazosLabels,
      plazo,
      plazoValor,
      plazoMin,
      plazoMax,
      productosDisponiblesSelect,
      producto,
      productoAyuda,
      productoObj,
      calculado,
      cotizacion,
      errores
    } = this.state;
    return (
      <Autenticado menuSelected="solicitud-credito">
        <TituloBotonDerecha
          icono={imgCredito}
          titulo="Solicitar Crédito"
          descripcion1="¿Cuánto dinero necesitas para hacer lo que planeaste?"
          descripcion2=""
        />
        <div className="row mt2">
          <div className="col-sm-4">
            <InputMonto
              label="Monto a solicitar"
              name="monto"
              placeholder="_"
              value={monto}
              maxLength={11}
              error={errores.monto}
              onChange={this.handleChange}
            />
            <Input
              label="Periodicidad"
              name="periodicidad"
              placeholder="Periodicidad_"
              value={periodicidad}
              readonly
              onChange={this.handleChange}
            />

            <Slider
              min={plazoMin}
              max={plazoMax}
              step={1}
              tooltip={false}
              value={plazo}
              labels={plazosLabels}
              onChange={this.cambiarPlazo}
            />

            { errores.num_amortizaciones && errores.num_amortizaciones.length > 0 && errores.num_amortizaciones.map((error, index) => {
              return (
                <div key={`num_amortizaciones-err-${index}`} className="invalid-feedback" style={{paddingTop: '10px'}}>
                  {error}
                </div>
              )
            }) }


            <div className="mt3"/>
            {
              productosDisponiblesSelect.length > 1 && (
                <Select
                  label="Producto"
                  name="producto"
                  value={producto}
                  options={productosDisponiblesSelect}
                  helpText={productoAyuda}
                  error={errores.producto}
                  onChange={this.handleChange}
                />
              )
            }
            <button className="btn btn-success mb1" type="button" onClick={this.calcular}>
              Calcular
            </button>
          </div>
          {
            calculado && (
              <div className="col-sm-3 offset-sm-1">
                <Card mostrarTitulo={false} padding="0.5rem" height="auto">
                  <div className="flex-row border-bottom">
                    <img src={imgCredito} alt="pago" />
                    <b>Solicitud de crédito</b>
                  </div>
                  <div className="informacion">
                    <div className="info-row">
                      <span>Monto solicitado</span>
                      <b>{currencyFormat(monto)}</b>
                    </div>
                    <div className="info-row">
                      <span>Plazo a pagar</span>
                      <b>{plazoValor}</b>
                    </div>
                    <div className="info-row">
                      { calculado && parseFloat(cotizacion.costo) <= parseFloat(cotizacion.costo_anual) ? <span>Costo del crédito</span> : <span>Costo anual</span> }
                      <b>{
                        calculado && parseFloat(cotizacion.costo) <= parseFloat(cotizacion.costo_anual) ? `${cotizacion.costo} %` : `${cotizacion.costo_anual} %`
                      }</b>
                    </div>
                    <div className="info-row">
                      <span>Periodicidad</span>
                      <b>{periodicidad}</b>
                    </div>
                  </div>
                  <div className="flex-row cat-informativo">
                    { productoObj.leyenda_cat &&  <p className="leyenda-cat"><small>{ productoObj.leyenda_cat }</small></p> }
                  </div>
                </Card>
              </div>
            )
          }
          {
            calculado && (
              <div className="col-sm-3 offset-sm-1">
                <div className="informacion">
                  <div className="info-row">
                    <span>{`Pago ${periodicidad}`}</span>
                    <span className="pago">{currencyFormat(cotizacion.pagos[0].total_pagar)}</span>
                  </div>
                  <div className="info-row">
                    <span className="etiqueta">Total a pagar</span>
                    <span className="total">{currencyFormat(cotizacion.totales.total)}</span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link flex-row2"
                    onClick={this.mostrarCotizacion}
                  >
                    <img src={imgEye} alt="Ver" />
                    <small>Desgloce tabla amortización</small>
                  </button>
                  <button
                    className="btn btn-success mt2"
                    type="button"
                    onClick={this.crear}
                  >
                    Solicitar
                  </button>
                </div>
              </div>
            )
          }
        </div>
        <style jsx="true">{`
          .flex-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
          }
          .flex-row img {
            width: 35px;
          }
          .flex-row b {
            font-size: 1rem;
            margin-left: 1rem;
          }
          .flex-row2 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
            padding-left: 0px !important;
            color: #333 !important;
          }
          .flex-row2 img {
            width: 20px;
            margin-right: 0.5rem;
          }
          .informacion {
            padding: 1rem;
          }
          .info-row{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 1rem;
          }
          .informacion .info-row span{
            color: #333;
          }
          .informacion .info-row b{
            color: #25d07b;
          }
          .info-row .etiqueta{
            font-size: 0.8rem;
            font-weight: bold;
          }
          .info-row .pago{
            color: #666 !important;
            font-size: 1.7rem;
            font-weight: bold;
          }
          .info-row .total{
            color: #25d07b !important;
            font-size: 2rem;
            font-weight: bold;
          }
          .leyenda-cat {
            font-size: 0.8rem;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default Solicitud
