import React from 'react';
import moment from 'moment';
import { currencyFormat } from '../../utils/filters';

const Cotizacion = ({
  cotizacion,
  fontSize = '0.7rem',
  esModal = true,
  colorTotal = '#212529',
  colorSaldo = '#212529',
}) => (
  <div className="table-responsive table-wrapper-scroll-y fz">
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th>Fecha de vencimiento</th>
          <th className="text-right">Capital</th>
          <th className="text-right">Interés</th>
          <th className="text-right">IVA</th>
          <th className="text-right">Abonado</th>
          <th className="text-center">Pagada</th>
          <th>Fecha de pago</th>
        </tr>
      </thead>
      <tbody>
        {cotizacion.amortizaciones.map(p => (
          <tr key={`pago${p.num_amortizacion}`}>
            <td>{p.num_amortizacion}</td>
            <td>
              {moment(p.fecha).format('DD-MM-YYYY')}
            </td>
            <td className="text-right">
              {currencyFormat(p.capital)}
            </td>
            <td className="text-right">
              {currencyFormat(p.interes)}
            </td>
            <td className="text-right">
              {currencyFormat(p.iva)}
            </td>
            <td className="text-right" style={{ color: colorTotal }}>
              {currencyFormat(p.abonado)}
            </td>
            <td className="text-center">
              {
                p.pagada
                  ? <i className="fa fa-check verde" aria-hidden="true"></i>
                  : <i className="fa fa-times rojo" aria-hidden="true"></i>
              }
            </td>
            <td>
              {p.fecha_pagada ? moment(p.fecha_pagada).format('DD-MM-YYYY') : ''}
            </td>
          </tr>
        ))}
      </tbody>
      {/* <tfoot>
        <tr>
          <th />
          <th className="text-right">
            {currencyFormat(cotizacion.totales.capital)}
          </th>
          <th className="text-right">
            {currencyFormat(cotizacion.totales.interes)}
          </th>
          <th className="text-right">
            {currencyFormat(cotizacion.totales.iva)}
          </th>
          <th className="text-right">
            {currencyFormat(cotizacion.totales.total)}
          </th>
          <th />
        </tr>
      </tfoot> */}
    </table>
    <style jsx="true">{`
      .fz {
        font-size: ${fontSize};
      }
      .table-wrapper-scroll-y {
        display: block;
        position: relative;
        ${esModal ? `height: ${window.innerHeight - ((window.innerHeight / 12) * 4)}px` : ''};
        overflow: auto;
      }
    `}</style>
  </div>
)

export default Cotizacion
