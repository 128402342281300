import React, { Component } from 'react';
import moment from 'moment';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import {obtenerMisRecargas} from '../../api/Recargas';
import {currencyFormat} from '../../utils/filters';
import imgPrepago from '../../images/iconos/prepago_big.svg';

class Lista extends Component {
  state = {
    recargas: []
  }

  componentDidMount = async () => {
    try {
      const resp = await obtenerMisRecargas();
      this.setState({ recargas: resp.data.results });
    } catch (error) {
      console.log('Error:', error);
    }
  }
  

  render() {
    const { recargas } = this.state;
    return (
      <Autenticado menuSelected="recargas">
        <TituloBotonDerecha
          icono={imgPrepago}
          titulo="Historial de recargas"
          descripcion1="Historial de recargas de tiempo aire elecrónico"
          descripcion2=""
          textoBoton="Recargar tiempo aire"
          linkBoton="/recargas/comprar"
        />
        <div className="row mt2">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {
                    recargas.map(r => (
                      <tr key={`rec${r.id}`}>
                        <td>
                          <img src={r.proveedor.imagen} alt={r.proveedor.nombre} width="75" />
                        </td>
                        <td>
                          <span className="td-label">
                            Monto<br />
                            <span className="td-value">{currencyFormat(r.monto)}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Teléfono<br />
                            <span className="td-value">{r.numero_telefono}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Comisión<br />
                            <span className="td-value">{currencyFormat(r.comision)}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Total<br />
                            <span className="td-value">{currencyFormat(r.total)}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Estatus<br />
                            <span className="td-value">{r.estatus}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Folio<br />
                            <span className="td-value">{r.folio}</span>
                          </span>
                        </td>
                        <td>
                          <span className="td-label">
                            Fecha<br />
                            <span className="td-value">{moment(r.fecha_creacion).format('DD-MM-YYYY')}</span>
                          </span>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            {
              recargas.length === 0 && (
                <div className="text-center font-italic">
                  Aun no tienes recargas de tiempo aire electrónico.
                </div>
              )
            }
          </div>
        </div>
        <style jsx="true">{`
          .flex1 {
            flex: 1;
          }
          .td {
            vertical-align: middle !important;
          }
          .td-label {
            color: #333;
            font-size: 0.8rem;
          }
          .td-value {
            color: #25d07b;
            font-size: 0.8rem;
          }
          .icon {
            padding: 0.5rem;
          }
          .icon-check {
            color: #25d07b;
          }
          .icon-eye {
            width: 1rem;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default Lista
