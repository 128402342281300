import React from 'react';

const TituloBotonDerecha = ({icono, titulo, descripcion1, descripcion2, textoBoton = '', linkBoton = '#'}) => (
  <div className="row border-bottom-green row-mt-15">
    <div className="col-md-12">
      <div className="ctbd-div-titulo">
        <div className="ctbd-titulo">
          <img src={icono} alt="Pago" className="icono-titulo-seccion" style={{marginRight: '1rem', height: '60px'}} />
          <div className="div-titulo-seccion">
            <h3 className="titulo-seccion">{titulo}</h3>
            {
              (!!descripcion1 || !!descripcion2) && (
                <p className="subtitulo-seccion">
                  {!!descripcion1 && descripcion1}{!!descripcion1 && <br />}
                  {!!descripcion2 && descripcion2}
                </p>
              )
            }
          </div>
        </div>
        {
          !!textoBoton && (
            <a href={linkBoton} className="btn btn-success">
              {textoBoton}
            </a>
          )
        }
      </div>
    </div>
  </div>
)

export default TituloBotonDerecha
