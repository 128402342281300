import axios from 'axios'
import { getToken } from '../utils/auth'
import { BASE_URL } from '../utils/config'

function ConstructorAPI (timeout = 5000) {
  const token = getToken()

  return axios.create({
    baseURL: BASE_URL,
    headers: {
      'Authorization': `Bearer ${token}`,
      'content-type': 'application/json',
      'Accept': '*/*'
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    timeout,
    type: 'json'
  })
}

async function ConstructorAPIAsync (timeout = 5000) {
  const token =  getToken()

  const headers = {
    'content-type': 'application/json',
    'Accept': '*/*'
  }

  if (token !== null) {
    headers.Authorization = `Bearer ${token}`
  }

  const instance = await axios.create({
    baseURL: BASE_URL,
    headers,
    responseType: 'json',
    responseEncoding: 'utf8',
    timeout,
    type: 'json'
  })

  return instance
}

export {
  ConstructorAPI,
  ConstructorAPIAsync
}