import React from 'react'
import '../fonts/font-awesome/css/font-awesome.css'

const NoAutenticado = ({ children }) => (
  <div className="login-bg">
      <div className="middle-box text-center loginscreen animated fadeInDown">
          { children }
      </div>
  </div>
)

export default NoAutenticado
