import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Inicio from './pages/Inicio';
import PerfilVer from './pages/Perfil/Ver';
import InfoEmpleado from './pages/Perfil/InfoEmpleado';
import Domicilio from './pages/Perfil/Domicilio';
import InfoBancaria from './pages/Perfil/InfoBancaria';
import Percepciones from './pages/Perfil/Percepciones';
import ListaServicios from './pages/PagoServicios/ListaServicios';
import PagoServicio from './pages/PagoServicios/PagoServicio';
import Recibo from './pages/PagoServicios/Recibo';
import Frecuentes from './pages/PagoServicios/Frecuentes';
import ListaCreditos from './pages/SolicitudCredito/Lista';
import SolicitudCredito from './pages/SolicitudCredito/Solicitud';
import Referencias from './pages/SolicitudCredito/Referencias';
import InformacionCrediticia from './pages/SolicitudCredito/InformacionCrediticia';
// import OtrosCreditos from './pages/SolicitudCredito/OtrosCreditos';
import Comprobantes from './pages/SolicitudCredito/Comprobantes';
import Resumen from './pages/SolicitudCredito/Resumen';
import FirmarDocumentos from './pages/SolicitudCredito/FirmarDocumentos';
import Disposicion from './pages/Disposicion/Disposicion';
import HistorialRecargas from './pages/Recargas/Lista';
import Recarga from './pages/Recargas/Recarga';
import RecargaRecibo from './pages/Recargas/Recibo';
import EstadoDeCuenta from './pages/EstadoDeCuenta/EstadoDeCuenta';
import IniciarSesion from './pages/IniciarSesion';
import ActivarCuenta from './pages/ActivarCuenta';
import ResetarContrasena from './pages/ResetarContrasena';
import CambiarContrasena from './pages/CambiarContrasena';
import Error404 from './pages/Error404';
import { isLogin } from './utils/auth';

const PrivateRoute = ({ component: Componente, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLogin() === true ? (
          <Componente {...props} />
        ) : (
          <Redirect to="/iniciar-sesion" />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Componente, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLogin() === false ? <Componente {...props} /> : <Redirect to="/" />
      }
    />
  );
};

class App extends Component {
  state = {
    token: undefined
  };

  render() {
    return (
      <Switch>
        <PrivateRoute exact path="/" component={Inicio} />
        <PrivateRoute exact path="/perfil/ver" component={PerfilVer} />
        {/*<PrivateRoute exact path="/perfil/editar" component={InfoEmpleado} />*/}
        {/*<PrivateRoute exact path="/perfil/editar/domicilio" component={Domicilio} />*/}
        {/*<PrivateRoute exact path="/perfil/editar/info-bancaria" component={InfoBancaria} />*/}
        {/*<PrivateRoute exact path="/perfil/editar/percepciones" component={Percepciones} />*/}
        {/*<PrivateRoute exact path="/servicios" component={ListaServicios} />*/}
        {/*<PrivateRoute exact path="/servicios/:id/pago" component={PagoServicio} />*/}
        {/*<PrivateRoute exact path="/servicios/:id/recibo" component={Recibo} />*/}
        {/*<PrivateRoute exact path="/servicios/frecuentes" component={Frecuentes} />*/}
        <PrivateRoute exact path="/lista-creditos" component={ListaCreditos} />
        <PrivateRoute exact path="/solicitud-credito" component={SolicitudCredito} />
        {/*<PrivateRoute exact path="/solicitud-credito/:id/referencias" component={Referencias} />*/}
        {/*<PrivateRoute exact path="/solicitud-credito/:id/otros-creditos" component={InformacionCrediticia} />*/}
        {/*<PrivateRoute exact path="/solicitud-credito/:id/comprobantes" component={Comprobantes} />*/}
        {/*<PrivateRoute exact path="/solicitud-credito/:id/resumen" component={Resumen} />*/}
        {/*<PrivateRoute exact path="/solicitud-credito/:id/documentos-firmar" component={FirmarDocumentos} />*/}
        {/*<PrivateRoute exact path="/prestamo-express" component={Disposicion} />*/}
        {/*<PrivateRoute exact path="/recargas" component={HistorialRecargas} />*/}
        {/*<PrivateRoute exact path="/recargas/comprar" component={Recarga} />*/}
        {/*<PrivateRoute exact path="/recargas/:id/recibo" component={RecargaRecibo} />*/}
        <PrivateRoute exact path="/estado-de-cuenta" component={EstadoDeCuenta} />
        <PrivateRoute exact path="/cambiar-contrasena" component={CambiarContrasena} />
        <PublicRoute exact path="/iniciar-sesion" component={IniciarSesion} />
        <PublicRoute exact path="/activar-cuenta/:id/:uuid/" component={ActivarCuenta} />
        <PublicRoute exact path="/reset/:id/:uuid/" component={ResetarContrasena} />
        <Route component={Error404} />
        <Route exact path="/pagina-no-encontrada" component={Error404} />
      </Switch>
    );
  }
}

export default App;
