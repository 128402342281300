import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NoAutenticado from '../components/NoAutenticado';
import Input from '../components/form/Input';
import { Auth, resetearContrasena } from '../api/Auth';
import { setToken, setUserInfo } from '../utils/auth';
import { validateEmail } from '../utils/regex';
import logo from '../images/login/logo_yuhu_blanco.svg';
import iconoUsuario from '../images/login/icono_nombre_usuario.svg';
import iconoPass from '../images/login/icono_contrasena.svg';

const MySwal = withReactContent(Swal)

export class IniciarSesion extends Component {
  state = {
    datos: {
      email: '',
      password: ''
    },
    correo: '',
    errores: {}
  }

  handleChange = (e) => {
    const { datos } = this.state;
    datos[e.target.name] = e.target.value;
    this.setState({ datos, errores: {} });
  }

  handleChangeEmail = (e) => {
    console.log(e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      datos: { email, password }
    } = this.state;

    if (email.length === 0) {
      validado = false;
      errores.email = ['Este campo es requerido.'];
    }
    if (password.length === 0) {
      validado = false;
      errores.password = ['Este campo es requerido.'];
    }

    this.setState({ errores })
    return validado;
  }

  iniciarSesion = async (e) => {
    e.preventDefault();
    if(!this.validar()) { return; }
    try {
      const { datos } = this.state;
      const resp = await Auth(datos);
      if (resp.status === 200){
        await setToken(resp.data.token);
        await setUserInfo(resp.data.user);
        this.props.history.push('/');
      }
    } catch (error) {
      console.log('Error:', error);
      if (error.status === 400) {
        this.setState({ errores: error.data });
      }
    }
  }

  enviarEmailRecuperar = async () => {
    const { correo } = this.state;
    if (correo.length === 0) {
      Swal.fire(
        'Correo no enviado',
        'El correo electrónico es obligatorio.',
        'error'
      );
    } else if (correo.length === 0 || !validateEmail(correo)) {
      Swal.fire(
        'Correo no enviado',
        'El correo electrónico no esta en el formato correcto.',
        'error'
      );
    } else {
      try {
        await resetearContrasena(correo);
        Swal.fire(
          'Recuperar contraseña',
          'Si el correo electrónico es válido, por favor revise su bandeja de entrada.',
          'success'
        );
      } catch (error) {
        if (error.status === 400) {
          Swal.fire(
            'Correo no enviado',
            'Dirección de correo electrónico no existe.',
            'error'
          );
        } else {
          Swal.fire(
            'Correo no enviado',
            'Ocurrio un error al enviar el correo electrónico.',
            'error'
          );
        }
      }
    }
  }

  modalRecuperarContrasena = (e) => {
    e.preventDefault();
    const { errores } = this.state;
    MySwal.fire({
      title: 'Recuperar contraseña',
      html: <Input
        label="Correo electrónico"
        type="email"
        name="correo"
        placeholder="ejemplo@mail.com"
        error={errores.correo}
        onChange={this.handleChangeEmail}
      />,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Recuperar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ml1'
      }
    }).then((result) => {
      if (result.value) {
        this.enviarEmailRecuperar()
      }
    });
  }

  render () {
    const {
      datos: { email, password },
      errores
    } = this.state;
    return (
      <NoAutenticado>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <a href="https://yuhu.mx/">
                <img src={logo} alt="yuhu" className="logo-login" />
              </a>
            </div>
          </div>
          <div className="row mt3">
            <div className="col-sm-5 offset-sm-7 login-box">
                <div className="titulo-box">
                  <span>iniciar sesión</span>
                </div>
                <div className="contenido-box">
                  <form onSubmit={(e) => this.iniciarSesion(e)}>
                    <div className="row mb1 mt1">
                      <div className="col-1 mb1">
                        <img src={iconoUsuario} alt="usuario" />
                      </div>


                      <div className="col-11 text-left">
                        <Input
                          name="email"
                          placeholder="correo del usuario_"
                          value={email}
                          error={errores.email}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb1">
                      <div className="col-1 mb1">
                        <img src={iconoPass} alt="contraseña" />
                      </div>
                      <div className="col-11 text-left">
                        <Input
                          type="password"
                          name="password"
                          placeholder="contraseña_"
                          value={password}
                          error={errores.password}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb1">
                      <div className="col-1" />
                      <div className="col-11 text-left">
                        <button
                          type="submit"
                          className="btn btn-success btn-block btn-lg"
                          // onClick={ this.iniciarSesion }
                        >
                          iniciar sesión
                        </button>
                        {
                          errores.non_field_errors && errores.non_field_errors.map((obj, index) => (
                            <div key={`err${index}`} className="invalid-feedback" style={{ display: 'block' }}>
                              <b>{obj}</b>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1" />
                      <div className="col-11 text-left">
                        <label>
                          <input type="checkbox" />
                          <span className="pl0-5">
                          recordar usuario
                        </span>
                        </label>
                      </div>
                    </div>
                  </form>
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <a href="#" onClick={this.modalRecuperarContrasena}>
                        ¿Olvidaste tu contraseña?
                      </a>
                    </div>
                  </div>
                  <div className="row mb1">
                    <div className="col-1" />
                    <div className="col-11 text-left">
                      <h3>
                        La plataforma financiera que te ayuda a conseguir tus sueños.
                      </h3>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          {/* <div className="row mt3">
            <div className="col-12 text-center verde">
              <h1><b>en 15 minutos te confirmamos vía whatsapp</b></h1>
            </div>
          </div> */}
        </div>
        <style jsx="true">{`
          .pl0-5 {
            padding-left: 0.5rem;
          }
        `}</style>
      </NoAutenticado>
    )
  }
}

export default IniciarSesion
