import React, { Component } from 'react';
import {
  solicitarDisposicion,
  cotizarDisposicion
} from '../../api/Disposiciones';
import {
  obtenerSaldoEmergenteDisponible
} from '../../api/Empleados';
import Autenticado from '../../components/Autenticado';
import TituloSaldo from '../../components/general/TituloSaldo';
import InputMonto from '../../components/form/InputMonto';
import Cotizacion from '../../components/solicitudes/Cotizacion';
import imgCredito from '../../images/iconos/credito.svg';
import imgEye from '../../images/iconos/eye.svg';
import {
  capitalizeOnlyFirst,
  currencyFormat
} from '../../utils/filters';
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts';
import Swal from "sweetalert2";
import { verificarServicio } from '../../api/Utils';

class Disposicion extends Component {
  state = {
    servicioActivo: false,
    saldoDisponible: '0',
    monto: '',
    calculado: false,
    verAmortizaciones: false,
    cotizacion: {},
    errores: {}
  };

  componentDidMount = async () => {
    this.verificarServicio();
    try {
      const resp = await obtenerSaldoEmergenteDisponible();
      this.setState({ saldoDisponible: resp.data.saldo_disponible });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  verificarServicio = async () => {
    try {
      const req = await verificarServicio('disposiciones');
      if(req.data.servicio_activo){
        this.setState({servicioActivo: req.data.servicio_activo});
      }
    }catch (e) {
      console.log(e);
    }
  }
  

  handleChange = (e) => {
    const { target: { name, value }} = e;
    this.setState({ [name]: value, calculado: false, errores: {} });
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      monto,
      saldoDisponible
    } = this.state;

    if (monto.length === 0) {
      validado = false;
      errores.monto = ['Ingresa un monto.'];
    } else if (parseFloat(monto) > parseFloat(saldoDisponible)) {
      validado = false;
      errores.monto = [`El monto disponible es: ${currencyFormat(saldoDisponible)}`];
    }

    this.setState({ errores })
    return validado;
  }

  calcular = async () => {
    if(!this.validar()) { return; }
    const {
      monto,
    } = this.state;
    try {
      const resp = await cotizarDisposicion(monto);
      this.setState({
        cotizacion: resp.data,
        calculado: true
      });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  crear = async () => {
    cargando();
    const {
      monto,
    } = this.state;
    try {
      const resp = await solicitarDisposicion(monto);
      const disposicion = resp.data;
      cerrarAlertas();
      mostrarToast('Disposición de efectivo completada exitosamente.')
      Swal.fire({
        // title: 'Usuario activado',
        html: `
          <div>Tu solicitud de disposición de efectivo se realizó con éxito, a la brevedad recibirás un correo electrónico con la información y el estatus de tu solicitud. 
          <br><br>
          <h4>Información de la disposición:</h4>
          <b>ID:</b> ${disposicion.id} <br>
          <b>Monto:</b> ${ currencyFormat(disposicion.monto) } <br>
          <b>Estatus:</b> ${ capitalizeOnlyFirst(disposicion.estatus) }
          <br><br>
          <a href="/" class="btn btn-success">Continuar</a>
          </div>`,
        showConfirmButton: false,
        position: 'center',
        allowOutsideClick: false,
        allowEscapeKey: false,
        // imageUrl: spinner,
        // imageWidth: 100,
        // imageHeight: 100,
        // imageAlt: 'Espera un momento'
      });
      // this.props.history.push('/');
    } catch (error) {
      cerrarAlertas();
      if (error.status === 400) {
        this.setState({ errores: error.data });
      } else {
        mostrarToast(error.message, 'error');
      }
    }
  }

  render() {
    const {
      monto,
      calculado,
      cotizacion,
      errores,
      verAmortizaciones,
      saldoDisponible
    } = this.state;
    return (
      <Autenticado menuSelected="prestamo-express">
        <TituloSaldo
          icono={imgCredito}
          titulo="Disposición de efectivo"
          descripcion1="¿Cuánto dinero necesitas para hacer lo que planeaste?"
          descripcion2=""
          saldo={saldoDisponible}
        />
        {!this.state.servicioActivo ?
          <div className="row mt2">
            <div className="col-md-10 offset-md-1">
              <div className="text-center mt-5">
                <p>Por el momento el servicio no se encuentra activo, envíanos un mensaje para darte mas
                  información.</p>
              </div>
            </div>
          </div>
          :
          <div className="row mt2">
            <div className="col-sm-4">
              <InputMonto
                label="Monto a solicitar"
                name="monto"
                placeholder="_"
                value={monto}
                maxLength={11}
                // bgColor="#25d07b"
                // valueColor="#FFF"
                error={errores.monto}
                onChange={this.handleChange}
              />
              <button className="btn btn-success mb1" type="button" onClick={this.calcular}>
                Calcular
              </button>
            </div>
            {
              calculado && (
                <div className="col-sm-3 offset-sm-1">
                  <div className="informacion">
                    <div className="info-row">
                      <span>Pago quincenal</span>
                      <span className="pago">{currencyFormat(cotizacion.pagos[0].total_pagar)}</span>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link flex-row2"
                      onClick={() => this.setState({ verAmortizaciones: !verAmortizaciones })}
                    >
                      <img src={imgEye} alt="Ver" />
                      <small>Desgloce tabla amortización</small>
                    </button>
                  </div>
                </div>
              )
            }
            {
              calculado && (
                <div className="col-sm-3 offset-sm-1">
                  <div className="informacion">
                    <div className="info-row">
                      <span className="etiqueta">Total a pagar</span>
                      <span className="total">{currencyFormat(cotizacion.totales.total)}</span>
                    </div>
                    <button
                      className="btn btn-success mt2"
                      type="button"
                      onClick={this.crear}
                    >
                      Solicitar
                    </button>
                  </div>
                </div>
              )
            }
          </div>
        }

        {
          verAmortizaciones && (
            <div className="row mt1">
              <div className="col-12">
                <Cotizacion
                  cotizacion={cotizacion}
                  fontSize="1rem"
                  esModal={false}
                  colorTotal="#ff6b6b"
                  colorSaldo="#25d07b"
                />
              </div>
            </div>
          )
        }
        <style jsx="true">{`
          .flex-row2 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
            padding-left: 0px !important;
            color: #333 !important;
          }
          .flex-row2 img {
            width: 20px;
            margin-right: 0.5rem;
          }
          .informacion {
            padding: 1rem;
          }
          .info-row{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 1rem;
          }
          .informacion .info-row span{
            color: #333;
          }
          .informacion .info-row b{
            color: #25d07b;
          }
          .info-row .etiqueta{
            font-size: 0.8rem;
            font-weight: bold;
          }
          .info-row .pago{
            color: #666 !important;
            font-size: 1.7rem;
            font-weight: bold;
          }
          .info-row .total{
            color: #25d07b !important;
            font-size: 2rem;
            font-weight: bold;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default Disposicion
