import { removeToken } from '../utils/auth';

const callError = (err) => {
  let message = 'Ocurrio un error'
  let status = 400
  let data = {}

  if (err.response) {
    status = err.response.status
    if (status === 403) {
      message = err.response.data.detail
    } else if (status === 400) {
      message = 'Error en el formulario.'
      data = err.response.data
    } else if (status === 401) {
      removeToken()
      window.location.replace("/iniciar-sesion");
    }
  } else if (err.message && err.message === 'Network Error') {
    message = 'No tiene conexion a internet.'
    status = 408
  } else if (err.message) {
    message = err.message
  }

  const error = { message, status, data }
  throw error
}

export {callError};