import React from 'react';
import {currencyFormat} from '../../utils/filters';

const TituloBotonDerecha = ({icono, titulo, descripcion1, descripcion2, saldo = ''}) => (
  <div className="row border-bottom-green row-mt-15">
    <div className="col-md-12">
      <div className="ctbd-div-titulo">
        <div className="ctbd-titulo">
          <img src={icono} alt="Pago" className="icono-titulo-seccion" style={{marginRight: '1rem', height: '60px'}} />
          <div className="div-titulo-seccion">
            <h3 className="titulo-seccion">{titulo}</h3>
            {
              (!!descripcion1 || !!descripcion2) && (
                <p className="subtitulo-seccion">
                  {!!descripcion1 && descripcion1}{!!descripcion1 && <br />}
                  {!!descripcion2 && descripcion2}
                </p>
              )
            }
          </div>
        </div>
        {
          !!saldo && (
            <div className="flex-column-end-center">
              <small className="d-none d-sm-block">Saldo disponible</small>
              <b className="verde fsz-2 d-none d-sm-block">{currencyFormat(saldo)}</b>
            </div>
          )
        }
      </div>
      {
        !!saldo && (
          <div className="d-block d-sm-none">
            <small>Saldo disponible: </small>
            <b className="verde fsz-2">{currencyFormat(saldo)}</b>
          </div>
        )
      }
    </div>
  </div>
)

export default TituloBotonDerecha
