import React, { Component } from 'react';
import moment from 'moment';
import {
  obtenerComprobantes,
  obtenerReferencias,
  obtenerOtrosCreditos,
  completarSolicitud,
  obtenerDetalleSolicitud
} from '../../api/Solicitudes';
import {obtenerPerfil} from '../../api/Empleados';
import {currencyFormat} from '../../utils/filters';
import Autenticado from '../../components/Autenticado';
import TituloBotonDerecha from '../../components/general/TituloBotonDerecha';
import Card from '../../components/general/Card';
import InfoRow from '../../components/general/InfoRow';
import imgCredito from '../../images/iconos/credito.svg';
import imgOkVerde from '../../images/iconos/okverde.svg';
import imgEye from '../../images/iconos/ojo.svg';
import imgExito from '../../images/iconos/exito.svg';
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts';

const PLAZOS = {
  semanal: 'semanas',
  quincenal: 'quincenas',
  catorcenal: 'catorcenas',
  decenal: 'decenas',
  mensual: 'meses'
};
const PLAZOS2 = {
  semanal: 'semanal',
  quincenal: 'quincenal',
  catorcenal: 'catorcenal',
  decenal: 'decenal',
  mensual: 'mensual'
};

class OtrosCreditos extends Component {
  state = {
    infoEmpleado: {
      domicilio: {
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        codigo_postal: '',
        municipio_str: '',
        estado_str: '',
      },
      informacion_bancaria: {
        banco: '',
        cuenta: '',
        clabe: ''
      },
      percepciones: {
        salario_diario: '',
        salario_diario_integrado: '',
        sueldo_mensual: '',
        sueldo_mensual_real: ''
      }
    },
    referencias: [{}, {}],
    otrosCreditos: [],
    comprobantes: [],
    solicitud: {
      informacion_crediticia: {},
      cotizacion: {
        pagos: [
          {total_pagar: ''}
        ],
        totales: {
          capital: '',
          interes: '',
          iva: '',
          total: ''
        }
      }
    },
    terminosAceptados: false
  }

  componentDidMount = async () => {
    cargando();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const promesas = [];
    promesas.push(obtenerPerfil());
    promesas.push(obtenerReferencias(id));
    promesas.push(obtenerOtrosCreditos(id));
    promesas.push(obtenerComprobantes(id));
    promesas.push(obtenerDetalleSolicitud(id));
    try {
      const [
        respPerfil,
        respReferencias,
        respOtrosCreditos,
        respComprobantes,
        respDetalleSolicitud
      ] = await Promise.all(promesas);
      const infoEmpleado = respPerfil.data;
      if (!infoEmpleado.domicilio) {
        infoEmpleado.domicilio = {
          calle: '',
          numero_exterior: '',
          numero_interior: '',
          colonia: '',
          codigo_postal: '',
          municipio_str: '',
          estado_str: '',
        };
      }
      if (!infoEmpleado.informacion_bancaria) {
        infoEmpleado.informacion_bancaria = {
          banco: '',
          cuenta: '',
          clabe: ''
        };
      }
      if (!infoEmpleado.percepciones) {
        infoEmpleado.percepciones = {
          salario_diario: '',
          salario_diario_integrado: '',
          sueldo_mensual: '',
          sueldo_mensual_real: ''
        };
      }
      this.setState({
        infoEmpleado,
        referencias: respReferencias.data,
        otrosCreditos: respOtrosCreditos.data,
        comprobantes: respComprobantes.data,
        solicitud: respDetalleSolicitud.data
      });
      cerrarAlertas();
    } catch (error) {
      cerrarAlertas();
      mostrarToast('Ocurrio un error el cargar la información', 'error');
    }
  }

  submit = async () => {
    console.log('Click1:');
    const {terminosAceptados} = this.state;
    if (!terminosAceptados) {
      mostrarToast('Por favor acepta los terminos y condiciones.', 'warning');
      return;
    }
    const {
      match: {
        params: { id }
      }
    } = this.props;
    try {
      cargando();
      console.log('Click2:');
      await completarSolicitud(id);
      cerrarAlertas();
      mostrarToast('Solicitud enviada exitosamente.')
      this.props.history.push('/lista-creditos');
    } catch (error) {
      cerrarAlertas();
      mostrarToast('Ocurrio un error al crear la solicitud', 'error')
    }
  }

  render() {
    const {
      infoEmpleado,
      referencias,
      otrosCreditos,
      comprobantes,
      terminosAceptados,
      solicitud
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const solicitudCredito = (
      <div className="row mb1 mt2">
        <div className="col-12">
          <Card padding="1rem" titulo="Solicitud" link="/solicitud-credito" backgroundColor="#FBEBAF">
            <div className="row">
              <div className="col-sm-4">
                <InfoRow label="Folio" value={solicitud.folio} fsz="0.7rem" />
                <InfoRow label="Producto" value={solicitud.producto_str} fsz="0.7rem" />
                <InfoRow label="Plazo" value={`${solicitud.num_amortizaciones} ${PLAZOS[solicitud.frecuencia_cobro]}`} fsz="0.7rem" />
                <InfoRow label="Costo del crédito" value={ solicitud.cotizacion.costo > solicitud.cotizacion.costo_anual ? `${solicitud.cotizacion.costo_anual}%` : `${solicitud.cotizacion.costo}%`} fsz="0.7rem" />
              </div>
              <div className="col-sm-4">
                <InfoRow label="Monto" value={currencyFormat(solicitud.monto)} fsz="0.7rem" />
                <InfoRow label={`Pago ${PLAZOS2[solicitud.frecuencia_cobro]}`} value={currencyFormat(solicitud.cotizacion.pagos[0].total_pagar)} fsz="0.7rem" />
                <InfoRow label="Interés" value={currencyFormat(solicitud.cotizacion.totales.interes)} fsz="0.7rem" />
                <InfoRow label="IVA" value={currencyFormat(solicitud.cotizacion.totales.iva)} fsz="0.7rem" />
                <InfoRow label="Total" value={currencyFormat(solicitud.cotizacion.totales.total)} fsz="0.7rem" />
              </div>
              <div className="col-sm-4 terminos">
                <div className="d-block d-sm-none mt1"></div>
                <a href="https://yuhu.mx/terminos-condiciones/" target="_blank" rel="noopener noreferrer" className="terminos">
                  <b>Términos y condiciones del contrato</b><br />
                </a>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="chkTerminos"
                    checked={terminosAceptados}
                    onChange={() => this.setState({ terminosAceptados: !terminosAceptados })}
                  />
                  <label className="custom-control-label" htmlFor="chkTerminos">Acepto los terminos y condiciones del contrato</label>
                </div>
                <button
                  type="button"
                  className="btn btn-success mt05 flex-center"
                  onClick={this.submit}
                >
                  <img src={imgExito} alt="Exito" width="20" />
                  &nbsp;Enviar solicitud
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
    const datosPersonales = (
      <div className="row mb1">
        <div className="col-12">
          <Card padding="1rem" titulo="Datos personales" link="/perfil/editar">
            <InfoRow label="Nombre" value={`${infoEmpleado.nombre} ${infoEmpleado.apellido_paterno} ${infoEmpleado.apellido_materno}`} fsz="0.7rem" />
            <InfoRow label="Fecha de nacimiento" value="1 de enero de 1930" fsz="0.7rem" />
            <InfoRow label="RFC" value={infoEmpleado.rfc} fsz="0.7rem" />
            <InfoRow label="CURP" value={infoEmpleado.curp} fsz="0.7rem" />
            <InfoRow label="Género" value={infoEmpleado.genero} fsz="0.7rem" />
            <InfoRow label="Nacionalidad" value="Mexicana" fsz="0.7rem" />
            <InfoRow label="Estado civil" value="Soltero" fsz="0.7rem" />
            <InfoRow label="E-mail" value={infoEmpleado.email} fsz="0.7rem" />
            <InfoRow label="Teléfono casa" value={infoEmpleado.telefono_casa} fsz="0.7rem" />
            <InfoRow label="Celular" value={infoEmpleado.telefono_celular} fsz="0.7rem" />
            <InfoRow label="Empresa" value={infoEmpleado.empresa_str} fsz="0.7rem" />
            <InfoRow label="Puesto" value={infoEmpleado.puesto} fsz="0.7rem" />
            <InfoRow label="Fecha de contratación" value={moment(infoEmpleado.fecha_contratacion).format('LL')} fsz="0.7rem" />
          </Card>
        </div>
      </div>
    );
    const domicilio = (
      <div className="row mb1">
        <div className="col-12">
          <Card padding="1rem" titulo="Domicilio" link="/perfil/editar/domicilio">
            <InfoRow label="Calle" value={infoEmpleado.domicilio.calle} fsz="0.7rem" />
            <InfoRow label="Número" value={`${infoEmpleado.domicilio.numero_exterior}${infoEmpleado.domicilio.numero_interior ? ` int. ${infoEmpleado.domicilio.numero_interior}` : ''}`} fsz="0.7rem" />
            <InfoRow label="Colonia" value={infoEmpleado.domicilio.colonia} fsz="0.7rem" />
            <InfoRow label="Código Postal" value={infoEmpleado.domicilio.codigo_postal} fsz="0.7rem" />
            <InfoRow label="Municipio" value={infoEmpleado.domicilio.municipio_str} fsz="0.7rem" />
            <InfoRow label="Estado" value={infoEmpleado.domicilio.estado_str} fsz="0.7rem" />
          </Card>
        </div>
      </div>
    );
    const infoBancaria = (
      <div className="row mb1">
        <div className="col-12">
          <Card padding="1rem" titulo="Información bancaria" link="/perfil/editar/info-bancaria">
            <InfoRow label="Banco" value={infoEmpleado.informacion_bancaria.banco} fsz="0.7rem" />
            <InfoRow label="Cuenta" value={infoEmpleado.informacion_bancaria.cuenta} fsz="0.7rem" />
            <InfoRow label="Clabe" value={infoEmpleado.informacion_bancaria.clabe} fsz="0.7rem" />
          </Card>
        </div>
      </div>
    );

    const infoCrediticia = (
      <div className="row mb1">
        <div className="col-12">
          <Card padding="1rem" titulo="Información crediticia" link={`/solicitud-credito/${id}/otros-creditos`}>
            <InfoRow label="Crédito hipotecario" value={solicitud.informacion_crediticia.credito_hipotecario} fsz="0.7rem" />
            <InfoRow label="Crédito automotriz" value={solicitud.informacion_crediticia.credito_automotriz} fsz="0.7rem" />
            <InfoRow label="Tarjeta crédito" value={solicitud.informacion_crediticia.tarjeta_credito} fsz="0.7rem" />
          </Card>
        </div>
      </div>
    );

    const percepciones = (
      <div className="row mb1">
        <div className="col-12">
          <Card padding="1rem" titulo="Percepciones" link="/perfil/editar/percepciones">
            <InfoRow label="Salario diario" value={currencyFormat(infoEmpleado.percepciones.salario_diario)} fsz="0.7rem" />
            <InfoRow label="Salario diario integrado" value={currencyFormat(infoEmpleado.percepciones.salario_diario_integrado)} fsz="0.7rem" />
            <InfoRow label="Sueldo mensual" value={currencyFormat(infoEmpleado.percepciones.sueldo_mensual)} fsz="0.7rem" />
            <InfoRow label="Sueldo mensual real" value={currencyFormat(infoEmpleado.percepciones.sueldo_mensual_real)} fsz="0.7rem" />
          </Card>
        </div>
      </div>
    );
    const referencia = (titulo, datos) => (
      <div className="row mb1">
        <div className="col-12">
          <Card padding="1rem" titulo={titulo} link={`/solicitud-credito/${id}/referencias`} backgroundColor="#cbecd6">
            <InfoRow label="Nombre" value={datos.nombre} fsz="0.7rem" />
            <InfoRow label="Parentesco" value={datos.parentesco} fsz="0.7rem" />
            <InfoRow label="Domicilio" value={datos.domicilio} fsz="0.7rem" />
            <InfoRow label="Teléfono" value={datos.telefono} fsz="0.7rem" />
          </Card>
        </div>
      </div>
    );
    const otroCredito = (datos) => (
      <div className="row mb1" key={`oc${datos.id}`}>
        <div className="col-12">
          <Card padding="1rem" titulo="Otros créditos" link="/perfil/editar/percepciones" backgroundColor="#e4e0e6">
            <InfoRow label="Tipo de crédito" value={datos.tipo_credito} fsz="0.7rem" />
            <InfoRow label="Institución" value={datos.institucion} fsz="0.7rem" />
            <InfoRow label="Pago mensual" value={currencyFormat(datos.pago_mensual)} fsz="0.7rem" />
            <InfoRow label="Fecha de contratación" value={datos.fecha_contratacion} fsz="0.7rem" />
          </Card>
        </div>
      </div>
    );
    const comprobantesCard = (
      <div className="row mb1">
        <div className="col-12">
          <Card padding="1rem" titulo="Comprobantes" link={`/solicitud-credito/${id}/comprobantes`}>
            {
              comprobantes.map(c => (
                <div key={`comp${c.id}`} className="flex-between border-bottom mt1">
                  <span className="td-label">
                    <span className="td-value">{c.nombre}</span><br />
                    Archivo
                  </span>
                  <div>
                    <img src={imgOkVerde} alt="Ok" width="20" />
                    <a href={c.documento} target="_blank" rel="noopener noreferrer">
                      <img src={imgEye} alt="Ok" width="20" className="ml05" />
                    </a>
                  </div>
                </div>
              ))
            }
          </Card>
        </div>
      </div>
    );
    return (
      <Autenticado menuSelected="solicitud-credito">
        <TituloBotonDerecha
          icono={imgCredito}
          titulo="Resumen de solicitud y aceptación"
          descripcion1="Verifica que la información escrita este correcta para poder agilizar el trámite de tu crédito."
          descripcion2=""
        />
        {solicitudCredito}
        <div className="row">
          <div className="col-sm-4">
            {datosPersonales}
            {/*{referencia('Referencia 01', referencias[0])}*/}
            {/*{referencia('Referencia 02', referencias[1])}*/}
          </div>
          <div className="col-sm-4">
            {domicilio}
            {infoCrediticia}
            {
              otrosCreditos.map(oc => otroCredito(oc))
            }
          </div>
          <div className="col-sm-4">
            {infoBancaria}
            {percepciones}
            {comprobantesCard}
          </div>
        </div>
        <style jsx="true">{`
          .border-bottom {
            padding-bottom: 1rem;
          }
          .td-label {
            color: #333;
            font-size: 0.7rem;
          }
          .td-value {
            color: #25d07b;
            font-size: 0.8rem;
            font-weight: bold;
            font-size: 0.7rem;
          }
          .ml05 {
            margin-left: 0.5rem;
          }
          .terminos {
            color: #333;
            font-size: 0.85rem;;
          }
          .custom-control-input:checked~.custom-control-label::before {
            color: #fff;
            border-color: #25d07b;
            background-color: #25d07b;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default OtrosCreditos
