import React from 'react';

const Card = ({
  children,
  titulo,
  link = '',
  mostrarTitulo = true,
  padding = '1.5rem',
  backgroundColor = '#FFF',
  height = '100%',
  border = true
}) => (
  <div className="card" style={{backgroundColor, height}}>
    <div className={border ? 'border' : 'withOutBorder'} style={{ padding, height }}>
      {
        mostrarTitulo && (
          <div className="titulo">
            <span className="title">{titulo}</span>
            { !!link && <a href={link}><i className="fa fa-pencil icon-card" aria-hidden="true"></i></a> }
          </div>
        )
      }
      <div className="contenido">
        {children}
      </div>
    </div>
  </div>
)

export default Card
