import React from 'react';
import Flatpickr from 'react-flatpickr';
import {Spanish} from 'flatpickr/dist/l10n/es';
import 'flatpickr/dist/themes/material_green.css';

const DatePicker = ({
  label,
  name,
  value,
  helpText,
  options = {
    dateFormat: 'd-m-Y',
    locale: Spanish
  },
  dataEnableTime = false,
  error = [],
  onChange
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Flatpickr
        className={`form-control date ${error.length > 0 ? 'is-invalid' : ''}`}
        data-enable-time={dataEnableTime}
        value={value}
        options={options}
        onChange={date => {
          const fechaInicio = date[0];
          onChange({
            target: {
              name,
              value: fechaInicio
            }
          });
        }}
      />
      {helpText && <small className="form-text text-muted">{helpText}</small>}
      {error.map((obj, index) => (
        <div key={`${name}${index}`} className="invalid-feedback">
          {obj}
        </div>
      ))}
      <style jsx="true">{`
        .date {
          background-color: #fff !important;
        }
      `}</style>
    </div>
  )
};

export default DatePicker;
