import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function obtenerPerfil () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/empleados/perfil/')
  } catch (err) {
    callError(err)
  }
}

async function actualizarPerfil (datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.put('/empleados/perfil/', datos)
  } catch (err) {
    callError(err)
  }
}

async function obtenerDomicilio () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/empleados/domicilio/')
  } catch (err) {
    callError(err)
  }
}

async function actualizarDomicilio (datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post('/empleados/domicilio/', datos)
  } catch (err) {
    callError(err)
  }
}

async function obtenerInfoBancaria () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/empleados/informacion-bancaria/')
  } catch (err) {
    callError(err)
  }
}

async function actualizarInfoBancaria (datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post('/empleados/informacion-bancaria/', datos)
  } catch (err) {
    callError(err)
  }
}

async function obtenerPercepciones () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/empleados/percepciones/')
  } catch (err) {
    callError(err)
  }
}

async function actualizarPercepciones (datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post('/empleados/percepciones/', datos)
  } catch (err) {
    callError(err)
  }
}

async function obtenerSaldoEmergenteDisponible () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/empleados/saldo-emergente-disponible/')
  } catch (err) {
    callError(err)
  }
}

async function obtenerUrlCajaDeAhorro () {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.get('/empleados/caja-ahorro/');
  } catch (err) {
    callError(err);
  }
}

async function obtenerUrlCajaDeAhorroYuhu () {
  const API = await ConstructorAPIAsync(30000);
  try {
    return await API.get('/empleados/caja-ahorro-yuhu/');
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerPerfil,
  actualizarPerfil,
  obtenerDomicilio,
  actualizarDomicilio,
  obtenerInfoBancaria,
  actualizarInfoBancaria,
  obtenerPercepciones,
  actualizarPercepciones,
  obtenerSaldoEmergenteDisponible,
  obtenerUrlCajaDeAhorro,
  obtenerUrlCajaDeAhorroYuhu
}