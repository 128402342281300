const currencyFormat = (value, decimalPosition = 2, renderSimbol = true) => {
  let decimalValue = value;
  if (!decimalValue) {
    decimalValue = 0;
  }
  if (typeof decimalValue === 'string') {
    try {
      decimalValue = parseFloat(decimalValue);
    } catch (error) {
      decimalValue = 0;
    }
  }
  return `${renderSimbol ? '$' : ''}${decimalValue
    .toFixed(decimalPosition)
    // .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

const capitalizeOnlyFirst = (stringValue = '') => {
    if (stringValue) {
      return stringValue.charAt(0).toUpperCase() + stringValue.slice(1).toLowerCase();
    }
    return '';
}

export {
  currencyFormat,
  capitalizeOnlyFirst
};
