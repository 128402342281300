import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import Input from '../../components/form/InputMonto';
import {
  obtenerPercepciones,
  actualizarPercepciones
} from '../../api/Empleados';
import {validateDecimal} from '../../utils/regex';

class Percepciones extends Component {
  state = {
    percepciones: {
      salario_diario: '',
      salario_diario_integrado: '',
      sueldo_mensual: '',
      sueldo_mensual_real: ''
    },
    errores: {}
  }

  componentDidMount = async () => {
    try {
      const resp = await obtenerPercepciones();
      this.setState({ percepciones: resp.data });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  handleChange = (e) => {
    const {target: {name, value}} = e;
    if (validateDecimal(value) || value.length === 0) {
      const { percepciones } = this.state;
      percepciones[name] = value;
      this.setState({ percepciones, errores: {} });
    }
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      percepciones
    } = this.state;

    if (percepciones.salario_diario.trim().length === 0) {
      validado = false;
      errores.salario_diario = ['Este campo es requerido.'];
    }
    if (percepciones.salario_diario_integrado.trim().length === 0) {
      validado = false;
      errores.salario_diario_integrado = ['Este campo es requerido.'];
    }
    if (percepciones.sueldo_mensual.trim().length === 0) {
      validado = false;
      errores.sueldo_mensual = ['Este campo es requerido.'];
    }
    if (percepciones.sueldo_mensual_real.trim().length === 0) {
      validado = false;
      errores.sueldo_mensual_real = ['Este campo es requerido.'];
    }

    this.setState({ errores });
    return validado;
  }

  guardar = async () => {
    if (!this.validar()) { return; }
    try {
      const { percepciones } = this.state;
      await actualizarPercepciones(percepciones)
      this.props.history.push('/perfil/ver')
    } catch (error) {
      console.log('Error:', error);
      if (error.status === 400) {
        this.setState({ errores: error.data });
      }
    }
  }

  render() {
    const { percepciones, errores } = this.state;
    return (
      <Autenticado menuSelected="perfil">
        <div className="row mb1 row-mt-15">
          <div className="col-md-12">
            <div className="titulo">
              <h3 className="titulo-seccion">Percepciones del empleado</h3>
            </div>
          </div>
        </div>
        <div className="row mb1">
          <div className="col-md-3 col-sm-6 form-group">
            <Input
              label="Salario diario"
              name="salario_diario"
              placeholder="_"
              value={percepciones.salario_diario}
              maxLength={11}
              error={errores.salario_diario}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-3 col-sm-6 form-group">
            <Input
              label="Salario diario integrado"
              name="salario_diario_integrado"
              placeholder="_"
              value={percepciones.salario_diario_integrado}
              maxLength={11}
              error={errores.salario_diario_integrado}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-3 col-sm-6 form-group">
            <Input
              label="Sueldo mensual"
              name="sueldo_mensual"
              placeholder="_"
              value={percepciones.sueldo_mensual}
              maxLength={11}
              error={errores.sueldo_mensual}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-3 col-sm-6 form-group">
            <Input
              label="Sueldo mensual real"
              name="sueldo_mensual_real"
              placeholder="_"
              value={percepciones.sueldo_mensual_real}
              maxLength={11}
              error={errores.sueldo_mensual_real}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-success" onClick={this.guardar}>Guardar</button>
            <a href="/perfil/ver" className="btn btn-secondary ml1">Cancelar</a>
          </div>
        </div>
      </Autenticado>
    )
  }
}

export default Percepciones
