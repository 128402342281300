import { ConstructorAPIAsync } from './API'
import { callError } from './Error'

async function obtenerMisSolicitudes () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/mis-solicitudes/')
  } catch (err) {
    callError(err)
  }
}

async function obtenerSolicitudIncompleta () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/mis-solicitudes/solicitud-incompleta/')
  } catch (err) {
    callError(err)
  }
}

async function obtenerDetalleSolicitud (id) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/mis-solicitudes/${id}/`)
  } catch (err) {
    callError(err)
  }
}

async function completarSolicitud (id) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/solicitudes/${id}/completar-solicitud/`)
  } catch (err) {
    callError(err)
  }
}

async function obtenerProductosDisponibles () {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get('/productos-disponibles/')
  } catch (err) {
    callError(err)
  }
}

async function crearSolicitud (datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post('/solicitudes/crear-solicitud/', datos)
  } catch (err) {
    callError(err)
  }
}

async function calcularSolicitud (idProducto, datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/productos/${idProducto}/cotizar/`, datos)
  } catch (err) {
    callError(err)
  }
}

async function obtenerReferencias (idSolicitud) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/solicitudes/${idSolicitud}/referencias/`)
  } catch (err) {
    callError(err)
  }
}

async function agregarReferencia (idSolicitud, datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/solicitudes/${idSolicitud}/referencias/`, datos)
  } catch (err) {
    callError(err)
  }
}

async function editarReferencia (idSolicitud, idReferencia, datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.put(`/solicitudes/${idSolicitud}/referencias/${idReferencia}/`, datos)
  } catch (err) {
    callError(err)
  }
}

async function eliminarReferencia (idSolicitud, idReferencia) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.del(`/solicitudes/${idSolicitud}/referencias/${idReferencia}/`)
  } catch (err) {
    callError(err)
  }
}

async function obtenerInfoCrediticia (idSolicitud) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/solicitudes/${idSolicitud}/informacion-crediticia/`)
  } catch (err) {
    callError(err)
  }
}

async function actualizarInfoCrediticia (idSolicitud, datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/solicitudes/${idSolicitud}/informacion-crediticia/`, datos)
  } catch (err) {
    callError(err)
  }
}

async function obtenerOtrosCreditos (idSolicitud) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/solicitudes/${idSolicitud}/otros-creditos/`)
  } catch (err) {
    callError(err)
  }
}

async function agregarOtroCredito (idSolicitud, datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/solicitudes/${idSolicitud}/otros-creditos/`, datos)
  } catch (err) {
    callError(err)
  }
}

async function eliminarOtroCredito (idSolicitud, idCredito) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.del(`/solicitudes/${idSolicitud}/otros-creditos/${idCredito}/`)
  } catch (err) {
    callError(err)
  }
}

async function obtenerComprobantes (idSolicitud) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/solicitudes/${idSolicitud}/comprobantes/`)
  } catch (err) {
    callError(err)
  }
}

async function agregarComprobante (idSolicitud, idComprobate, datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.put(`/solicitudes/${idSolicitud}/comprobantes/${idComprobate}/`, datos)
  } catch (err) {
    callError(err)
  }
}

async function eliminarComprobante (idSolicitud, idComprobante) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.delete(`/solicitudes/${idSolicitud}/comprobantes/${idComprobante}/`)
  } catch (err) {
    callError(err)
  }
}

async function obtenerDocumentos (idSolicitud) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.get(`/solicitudes/${idSolicitud}/documentos-firmar/`)
  } catch (err) {
    callError(err)
  }
}

async function agregarDocumento (idSolicitud, idDocumento, datos) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/solicitudes/${idSolicitud}/documentos-firmar/${idDocumento}/firmados/`, datos)
  } catch (err) {
    callError(err)
  }
}

async function eliminarDocumento (idSolicitud, idDocumento, idDocumentoFirmado) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.delete(`/solicitudes/${idSolicitud}/documentos-firmar/${idDocumento}/firmados/${idDocumentoFirmado}/`)
  } catch (err) {
    callError(err)
  }
}

async function terminarDocumentos (idSolicitud,) {
  const API = await ConstructorAPIAsync()
  try {
    return await API.post(`/solicitudes/${idSolicitud}/documentos-firmar/terminar/`)
  } catch (err) {
    callError(err)
  }
}

export {
  obtenerMisSolicitudes,
  obtenerDetalleSolicitud,
  obtenerSolicitudIncompleta,
  completarSolicitud,
  obtenerProductosDisponibles,
  crearSolicitud,
  calcularSolicitud,
  obtenerReferencias,
  agregarReferencia,
  editarReferencia,
  eliminarReferencia,
  obtenerInfoCrediticia,
  actualizarInfoCrediticia,
  obtenerOtrosCreditos,
  agregarOtroCredito,
  eliminarOtroCredito,
  obtenerComprobantes,
  agregarComprobante,
  eliminarComprobante,
  obtenerDocumentos,
  agregarDocumento,
  eliminarDocumento,
  terminarDocumentos
}