import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {
  obtenerInfoBancaria,
  actualizarInfoBancaria
} from '../../api/Empleados';
import { obtenerBancos } from '../../api/Utils'
import {validateNumber} from '../../utils/regex';

class InfoBancaria extends Component {
  state = {
    infoBancaria: {
      banco: '',
      cuenta: '',
      clabe: ''
    },
    bancos: [],
    errores: {}
  }

  componentDidMount = async () => {
    const bancos = [{label: '--Selecciona--', value: ''}];
    let { infoBancaria } = this.state;
    try {
      const respBancos = await obtenerBancos();
      respBancos.data.forEach(b => {
        bancos.push(
          {label: b.nombre, value: b.id},
        )
      });
    } catch (error) {
      console.log('Error: ', error);
    }
    try {
      const respInfoBancaria = await obtenerInfoBancaria();
      infoBancaria = respInfoBancaria.data;
    } catch (error) {
      console.log('Error: ', error);
    }
    this.setState({ infoBancaria, bancos });
  }

  handleChange = (e) => {
    const { infoBancaria } = this.state
    const {target: {name, value}} = e;
    if (name === 'cuenta' || name === 'clabe') {
      if (!validateNumber(value) && value.length > 0) { return; }
    }
    infoBancaria[name] = value
    this.setState({ infoBancaria })
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      infoBancaria
    } = this.state;

    if (infoBancaria.banco.trim().length === 0) {
      validado = false;
      errores.banco = ['Este campo es requerido.'];
    }
    if (infoBancaria.cuenta.trim().length === 0) {
      validado = false;
      errores.cuenta = ['Este campo es requerido.'];
    }
    if (infoBancaria.clabe.trim().length === 0) {
      validado = false;
      errores.clabe = ['Este campo es requerido.'];
    }

    this.setState({ errores });
    return validado;
  }

  guardar = async () => {
    if (!this.validar()) { return; }
    try {
      const { infoBancaria } = this.state;
      await actualizarInfoBancaria(infoBancaria)
      this.props.history.push('/perfil/ver')
    } catch (error) {
      console.log('Error:', error)
      if (error.status === 400) {
        this.setState({ errores: error.data });
      }
    }
  }

  render() {
    const { infoBancaria, errores, bancos } = this.state;
    return (
      <Autenticado menuSelected="perfil">
        <div className="row mb1 row-mt-15">
          <div className="col-md-12">
            <div className="titulo">
              <h3 className="titulo-seccion">Información bancaria del empleado</h3>
            </div>
          </div>
        </div>
        <div className="row mb1">
          <div className="col-sm-4 form-group">
            <Select
              label="Banco"
              value={infoBancaria.banco}
              name="banco"
              error={errores.banco}
              options={bancos}
              onChange={this.handleChange}
            />
            {/* <Input
              label="Banco"
              name="banco"
              placeholder="Banco_"
              value={infoBancaria.banco}
              maxLength={25}
              error={errores.banco}
              onChange={this.handleChange}
            /> */}
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="Cuenta"
              name="cuenta"
              placeholder="Cuenta_"
              value={infoBancaria.cuenta}
              maxLength={12}
              error={errores.cuenta}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="CLABE"
              name="clabe"
              placeholder="CLABE_"
              value={infoBancaria.clabe}
              maxLength={18}
              error={errores.clabe}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-success" onClick={this.guardar}>Guardar</button>
            <a href="/perfil/ver" className="btn btn-secondary ml1">Cancelar</a>
          </div>
        </div>
      </Autenticado>
    )
  }
}

export default InfoBancaria
