// BASE URL
// const DOMAIN = 'http://192.241.137.113'
// const DOMAIN = 'https://api.yuhu.mx'
const DOMAIN = process.env.REACT_APP_API_URL
// const API_VERSION = '/v1'
const BASE_URL = `${DOMAIN}`

// Google analytics code
// eslint-disable-next-line prefer-destructuring
const GA_CODE = process.env.REACT_APP_GA_CODE;

const MOMENT_LOCALE = require('moment/locale/es.js')

export {
  BASE_URL,
  MOMENT_LOCALE,
  GA_CODE
}
