import React, { Component } from 'react';
import Autenticado from '../../components/Autenticado';
import TituloSaldo from '../../components/general/TituloSaldo';
import Select from '../../components/form/Select';
import Input from '../../components/form/Input';
import Cotizacion from '../../components/solicitudes/Cotizacion';
import imgPago from '../../images/iconos/pago.svg';
import imgDinero from '../../images/iconos/dinero1.svg';
import {
  obtenerProveedores,
  obtenerProductosPorProveedor,
  pagarServicio
} from '../../api/Servicios';
import {
  cotizarDisposicion
} from '../../api/Disposiciones';
import {
  obtenerSaldoEmergenteDisponible
} from '../../api/Empleados';
import { validateDecimal } from '../../utils/regex';
import { currencyFormat, capitalizeOnlyFirst } from '../../utils/filters';
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts';
import { verificarServicio } from '../../api/Utils';

class PagoServicio extends Component {
  state = {
    servicioActivo: false,
    saldoDisponible: '0',
    proveedor: {},
    productos: [],
    producto: {},
    referenciaProd: {
      type: '',
      length: 10,
      name: '# Cuenta',
      amountType: 'variable'
    },
    monto: '',
    referencia: '',
    errores: {},
    verAmortizaciones: false,
    cotizacion: {
      totales: {
        total: '0'
      }
    }
  }

  verificarServicio = async () => {
    try {
      const req = await verificarServicio('recargas');
      if(req.data.servicio_activo){
        this.setState({servicioActivo: req.data.servicio_activo});
      }
    }catch (e) {
      console.log(e);
    }
  }

  componentDidMount = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    this.verificarServicio();

    try {
      const promesas = [];
      promesas.push(obtenerProveedores());
      promesas.push(obtenerProductosPorProveedor(id));
      promesas.push(obtenerSaldoEmergenteDisponible());
      const [respProveedores, respProductos, respSaldo] = await Promise.all(promesas);
      const proveedor = respProveedores.data.find(p => p.id === parseInt(id));
      let producto = {};
      if (respProductos.data.length > 0) {
        producto = respProductos.data[0];
      }
      const referenciaProd = this.getProductReference(producto);
      this.setState({
        proveedor,
        productos: respProductos.data,
        producto,
        saldoDisponible: respSaldo.data.saldo_disponible,
        referenciaProd
      });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  getProductReference = (producto) => {
    const referenciaProd = {
      type: '',
      length: 10,
      name: '# Cuenta',
      amountType: 'variable'
    };
    let referencia = producto.referencia.replace(/= /g, '=');
    referencia = referencia.replace(' Amount Type', '|Amount_Type');
    referencia = referencia.replace(' Name=', '|Name=');
    referencia = referencia.replace(' Length=', '|Length=');
    const referenciaArray = referencia.split('|')
    referenciaArray.forEach(ra => {
      const arr = ra.split('=')
      if (arr[0] === 'Type') { referenciaProd.type = arr[1].trim() }
      else if (arr[0] === 'Length') { referenciaProd.length = parseInt(arr[1]) }
      else if (arr[0] === 'Name') { referenciaProd.name = capitalizeOnlyFirst(arr[1].trim()) }
      else if (arr[0] === 'Amount_Type') { referenciaProd.amountType = arr[1].trim() }
    })
    return referenciaProd
  }

  handleChange = (e) => {
    const { target: { name, value }} = e;
    if (name === 'producto') {
      const { productos } = this.state;
      const producto = productos.find(pd => pd.id === parseInt(value));
      const referenciaProd = this.getProductReference(producto);
      this.setState({
        producto,
        referenciaProd,
        errores: {}
      });
    } else if(name === 'monto') {
      if (!validateDecimal(value) && value.length > 0) { return }
      this.setState({ [name]: value, errores: {} });
    } else {
      this.setState({ [name]: value, errores: {} });
    }
  }

  calcular = async () => {
    if(!this.validar()) { return; }
    const {
      monto,
      producto,
    } = this.state;
    const total = parseFloat(monto) + parseFloat(producto.comision);
    try {
      const resp = await cotizarDisposicion(total);
      this.setState({
        cotizacion: resp.data,
        verAmortizaciones: true
      });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      producto,
      monto,
      referencia
    } = this.state;

    if (producto.length === 0) {
      validado = false;
      errores.producto = ['Selecciona un producto.'];
    }

    if (monto.length === 0) {
      validado = false;
      errores.monto = ['Ingresa un monto.'];
    } else if (parseFloat(monto) <= 0) {
      validado = false;
      errores.monto = ['El monto debe ser mayor a 0.'];
    }

    if (referencia.length === 0) {
      validado = false;
      errores.referencia = ['Ingresa el número de recibo.'];
    }

    this.setState({ errores })
    return validado;
  }

  submit = async () => {
    if (!this.validar()) { return; }
    cargando();
    const {
      producto,
      monto,
      referencia
    } = this.state;

    const datos = {
      producto: producto.id,
      monto,
      referencia
    };

    const {
      match: {
        params: { id }
      }
    } = this.props;

    try {
      const resp = await pagarServicio(id, datos);
      cerrarAlertas();
      if (resp.data.estatus === 'error') {
        mostrarToast('Ocurrio un error al realizar el pago.', 'error');
      } else {
        mostrarToast('Pago realizado exitosamente.');
      }
      this.props.history.push(`/servicios/${resp.data.id}/recibo`);
    } catch (error) {
      console.log('Error:', error);
      cerrarAlertas();
      if (error.status === 400) {
        this.setState({ errores: error.data });
      } else {
        mostrarToast(error.message, 'error');
      }
    }
  }

  render() {
    const {
      proveedor,
      productos,
      producto,
      monto,
      referencia,
      errores,
      saldoDisponible,
      verAmortizaciones,
      cotizacion,
      referenciaProd: { name, length }
    } = this.state;
    const productosSelect = () => {
      const ps = [];
      productos.forEach(p => {
        ps.push({
          label: p.nombre,
          value: p.id
        });
      });
      return ps;
    };
    return (
      <Autenticado menuSelected="pago-servicios">
        <TituloSaldo
          titulo="Pago de servicios"
          descripcion1="Realiza tus pagos de la manera más sencilla y segura."
          descripcion2="Busca aquí el proveedor de servicio que deseas pagar."
          icono={imgPago}
          saldo={saldoDisponible}
        />
        <div className="row mb1">
          <div className="col-md-12">
            { !this.state.servicioActivo ?
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="text-center mt-5">
                    <p>Por el momento el servicio no se encuentra activo, envíanos un mensaje para darte mas información.</p>
                  </div>
                </div>
              </div>
              :
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row" style={{ marginTop: '1rem' }}>
                    <div className="col-sm-2">
                      <img
                        src={proveedor.imagen}
                        alt={proveedor.nombre}
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-sm-10">
                    <span className="titulo-servicio" style={{ fontSize: '1rem' }}>
                      {proveedor.nombre}
                    </span>
                      <div className="border-top" />
                      {/* <small style={{ fontSize: '0.9rem', color: '#AAA' }}>Servicio hogar</small> */}
                      <div className="flex mt1">
                        <div className="flex1">
                          {
                            productos.length > 1 && (
                              <Select
                                label="Producto"
                                name="producto"
                                value={producto.id}
                                options={productosSelect()}
                                error={errores.producto}
                                onChange={this.handleChange}
                              />
                            )
                          }
                          <Input
                            label={name}
                            name="referencia"
                            placeholder={name}
                            value={referencia}
                            maxLength={length}
                            error={errores.referencia}
                            onChange={this.handleChange}
                          />
                          <Input
                            label="Monto"
                            name="monto"
                            placeholder="Monto_"
                            value={monto}
                            maxLength={11}
                            error={errores.monto}
                            onChange={this.handleChange}
                            onBlur={this.calcular}
                          />
                          <div className="montos">
                          <span className="monto">
                            Monto<br />
                            <b>{currencyFormat(monto)}</b>
                          </span>
                            <span className="monto">
                            Comisión<br />
                            <b>{currencyFormat(producto.comision)}</b>
                          </span>
                          </div>
                        </div>
                        <div className="flex1">
                          <div className="montos">
                          <span className="pago-total">
                            Total a pagar<br />
                            <b>{currencyFormat(cotizacion.totales.total)}</b>
                          </span>
                            <button type="button" className="btn btn-success" onClick={this.submit}>
                              <img src={imgDinero} alt="pago" style={{ width: '25px' }} />
                              &nbsp;<b>Pagar</b>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
        {
          verAmortizaciones && (
            <div className="row mt2">
              <div className="col-12">
                <h5>Monto se cobrará en { cotizacion.pagos ? cotizacion.pagos.length : '3'} pagos como se muestra en esta tabla</h5>
                <Cotizacion
                  cotizacion={cotizacion}
                  fontSize="1rem"
                  esModal={false}
                  colorTotal="#ff6b6b"
                  colorSaldo="#25d07b"
                />
              </div>
            </div>
          )
        }
        <style jsx="true">{`
          .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .flex1 {
            flex: 1;
          }
          .montos {
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
          .monto {
            font-size: 0.8rem;
            color: #333;
          }
          .monto b {
            font-size: 1.5rem;
            color: #666;
          }
          .pago-total {
            font-size: 0.8rem;
            color: #333;
            font-weight: 600;
          }
          .pago-total b {
            font-size: 2rem;
            color: #25d07b;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default PagoServicio
