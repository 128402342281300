import React, { Component } from 'react';
import {
  cotizarDisposicion
} from '../../api/Disposiciones';
import {
  realizarRecarga,
  obtenerProveedores,
  obtenerProductosPorProveedor
} from '../../api/Recargas';
import {
  obtenerSaldoEmergenteDisponible
} from '../../api/Empleados';
import Autenticado from '../../components/Autenticado';
import TituloSaldo from '../../components/general/TituloSaldo';
import Input from '../../components/form/Input';
import Cotizacion from '../../components/solicitudes/Cotizacion';
import imgPrepago from '../../images/iconos/prepago_big.svg';
import {
  currencyFormat
} from '../../utils/filters';
import {
  validateNumber
} from '../../utils/regex';
import { cargando, mostrarToast, cerrarAlertas } from '../../utils/alerts';
import { verificarServicio } from '../../api/Utils';

class Disposicion extends Component {
  state = {
    servicioActivo: false,
    saldoDisponible: '0',
    proveedores: [],
    proveedor: {},
    productos: [],
    producto: {},
    categorias: [],
    categoria: {},
    telefono: '',
    telefonoConfirmar: '',
    calculado: false,
    verAmortizaciones: false,
    cotizacion: {},
    errores: {
      proveedor: [],
      producto: [],
      categoria: [],
      monto: []
    }
  };

  componentDidMount = async () => {
    const promesas = [
      obtenerSaldoEmergenteDisponible(),
      obtenerProveedores(),
    ]

    this.verificarServicio();

    try {
      const [respSaldo, respProveedores] = await Promise.all(promesas);
      this.setState({
        saldoDisponible: respSaldo.data.saldo_disponible,
        proveedores: respProveedores.data
      });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  verificarServicio = async () => {
    try {
      const req = await verificarServicio('recargas');
      if(req.data.servicio_activo){
        this.setState({servicioActivo: req.data.servicio_activo});
      }
    }catch (e) {
      console.log(e);
    }
  }

  cambiarProveedor = async (proveedor) => {
    try {
      const resp = await obtenerProductosPorProveedor(proveedor.id);
      const categorias = [...new Set(resp.data.map(c => c.producto_categoria))]
      let categoria = ''
      if (categorias.length === 1) {
        categoria = categorias[0];
      }
      this.setState({
        proveedor,
        productos: resp.data,
        cotizacion: {},
        verAmortizaciones: false,
        calculado: false,
        categorias,
        categoria,
        producto: {}
      });
    } catch (error) {
      console.log('Error:', error);
    }
  }
  
  cambiarCategoria = (cat) => {
    const { categoria } = this.state
    if (categoria !== cat) {
      this.setState({
        categoria: cat,
        cotizacion: {},
        verAmortizaciones: false,
        calculado: false,
        producto: {}
      });
    }
  }

  cambiarProducto = (producto) => {
    this.setState({
      producto,
      cotizacion: {},
      verAmortizaciones: false,
      calculado: false
    });
  }

  handleChange = (e) => {
    const { target: { name, value } } = e;
    if (validateNumber(value)) {
      this.setState({
        [name]: value,
        cotizacion: {},
        verAmortizaciones: false,
        calculado: false
      });
    }
  }

  validar = () => {
    let validado = true;
    const errores = {
      proveedor: [],
      producto: [],
      categoria: [],
      monto: []
    }
    const {
      proveedor,
      producto,
      telefono,
      telefonoConfirmar
    } = this.state;

    if (!proveedor.id) {
      validado = false;
      errores.proveedor = ['Debe seleccionar un proveedor.'];
    }
    if (!producto.id) {
      validado = false;
      errores.producto = ['Debe seleccionar un monto.'];
    }

    if (telefono.length === 0) {
      validado = false;
      errores.telefono = ['Este campo es obligatorio.'];
    } else if (telefono.length !== 10) {
      validado = false;
      errores.telefono = ['El telefono debe ser de 10 digitos.'];
    }
    if (telefonoConfirmar.length === 0) {
      validado = false;
      errores.telefonoConfirmar = ['Este campo es obligatorio.'];
    } else if (telefonoConfirmar.length !== 10) {
      validado = false;
      errores.telefonoConfirmar = ['El telefono debe ser de 10 digitos.'];
    }
    if (!errores.telefono && !errores.telefonoConfirmar && telefono !== telefonoConfirmar) {
      validado = false;
      errores.telefonoConfirmar = ['Los telefonos no coinciden.'];
    }

    this.setState({ errores })
    return validado;
  }

  calcular = async () => {
    if(!this.validar()) { return; }
    const {
      producto
    } = this.state;
    try {
      const resp = await cotizarDisposicion(producto.costo);
      this.setState({
        cotizacion: resp.data,
        verAmortizaciones: true,
        calculado: true
      });
    } catch (error) {
      console.log('Error:', error);
    }
  }

  crear = async () => {
    if(!this.validar()) { return; }
    cargando();
    const {
      proveedor,
      producto,
      telefono
    } = this.state;
    try {
      const resp = await realizarRecarga(proveedor.id, producto.id, telefono);
      cerrarAlertas();
      if (resp.data.estatus === 'error') {
        mostrarToast('Ocurrio un error al realizar la recarga.', 'error');
      } else {
        mostrarToast('Recarga realizada exitosamente.');
      }
      this.props.history.push(`/recargas/${resp.data.id}/recibo`);
    } catch (error) {
      cerrarAlertas();
      if (error.status === 400) {
        const errores = {
          proveedor: [],
          producto: [],
          categoria: [],
          monto: []
        }
        this.setState({ errores: {...errores, ...error.data} });
      } else {
        mostrarToast(error.message, 'error');
      }
    }
  }

  render() {
    const {
      proveedores,
      proveedor,
      productos,
      producto,
      telefono,
      telefonoConfirmar,
      calculado,
      cotizacion,
      errores,
      verAmortizaciones,
      saldoDisponible,
      categorias,
      categoria
    } = this.state;
    return (
      <Autenticado menuSelected="recargas">
        <TituloSaldo
          icono={imgPrepago}
          titulo="Recargar tiempo aire electrónico"
          descripcion1=""
          descripcion2=""
          saldo={saldoDisponible}
        />

        {!this.state.servicioActivo ?
          <div className="row mt-2">
            <div className="col-md-10 offset-md-1">
              <div className="text-center mt-5">
                <p>Por el momento el servicio no se encuentra activo, envíanos un mensaje para darte mas
                  información.</p>
              </div>
            </div>
          </div>
          :
          <div className="row mt2">
            <div className="col-sm-8">
              <div className="row">
                <div className="col-12">
                  <span>Selecciona tu compañia</span>
                  <div className="scrollx">
                    {
                      proveedores.map(p => (
                        <button
                          key={`prov${p.id}`}
                          type="button"
                          className={`btn btn-link mh05 pv0 ${p.id === proveedor.id ? 'seleccionado' : ''}`}
                          onClick={() => this.cambiarProveedor(p)}
                        >
                          <img src={p.imagen} alt={p.nombre} className="proveedor-img" />
                        </button>
                      ))
                    }
                  </div>
                  {errores.proveedor.map((obj, index) => (
                    <div key={`$errprov-${index}`} className="invalid-feedback">
                      {obj}
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mt1">
                <div className="col-12">
                  <span>Selecciona la categoria</span>
                  <div className="scrollx">
                    {
                      categorias.map(c => (
                        <button
                          key={`cat${c}`}
                          type="button"
                          className={`btn mh05 pv1 ${c === categoria ? 'btn-success' : 'btn-outline-secondary'}`}
                          onClick={() => this.cambiarCategoria(c)}
                        >
                          {c}
                        </button>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className="row mt1">
                <div className="col-12">
                  <span>Selecciona el monto</span>
                  <div className="scrollx">
                    {
                      productos.map(p => {
                        if (p.producto_categoria === categoria) {
                          return (
                            <button
                              key={`prod${p.id}`}
                              type="button"
                              className={`btn mh05 pv1 ${p.id === producto.id ? 'btn-success' : 'btn-outline-secondary'}`}
                              onClick={() => this.cambiarProducto(p)}
                            >
                              {currencyFormat(p.costo)}
                            </button>
                          )
                        }
                        return null
                      })
                    }
                  </div>

                  <div className="product-reference">
                    <small>
                      { producto.referencia }
                    </small>
                  </div>

                  {errores.producto.map((obj, index) => (
                    <div key={`$errprod-${index}`} className="invalid-feedback">
                      {obj}
                    </div>
                  ))}
                  {errores.monto.map((obj, index) => (
                    <div key={`$errmonto-${index}`} className="invalid-feedback">
                      {obj}
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mt1">
                <div className="col-sm-6">
                  <Input
                    label="Teléfono"
                    name="telefono"
                    placeholder="Teléfono_"
                    value={telefono}
                    maxLength={10}
                    error={errores.telefono}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    label="Confirmar teléfono"
                    name="telefonoConfirmar"
                    placeholder="Confirmar teléfono_"
                    value={telefonoConfirmar}
                    maxLength={10}
                    error={errores.telefonoConfirmar}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <button className="btn btn-success mb1 btn-lg btn-block" type="button" onClick={this.calcular}>
                Calcular
              </button>
            </div>
            {
              calculado && (
                <div className="col-sm-3 offset-sm-1">
                  <div className="informacion">
                    <div className="info-row">
                      <span className="etiqueta">Total a pagar</span>
                      <span className="total">{currencyFormat(cotizacion.totales.total)}</span>
                    </div>
                    <button
                      className="btn btn-success mt1 btn-lg btn-block"
                      type="button"
                      onClick={this.crear}
                    >
                      Realizar recarga
                    </button>
                  </div>
                </div>
              )
            }
          </div>
        }


        {
          verAmortizaciones && (
            <div className="row mt2">
              <div className="col-12">
                <h5>Monto se cobrará en { cotizacion.pagos ? cotizacion.pagos.length : '3'} pagos como se muestra en esta tabla</h5>
                <Cotizacion
                  cotizacion={cotizacion}
                  fontSize="1rem"
                  esModal={false}
                  colorTotal="#ff6b6b"
                  colorSaldo="#25d07b"
                />
              </div>
            </div>
          )
        }
        <style jsx="true">{`
          .scrollx {
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
          }
          .proveedor-img {
            width: 100px;
          }
          .seleccionado {
            background: #25d07b;
          }
          .informacion {
            padding: 1rem;
          }
          .info-row{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 1rem;
          }
          .informacion .info-row span{
            color: #333;
          }
          .informacion .info-row b{
            color: #25d07b;
          }
          .info-row .etiqueta{
            font-size: 0.8rem;
            font-weight: bold;
          }
          .info-row .pago{
            color: #666 !important;
            font-size: 1.7rem;
            font-weight: bold;
          }
          .info-row .total{
            color: #25d07b !important;
            font-size: 2rem;
            font-weight: bold;
          }
          .invalid-feedback {
            display: block;
          }
          .product-reference {
            padding-top: 15px;
          }
        `}</style>
      </Autenticado>
    )
  }
}

export default Disposicion
