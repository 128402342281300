import React, { Component } from 'react';
import moment from 'moment';
import Autenticado from '../../components/Autenticado';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import DatePicker from '../../components/form/DatePicker';
import {
  obtenerPerfil,
  actualizarPerfil
} from '../../api/Empleados';
import {
  validateEmail,
  validateRfc,
  validateCurp
} from '../../utils/regex';

class InfoEmpleado extends Component {
  state = {
    infoEmpleado: {
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      email: '',
      telefono_casa: '',
      telefono_celular: '',
      fecha_nacimiento: new Date(),
      rfc: '',
      curp: '',
      genero: 'masculino',
      estado_civil: 'soltero',
      nacionalidad: '',
      no_electoral: '',
      puesto: '',
      fecha_contratacion: new Date()
    },
    errores: {}
  }

  componentDidMount = async () => {
    try {
      const resp = await obtenerPerfil();
      const infoEmpleado = resp.data;
      delete infoEmpleado.domicilio
      delete infoEmpleado.informacion_bancaria
      delete infoEmpleado.percepciones
      delete infoEmpleado.referencias
      if (infoEmpleado.fecha_nacimiento) {
        infoEmpleado.fecha_nacimiento = moment(infoEmpleado.fecha_nacimiento, 'YYYY-MM-DD').toDate();
      }
      if (infoEmpleado.fecha_contratacion) {
        infoEmpleado.fecha_contratacion = moment(infoEmpleado.fecha_contratacion, 'YYYY-MM-DD').toDate();
      }
      if (!infoEmpleado.estado_civil) {
        infoEmpleado.estado_civil = 'soltero';
      }
      if (!infoEmpleado.genero) {
        infoEmpleado.genero = 'masculino';
      }
      const keys = Object.keys(infoEmpleado);
      keys.forEach((key) => {
        if (infoEmpleado[key] === null) {
          infoEmpleado[key] = '';
        }
      });
      this.setState({ infoEmpleado });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  handleChange = (e) => {
    const { infoEmpleado } = this.state;
    infoEmpleado[e.target.name] = e.target.value;
    this.setState({ infoEmpleado });
  }

  validar = () => {
    let validado = true;
    const errores = {}
    const {
      infoEmpleado
    } = this.state;

    if (infoEmpleado.nombre.trim().length === 0) {
      validado = false;
      errores.nombre = ['Este campo es requerido.'];
    }
    if (infoEmpleado.apellido_paterno.trim().length === 0) {
      validado = false;
      errores.apellido_paterno = ['Este campo es requerido.'];
    }
    if (infoEmpleado.email.trim().length === 0) {
      validado = false;
      errores.email = ['Este campo es requerido.'];
    } else if (!validateEmail(infoEmpleado.email)) {
      validado = false;
      errores.email = ['El correo electrónico no tiene el formato correcto.'];
    }
    if (infoEmpleado.curp.trim().length === 0) {
      validado = false;
      errores.curp = ['Este campo es requerido.'];
    } else if (!validateCurp(infoEmpleado.curp)) {
      validado = false;
      errores.curp = ['La CURP no tiene el formato correcto.'];
    }

    this.setState({ errores });
    return validado;
  }

  guardar = async () => {
    if (!this.validar()) { return; }
    try {
      const { infoEmpleado } = this.state;
      const infoEmpleadoAux = {...infoEmpleado};
      if (infoEmpleadoAux.fecha_contratacion) {
        infoEmpleadoAux.fecha_contratacion = moment(infoEmpleadoAux.fecha_contratacion).format('YYYY-MM-DD');
      }
      if (infoEmpleadoAux.fecha_nacimiento) {
        infoEmpleadoAux.fecha_nacimiento = moment(infoEmpleadoAux.fecha_nacimiento).format('YYYY-MM-DD');
      }
      const keys = Object.keys(infoEmpleadoAux);
      keys.forEach((key) => {
        if (infoEmpleadoAux[key] === '') {
          infoEmpleadoAux[key] = null;
        }
      });
      await actualizarPerfil(infoEmpleadoAux);
      this.props.history.push('/perfil/ver');
    } catch (error) {
      console.log('Error:', error);
      if (error.status === 400) {
        this.setState({ errores: error.data });
      }
    }
  }

  render() {
    const { infoEmpleado, errores } = this.state;
    const ESTADOS_CIVILES = [
      {label: 'Soltero', value: 'soltero'},
      {label: 'Casado', value: 'casado'}
    ];
    const GENEROS = [
      {label: 'Masculino', value: 'masculino'},
      {label: 'Femenino', value: 'femenino'}
    ];
    return (
      <Autenticado menuSelected="perfil">
        <div className="row mb1 row-mt-15">
          <div className="col-md-12">
            <div className="titulo">
              <h3 className="titulo-seccion">Informacion del empleado</h3>
            </div>
          </div>
        </div>
        <div className="row mb1">
          <div className="col-sm-4 form-group">
            <Input
              label="Nombre"
              name="nombre"
              placeholder="Nombre_"
              value={infoEmpleado.nombre}
              maxLength={25}
              error={errores.nombre}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="Apellido paterno"
              name="apellido_paterno"
              placeholder="Apellido paterno_"
              value={infoEmpleado.apellido_paterno}
              maxLength={25}
              error={errores.apellido_paterno}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="Apellido materno"
              name="apellido_materno"
              placeholder="Apellido materno_"
              value={infoEmpleado.apellido_materno}
              maxLength={25}
              error={errores.apellido_materno}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mb1">
          <div className="col-sm-4 form-group">
            <Input
              label="Correo electrónico"
              name="email"
              placeholder="Correo electrónico_"
              value={infoEmpleado.email}
              error={errores.email}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="Telefono casa"
              name="telefono_casa"
              placeholder="Telefono casa_"
              value={infoEmpleado.telefono_casa}
              maxLength={10}
              error={errores.telefono_casa}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="Telefono celular"
              name="telefono_celular"
              placeholder="Telefono celular_"
              value={infoEmpleado.telefono_celular}
              maxLength={10}
              error={errores.telefono_celular}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mb1">
          <div className="col-sm-4 form-group">
            <DatePicker
              name="fecha_nacimiento"
              label="Fecha de nacimiento"
              value={infoEmpleado.fecha_nacimiento}
              error={errores.fecha_nacimiento}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="RFC"
              name="rfc"
              placeholder="RFC_"
              value={infoEmpleado.rfc}
              maxLength={13}
              error={errores.rfc}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="CURP"
              name="curp"
              placeholder="CURP_"
              value={infoEmpleado.curp}
              maxLength={18}
              error={errores.curp}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mb1">
          <div className="col-sm-4 form-group">
            <Select
              label="Genero"
              name="genero"
              value={infoEmpleado.genero}
              options={GENEROS}
              error={errores.genero}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Select
              label="Estado civil"
              name="estado_civil"
              value={infoEmpleado.estado_civil}
              options={ESTADOS_CIVILES}
              error={errores.estado_civil}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="Nacionalidad"
              name="nacionalidad"
              placeholder="Nacionalidad_"
              value={infoEmpleado.nacionalidad}
              maxLength={15}
              error={errores.nacionalidad}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row mb1">
          <div className="col-sm-4 form-group">
            <Input
              label="Número electoral"
              name="no_electoral"
              placeholder="Número electoral_"
              value={infoEmpleado.no_electoral}
              maxLength={13}
              error={errores.no_electoral}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <Input
              label="Puesto"
              name="puesto"
              placeholder="Puesto_"
              value={infoEmpleado.puesto}
              maxLength={25}
              error={errores.puesto}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-sm-4 form-group">
            <DatePicker
              name="fecha_contratacion"
              label="Fecha de contratacion"
              value={infoEmpleado.fecha_contratacion}
              error={errores.fecha_contratacion}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-success" onClick={this.guardar}>Guardar</button>
            <a href="/perfil/ver" className="btn btn-secondary ml1">Cancelar</a>
          </div>
        </div>
      </Autenticado>
    )
  }
}

export default InfoEmpleado
