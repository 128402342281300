import React, { Component } from 'react';
import moment from 'moment';
import Autenticado from '../components/Autenticado';
import Card from '../components/general/Card';
import InfoRow from '../components/general/InfoRow';
import DonutChart from '../components/dashboard/DonutChart';
import imgCredito from '../images/iconos/credito.svg';
import imgEdoCuenta from '../images/iconos/estado_cuenta.svg';
import imgAhorro from '../images/iconos/ahorro.svg';
// import imgAhorro from '../images/iconos/caja_ahorro.png';
import imgPagoSericios from '../images/iconos/pago_servicio_color.svg';
import imgPrepago from '../images/iconos/prepago_big.svg';
import imgSemaforo from '../images/iconos/semaforo.svg';
import imgDisposicion from '../images/iconos/disposicion.svg';
import imgHistorialPExpress from '../images/iconos/historial_pexpress.svg';
import imgPExpress from '../images/iconos/prestamo_express.svg';
import {obtenerInfoDashboard} from '../api/Dashboard';
import { obtenerUrlCajaDeAhorro, obtenerUrlCajaDeAhorroYuhu } from '../api/Empleados';
import {currencyFormat} from '../utils/filters';
import { cargando, mostrarToast, cerrarAlertas } from '../utils/alerts';
import { Link } from 'react-router-dom';
import { getLinkSolicitud } from '../utils/functions';
import { getUserInfo } from '../utils/auth';

class Inicio extends Component {
  state = {
    dashboard: {
      ultima_solicitud: {
        totales_cotizacion: {}
      },
      ultimo_prestamo: {},
      historial_prestamos: []
    },
    heightCardPrincipal: 0,
    subdominio: ''
  };

  componentDidMount = async () => {
    try {
      const resp = await obtenerInfoDashboard();
      const dashboard = resp.data;
      if (!dashboard.ultima_solicitud) {
        dashboard.ultima_solicitud = {
          totales_cotizacion: {}
        };
      }
      if (!dashboard.ultimo_prestamo) {
        dashboard.ultimo_prestamo = {};
      }
      this.setState({ dashboard });

      const userInfo = await getUserInfo();
      const subdominio = userInfo.dils_subdominio ? `https://dils.yuhu.mx/${userInfo.dils_subdominio}?utm_source=web&utm_canal=portal_autoattend` : '';
      this.setState({'subdominio': subdominio});

    } catch (error) {
      mostrarToast('Ocurrio un error al obtener la informacion del dashboard', 'error');
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    const width = document.getElementById('cardPrincipal').getBoundingClientRect().width;
    this.setState({ heightCardPrincipal: (width / 3) * 2 });
  }

  irACajaDeAhorro = async (e) => {
    e.preventDefault();
    cargando();
    try {
      const resp = await obtenerUrlCajaDeAhorro();
      cerrarAlertas();
      if (resp.data.success) {
        const win = window.open(resp.data.mensaje, '_blank');
        if (win) {
          win.focus();
        }
      } else {
        mostrarToast(resp.data.mensaje, 'warning')
      }
    } catch (error) {
      cerrarAlertas();
      mostrarToast(error.message, 'error');
    }
  }

  irACajaDeAhorroYuhu = async (e) => {
    e.preventDefault();
    cargando();
    try {
      const resp = await obtenerUrlCajaDeAhorroYuhu();
      console.log(resp);
      cerrarAlertas();
      const win = window.open(`${resp.data.url}?token=${resp.data.access_token}`, '_blank');
      if(win){
        win.focus();
      }
    } catch (error) {
      cerrarAlertas();
      console.log(error);
      if( error.status === 403 ){
        mostrarToast(error.message, 'warning');
      }

      if(error.status === 500){
        mostrarToast(error.message, 'error');
      }

    }
  }

  render () {
    const guia = [
      { nombre: 'Inicio', url: '', clase: 'active' }
    ];
    const {
      heightCardPrincipal,
      dashboard,
    } = this.state;
    return (
      <Autenticado titulo="Inicio" guia={ guia }>
        <div className="row">
          <div className="col-md-7">
            {/*<div className="row mt1">*/}
            {/*  <div className="col-12">*/}
            {/*    <Card mostrarTitulo={false} backgroundColor="#FBEBAF">*/}
            {/*      <div className="row contenido lh1">*/}
            {/*        <div className="col-md-5 flex-start-center">*/}
            {/*          <img src={imgPExpress} alt="Prestamo express" width="70" />*/}
            {/*          <div className="flex-column-start-center ml1">*/}
            {/*            <p className="adelanto-nomina-text">*/}
            {/*              <b className="verde fsz-1">Adelanto 911</b><br/>*/}
            {/*              /!*<small className="mt1 mb1">Crédito pre-aprobado</small><br/>*!/*/}
            {/*              <div style={{paddingTop: '10px'}}>*/}
            {/*                <b className="verde fsz-2">{currencyFormat(dashboard.saldo_disponible, 0)}</b>*/}
            {/*              </div>*/}
            {/*            </p>*/}


            {/*            { dashboard.emergente_preaprobado === '0.00' &&*/}
            {/*              <p className="text-no-credito text-center">*/}
            {/*                Solicita para una emergencia económica, pago de servicios o recargas.<br/> Manda un WhatsApp<br/>*/}
            {/*                <a href="https://api.whatsapp.com/send?phone=5218123206260&text=%C2%A1Hola!%20Quisiera%20activar%20mi%20adelanto%20de%20n%C3%B3mina" target="_blank">81 2320 6260</a> o <a href="https://api.whatsapp.com/send?phone=5218123216027&text=%C2%A1Hola!%20Quisiera%20activar%20mi%20adelanto%20de%20n%C3%B3mina" target="_blank">81 2321 6027</a>*/}
            {/*              </p>*/}
            {/*            }*/}

            {/*          </div>*/}
            {/*        </div>*/}
            {/*        <div className="col-md-7">*/}
            {/*          <div className="row">*/}
            {/*            <div className="col-12 text-center">*/}
            {/*              <small>Úsalo en:</small>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*          <div className="row mt1">*/}
            {/*            <div className="col-4 flex-center">*/}
            {/*              <img src={imgPagoSericios} alt="Pago servicios" className="w80" />*/}
            {/*            </div>*/}
            {/*            <div className="col-4 flex-center">*/}
            {/*              <img src={imgPrepago} alt="Prepago" className="w80" />*/}
            {/*            </div>*/}
            {/*            <div className="col-4 flex-center">*/}
            {/*              <img src={imgDisposicion} alt="Disposicion" className="w80" />*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*          <div className="row">*/}
            {/*            <div className="col-4 flex-start">*/}
            {/*              <a href="/servicios" className="btn btn-success btn-sm mt1 btn-block btn-green-dashborad">*/}
            {/*                <b className="text-dark fsz-0-8">Servicios</b>*/}
            {/*              </a>*/}
            {/*            </div>*/}
            {/*            <div className="col-4 flex-start">*/}
            {/*              <a href="/recargas" className="btn btn-success btn-sm mt1 btn-block btn-green-dashborad">*/}
            {/*                <b className="text-dark fsz-0-8">Recargas</b>*/}
            {/*              </a>*/}
            {/*            </div>*/}
            {/*            <div className="col-4 flex-start">*/}
            {/*              <a href="/prestamo-express" className="btn btn-success btn-sm mt1 btn-block btn-green-dashborad">*/}
            {/*                <b className="text-dark fsz-0-8">Disposición</b>*/}
            {/*              </a>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </Card>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="row mt1">
              <div className="col-6" style={{ height: heightCardPrincipal }}>
                <a href={this.state.subdominio} id="cardPrincipal" className="text-decoration-none">
                  <Card mostrarTitulo={false}>
                    <div className="flex-column contenido">
                      <img src={imgCredito} alt="Solicitar Credito" style={{ width: '40%' }} />
                      <span className="title">Solicitar Crédito</span>
                    </div>
                  </Card>
                </a>
              </div>
              <div className="col-6" style={{ height: heightCardPrincipal }}>
                <a href="#" className="text-decoration-none" onClick={this.irACajaDeAhorroYuhu}>
                  <Card mostrarTitulo={false}>
                    <div className="flex-column contenido">
                      <img src={imgAhorro} alt="Estado de cuenta" style={{ width: '40%' }} />
                      <span className="title">Caja de ahorro/Inversión</span>
                    </div>
                  </Card>
                </a>
              </div>
            </div>
            <div className="row">
              {/*<div className="col-sm-6 mt1">*/}
              {/*  <Card mostrarTitulo={false} border={false} backgroundColor="#e4e0e6" padding="0.5rem">*/}
              {/*    <div className="row contenido lh1">*/}
              {/*      <div className="col-6 flex-start-center">*/}
              {/*        <img src={imgHistorialPExpress} id="imgExpress" alt="Prestamo express" className="w40" />*/}
              {/*        <div className="flex-column-start-center ml1">*/}
              {/*          <b className="fsz-0-8">Adelanto 911</b>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="col-6 flex-column-start-center border-left pex">*/}
              {/*        <span className="td-label">*/}
              {/*          Crédito pre-aprobado<br />*/}
              {/*          <span className="td-value">*/}
              {/*            {currencyFormat(dashboard.emergente_preaprobado)}*/}
              {/*          </span>*/}
              {/*        </span>*/}
              {/*        <span className="td-label mt05">*/}
              {/*          Usado<br />*/}
              {/*          <span className="td-value">*/}
              {/*            {currencyFormat(dashboard.emergente_utilizado)}*/}
              {/*          </span>*/}
              {/*        </span>*/}
              {/*        <span className="td-label mt05">*/}
              {/*          Saldo restante / plazo<br />*/}
              {/*          <span className="td-value">*/}
              {/*            {dashboard.emergente_plazo}*/}
              {/*          </span>*/}
              {/*        </span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </Card>*/}
              {/*</div>*/}
              <div className="col-sm-6 mt1">
                <Card mostrarTitulo={false} border={false} backgroundColor="#cbecd6" padding="0.5rem">
                  <div className="row contenido">
                    <div className="col-3 flex-start-center">
                      <img src={imgEdoCuenta} id="imgExpress" alt="Prestamo express" style={{ maxWidth: '100%' }} />
                    </div>
                    <div className="col-9">
                      <div className="row pr0-5">
                        <div className="col-12 mh0 cardbox-title border-bottom bbc3">
                          <b>Estado de cuenta</b>
                        </div>
                      </div>
                      <div className="row mt05">
                        <div className="col-12 mh0">
                          {
                            dashboard.ultimo_prestamo.folio
                              ? (
                                <div className="edo-cuenta">
                                  <InfoRow value="# pagos" label={dashboard.ultimo_prestamo.amortizaciones_pagadas} fsz="0.7rem" />
                                  <InfoRow value="cantidad" label={currencyFormat(dashboard.ultimo_prestamo.monto)} fsz="0.7rem" />
                                  <InfoRow value="pagado" label={currencyFormat(dashboard.ultimo_prestamo.total_pagado)} fsz="0.7rem" />
                                </div>
                              )
                              : (
                                <div className="flex-center">
                                  <small><b>Aun no tiene credito.</b></small>
                              </div>
                              )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-md-5 lh1">
            <div className="row mt1">
              <div className="col-12">
                <Card mostrarTitulo={false} height="auto">
                  {
                    dashboard.ultimo_prestamo.folio
                      ? (
                        <div className="row">
                          <div className="col-4 mh0">
                            <DonutChart
                              titulo="Pagos"
                              pagado={dashboard.ultimo_prestamo.amortizaciones_pagadas}
                              total={dashboard.ultimo_prestamo.num_amortizaciones}
                            />
                          </div>
                          <div className="col-8">
                            <div className="row">
                              <div className="col-12 mh0 cardbox-title border-bottom">
                                <b>Crédito #{dashboard.ultimo_prestamo.folio}</b>
                              </div>
                            </div>
                            <div className="row mt05">
                              <div className="col-6">
                                <span className="td-label">
                                  Crédito otorgado<br />
                                  <span className="td-value">
                                    {moment(dashboard.ultimo_prestamo.fecha_creacion).format('DD/MM/YYYY')}
                                  </span>
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="td-label">
                                  Monto otorgado<br />
                                  <span className="td-value">
                                    {currencyFormat(dashboard.ultimo_prestamo.monto)}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="row mt05">
                              <div className="col-6">
                                <span className="td-label">
                                  Plazo<br />
                                  <span className="td-value">
                                    {dashboard.ultimo_prestamo.plazo}
                                  </span>
                                </span>
                              </div>
                              <div className="col-6">
                                <span className="td-label">
                                  Saldo restante<br />
                                  <span className="td-value">
                                    {/*{currencyFormat(parseFloat(dashboard.ultimo_prestamo.monto) - parseFloat(dashboard.ultimo_prestamo.total_pagado))}*/}
                                    {currencyFormat(dashboard.ultimo_prestamo.saldo_restante)}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      : (
                        <div className="flex-center">
                            <small><b>Aun no tiene credito.</b></small>
                        </div>
                      )
                  }
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mt1">
                <Card mostrarTitulo={false}>
                  <div className="row border-bottom pb05 flex-column">
                    <div className="col-12 mh0 flex-column">
                      <span className="cardbox-title-black">
                        Estatus solicitud de crédito
                      </span>
                      {
                        dashboard.ultima_solicitud.id && (
                          <span className="cardbox-subtitle-black">
                            #{dashboard.ultima_solicitud.folio}
                          </span>
                        )
                      }
                    </div>
                  </div>
                  <div className="row mt05">
                    {
                      dashboard.ultima_solicitud.id
                        ? (
                          <div className="col-12 mh0">
                            <InfoRow value="monto" label={currencyFormat(dashboard.ultima_solicitud.monto)} fsz="0.7rem" />
                            <InfoRow value="plazo" label={dashboard.ultima_solicitud.num_amortizaciones} fsz="0.7rem" />
                            <InfoRow value="fecha" label={moment(dashboard.ultima_solicitud.fecha_creacion).format('DD/MM/YYYY')} fsz="0.7rem" />
                            <InfoRow value="capital" label={currencyFormat(dashboard.ultima_solicitud.totales_cotizacion.capital)} fsz="0.7rem" />
                            <InfoRow value="interés" label={currencyFormat(dashboard.ultima_solicitud.totales_cotizacion.interes)} fsz="0.7rem" />
                            <InfoRow value="iva" label={currencyFormat(dashboard.ultima_solicitud.totales_cotizacion.iva)} fsz="0.7rem" />
                            <InfoRow value="total" label={currencyFormat(dashboard.ultima_solicitud.totales_cotizacion.total)} fsz="0.7rem" />
                          </div>
                        )
                        : (
                          <div className="col-12 mh0 cardbox-subtitle-black">
                            Aun no tiene solicitud de crédito.
                          </div>
                        )
                    }
                  </div>
                  {
                    dashboard.ultima_solicitud.id && (
                      <div className="row mt1">
                        <div className="col-12 flex-around">
                          <img src={imgSemaforo} alt="Semaforo" />
                          { dashboard.ultima_solicitud.paso_autoattend ?
                              <span className="btn-success text-capitalize" style={{ padding: '10px 10px' }}>
                                <small><b>{dashboard.ultima_solicitud.estatus}</b></small>
                              </span>
                              :
                              <span className="bg-success no-link text-capitalize" style={{ padding: '10px 10px' }}>
                                <small><b>{dashboard.ultima_solicitud.estatus}</b></small>
                              </span>
                          }
                        </div>
                      </div>
                    )
                  }
                </Card>
              </div>
              <div className="col-sm-6 mt1">
                <Card mostrarTitulo={false}>
                  <div className="row border-bottom pb05 flex-column">
                    <div className="col-12 mh0 flex-column">
                      <span className="cardbox-title-black">
                        Historial de créditos
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    {
                      dashboard.historial_prestamos.length > 0
                        ? (
                          <div className="col-12 mh0">
                            {
                              dashboard.historial_prestamos.map((h, index) => (
                                <div key={`hp${index}`} className="border-bottom pb05 mt05">
                                  <span className="td-label">
                                    Fecha&nbsp;
                                    <span className="td-value">
                                      {moment(h.fecha).format('DD/MM/YYYY')}
                                    </span><br />
                                  </span>
                                  <span className="td-label">
                                    Monto&nbsp;
                                    <span className="td-value">
                                      {h.monto}
                                    </span><br />
                                  </span>
                                </div>
                              ))
                            }
                          </div>
                        )
                        : (
                          <div className="col-12 mt05 cardbox-subtitle-black">
                            Aun no tiene historial de credito.
                          </div>
                        )
                    }
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .title {
            font-size: 0.85rem;
            margin-top: 0.5rem;
            text-align: center;
          }
          .title-black {
            color: #333;
            font-size: 0.75rem;
            margin-top: 0.5rem;
            text-align: center;
            font-weight: bold;
          }
          .td-label {
            color: #333;
            font-size: 0.7rem;
          }
          .td-value {
            color: #25d07b;
            font-size: 0.7rem;
            font-weight: bold;
          }
          .cardbox-title {
            color: #25d07b;
            font-size: 0.8rem;
            font-weight: bold;
          }
          .cardbox-title-black {
            color: #333;
            font-size: 0.7rem;
            font-weight: bold;
            text-align: center;
          }
          .cardbox-subtitle-black {
            color: #333;
            font-size: 0.7rem;
            text-align: center;
          }
          .lh1 {
            line-height: 1 !important;
          }
          .mt05 {
            margin-top: 0.5rem;
          }
          .pb05 {
            padding-bottom: 0.5rem;
          }
          .info-row .label {
            font-size: 0.7rem;
            color: #25d07b;
            text-align: right;
            padding-left: 0.2rem;
            font-weight: bold;
            flex: 0 0 60%;
            max-width: 60%;
          }
          .info-row .value {
            font-size: 0.7rem;
            color: #333;
            text-align: left;
            padding-right: 0.2rem;
            flex: 0 0 40%;
            max-width: 40%;
            font-weight: normal;
          }
          .edo-cuenta .info-row .label {
            flex: 0 0 50%;
            max-width: 50%;
          }
          .edo-cuenta .info-row .value {
            flex: 0 0 50%;
            max-width: 50%;
          }
          .mh0 {
            padding-right: 0px;
            padding-left: 0px;
          }
          .w80 {
            width: 80%;
          }
          .w40 {
            width: 40%;
          }
          .pex {
            border-left-color: #25d07b !important;
          }
          .bbc3 {
            border-bottom-color: #333 !important;
          }
          .btn-green-dashborad {
            min-width: 95px;
          }
          .text-no-credito {
            font-size: 11px;
            padding-top: 10px;
            line-height: 1rem;
          }
          .adelanto-nomina-text {
            text-align: center;
            line-height: 1.5rem;
            width: 100%;
          }
          .btn-solicitud:hover {
            text-decoration: none;
          }
          .bg-success.no-link {
            color: #fff;
          }
        `}</style>
      </Autenticado>
    );
  }
}

export default Inicio;
